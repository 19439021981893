import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import {
  CommCenterService,
  DisplayService,
  LogService,
  RecentClaimService,
  Session,
  SupportDialogComponent,
} from 'src/app/my-sedgwick';
import { BaseComponent, MessageBus, MyClaim } from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { ConfigurationSetting, UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { Announcements } from '../../models/announcements';
import { EulaAgreements } from '../../models/eulaagreements';
import { UserLogout } from '../../models/user-logout';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DelegationService } from '../../services/delegation.service';
import { UserRoleService } from '../../services/user-role.service';
import { UserDetails } from '../../services/userdetails.service';
import { Delegatee, Delegatees } from '../delegate/act-delegate-page.component';
import { IClaimant } from '../labels/claimant-label.component';

@Component({
  selector: 'app-layout',
  template: `
    <div style="overflow-y: auto;">
      <app-skip-to-main></app-skip-to-main>
      <app-preview-mode-header
        class="tw-w-full "
        *ngIf="session.user && session.user.emulatorContext"
      ></app-preview-mode-header>

      <mat-sidenav-container
        class="sidenav-container app-high-density"
        *ngIf="session.user"
      >
        <mat-sidenav
          #drawer
          class="sidenav"
          role="navigation"
          position="start"
          [attr.role]="(isSmall$ | async) ? 'dialog' : 'navigation'"
          [mode]="(isSmall$ | async) ? 'over' : 'side'"
          [opened]="
            (isSmall$ | async) === false &&
            (!isAuditor || session.user.role !== 'AUDITOR')
          "
        >
          <div class="app-pt3 app-pb2" style="text-align: center"></div>
          <app-sidenav
            [hasEulaAgreement]="hasEulaAgreement"
            [isIncidentalClient]="isIncidentalClient"
            [hasReportClaim]="hasReportClaim"
            [isManager]="isManager"
            [showSearch]="showSearch"
            [showWatchlist]="showWatchlist"
            [showHistory]="showHistory"
            [showAccountSetting]="showAccountSetting"
            [myClaims]="myClaims"
            [isSmall]="isSmall$"
            [isAuditor]="isAuditor"
            [drawer]="drawer"
            [actAsDelegate]="display.actAsDelegateSignal()"
            [manageDelegates]="manageDelegates"
            [manageOthersDelegators]="manageOthersDelegators"
            *ngIf="isManager"
            [IsMyWorkSchedule]="hasWorkSchedule"
          >
          </app-sidenav>
          <mat-nav-list *ngIf="!hasEulaAgreement && !isManager && !terminated">
            <a
              role="button"
              tabindex="0"
              mat-list-item
              class="app-mat-list-item"
              *ngIf="!hasEulaAgreement && hasReportClaim && !isIncidentalClient"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              (click)="reportClaim(drawer)"
              (keydown.enter)="reportClaim(drawer)"
              [routerLinkActiveOptions]="{ exact: true }"
              title="Report a New Claim"
              ><span class="app-mat-list-item-span">Report a New Claim</span></a
            >
            <mat-divider
              *ngIf="!hasEulaAgreement && hasReportClaim && !isIncidentalClient"
            ></mat-divider>

            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="session.user.roles.omni || session.user.roles.overseer"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/summary"
              (click)="tryClose(drawer)"
              [routerLinkActiveOptions]="{ exact: true }"
              [title]="'MANAGER VIEW'"
              ><span class="app-mat-list-item-span">Summary View</span></a
            >
            <mat-divider
              *ngIf="session.user.roles.omni || session.user.roles.overseer"
            ></mat-divider>

            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="session.user.roles.supervisor && !isIncidentalClient"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              routerLink="/supervisor"
              (click)="tryClose(drawer)"
              [routerLinkActiveOptions]="{ exact: true }"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              [attr.aria-current]="
                router.isActive('/supervisor', true) ? 'page' : null
              "
              [style.text-decoration]="
                router.isActive('/supervisor', true) ? 'underline' : null
              "
              [title]="'MANAGER VIEW'"
              ><span class="app-mat-list-item-span">MANAGER VIEW</span></a
            >
            <mat-divider
              *ngIf="session.user.roles.supervisor && !isIncidentalClient"
            ></mat-divider>

            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="isIncidentalClient === false && isManager"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/supervisor"
              (click)="tryClose(drawer)"
              [routerLinkActiveOptions]="{ exact: true }"
              title="MANAGER VIEW"
              ><span class="app-mat-list-item-span">MANAGER VIEW</span></a
            >
            <mat-divider
              *ngIf="
                isIncidentalClient === false &&
                isManager &&
                !session.isDelegating()
              "
            ></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="isIncidentalClient === false && !session.isDelegating()"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              [routerLink]="getDashboardRoute()"
              (click)="tryClose(drawer)"
              [routerLinkActiveOptions]="{ exact: true }"
              [title]="showMenu"
              ><span class="app-mat-list-item-span">{{
                showMenu.toUpperCase()
              }}</span></a
            >
            <mat-divider *ngIf="isIncidentalClient === false"></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              (click)="tryClose(drawer)"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/my-work-schedule"
              title="My Work Schedule"
              *ngIf="hasWorkSchedule"
              ><span class="app-mat-list-item-span">My Work Schedule</span></a
            >
            <mat-divider *ngIf="hasWorkSchedule"></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="isIncidentalClient"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/absence-dashboard"
              (click)="tryClose(drawer)"
              [routerLinkActiveOptions]="{ exact: true }"
              ><span class="app-mat-list-item-span">Absences</span></a
            >
            <a
              mat-list-item
              class="app-mat-list-item"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/communication-center"
              (click)="tryClose(drawer)"
              title="Communication center"
              *ngIf="!isIncidentalClient"
              ><span class="app-mat-list-item-span"
                >communication center</span
              ></a
            >
            <mat-divider *ngIf="!isIncidentalClient"></mat-divider>

            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="showSearch"
              (click)="tryClose(drawer)"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/search"
              title="Search"
              ><span class="app-mat-list-item-span">Claim Search</span></a
            >
            <mat-divider *ngIf="showSearch"></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="showWatchlist"
              (click)="tryClose(drawer)"
              routerLink="/watchlist"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              title="Watch List"
              ><span class="app-mat-list-item-span">Watch List</span></a
            >
            <mat-divider *ngIf="showWatchlist"></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="showHistory"
              (click)="tryClose(drawer)"
              routerLink="/history"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              title="History"
              ><span class="app-mat-list-item-span">History</span></a
            >
            <mat-divider *ngIf="showHistory"></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="display.actAsDelegateSignal() && !session.isDelegating()"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/act-as-delegate"
              ><span class="app-mat-list-item-span">Act as delegate</span></a
            >
            <mat-divider
              *ngIf="display.actAsDelegateSignal() && !session.isDelegating()"
            ></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="
                (manageDelegates || manageOthersDelegators) &&
                !session.isDelegating()
              "
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="/manage-delegates"
              ><span class="app-mat-list-item-span">Manage delegates</span></a
            >
            <mat-divider
              *ngIf="
                (manageDelegates || manageOthersDelegators) &&
                !session.isDelegating()
              "
            ></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              (click)="tryClose(drawer)"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="PreferencesPage"
              title="Account settings"
              role="link"
              *ngIf="showAccountSetting && !session.isDelegating()"
              ><span class="app-mat-list-item-span">Account settings</span></a
            >
            <mat-divider
              *ngIf="showAccountSetting && !session.isDelegating()"
            ></mat-divider>

            <a
              mat-list-item
              class="app-mat-list-item"
              *ngIf="!isIncidentalClient"
              (click)="tryClose(drawer)"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="help"
              [queryParams]="{ focus: 'learningcenter' }"
              title="Helpful Resources"
              ><span class="app-mat-list-item-span">Helpful Resources</span></a
            >
            <mat-divider></mat-divider>
            <a
              [routerLink]="[]"
              mat-list-item
              class="app-mat-list-item"
              (click)="logOut()"
              title="Log Out"
              ><span class="app-mat-list-item-span">Log Out</span></a
            >

            @if (hostname === 'localhost') {
            <mat-divider></mat-divider>
            <a
              mat-list-item
              class="app-mat-list-item"
              routerLinkActive
              #rla="routerLinkActive"
              [class.active-link]="rla.isActive"
              [attr.aria-current]="rla.isActive ? 'page' : null"
              [style.text-decoration]="rla.isActive ? 'underline' : null"
              routerLink="design"
              title="design"
              ><span class="app-mat-list-item-span">Design</span></a
            >
            }
          </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content
          [class]="(isSmall$ | async) ? 'contentmini' : 'contentmax'"
          (scroll)="onScroll($event)"
        >
          <div class="tw-flex tw-flex-col inflate-body">
            <app-emulate-user-header
              class="tw-w-full"
              *ngIf="
                session.user &&
                session.user.emulatorContext &&
                !hideEmulatedUserHeader
              "
              [logoImage]="logoImage"
              [isSmall$]="isSmall$"
              [isAuditor]="isAuditor"
              [showHeaderIcons]="showHeaderIcons"
              [showSearch]="showSearch"
              [showWatchlist]="showWatchlist"
              [showHistory]="showHistory"
              [hasEulaAgreement]="hasEulaAgreement"
              [showAccountSetting]="showAccountSetting"
              [isPreference]="isPreference"
              [scrolled]="scrolled"
              (logOutEmitter)="logOut()"
              [drawer]="drawer"
            >
            </app-emulate-user-header>
            <div
              class="tw-grid tw-grid-cols-1 tw-justify-items-center header toolbar"
              role="banner"
              style="z-index:999;"
              [class.mat-elevation-z4]="scrolled"
            >
              <div
                id="imagelogoabove"
                class="sm:tw-hidden tw-justify-items-left"
                style="width:100%;"
              >
                <img
                  *ngIf="logoImage"
                  src="data:image/JPEG;base64,{{ logoImage }}"
                  class="logo-above"
                  alt="{{ logoalt }}"
                  tabindex="0"
                  [attr.aria-label]="logoalt"
                />
                <div class="clientname" *ngIf="isAuditor">
                  {{ clientHeader }}
                </div>
              </div>
              <mat-toolbar
                *ngIf="!session.user.emulatorContext"
                id="headerContext"
                color="primary"
                class="header tw-max-w-screen-xl last-head-bar"
              >
                <button
                  type="button"
                  aria-label="Toggle sidenav"
                  mat-icon-button
                  (click)="drawer.toggle()"
                  *ngIf="(isSmall$ | async) && !this.isAuditor"
                >
                  <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <div class="tw-hidden md:tw-block">
                  <img
                    *ngIf="logoImage"
                    src="data:image/JPEG;base64,{{ logoImage }}"
                    alt="{{ logoalt }}"
                    class="logo-inline"
                    tabindex="-1"
                  />
                </div>

                <div class="" *ngIf="isAuditor">
                  Client : {{ clientHeader }}
                </div>
                <span class="example-spacer"></span>
                <div
                  *ngIf="session.isDelegating()"
                  class="tw-py-0.5 tw-pl-1 tw-pr-2 tw-rounded tw-border-2 tw-border-solid tw-border-[#c24533] tw-w-fit tw-flex tw-items-center tw-text-[13px] tw-leading-tight tw-mr-2"
                >
                  <mat-icon class="tw-text-[#c24533] tw-mr-1"
                    >people_alt</mat-icon
                  >
                  <div>
                    <div>Delegating as {{ delegateeName }}</div>
                    <a
                      tabindex="0"
                      (click)="endDelegationConfirmation()"
                      (keydown.enter)="endDelegationConfirmation()"
                      >Stop delegation</a
                    >
                  </div>
                  <ng-template #removeDialog let-element="element">
                    <app-dialog-container
                      [headerId]="'confirmEndDelegation'"
                      label="Confirm end delegation"
                      [headerLevel]="2"
                      [showHeader]="true"
                    >
                      <div class="tw-py-6">
                        The action you selected will end your delegate session
                        and any unsaved data will be lost.<br />To stop acting
                        as a delegate, press the Confirm button below.
                      </div>
                      <app-action-row>
                        <app-button
                          emphasis="High"
                          right2
                          matDialogClose
                          (click)="endDelegation()"
                          (keydown.enter)="endDelegation()"
                          >Confirm</app-button
                        >
                        <app-button emphasis="Low" right1 matDialogClose
                          >Cancel</app-button
                        >
                      </app-action-row>
                    </app-dialog-container>
                  </ng-template>
                </div>
                <mat-icon color="" class="persondisplay toolbar-icon-slate"
                  >person</mat-icon
                >
                <div class="app-pr3 app-pl1 welcome toolbar-icon-slate">
                  {{ getUserDisplayName() }}
                </div>
                <div id="homepagelink" style="display:none">
                  <button
                    title="Home"
                    aria-label="Home"
                    mat-icon-button
                    (click)="this.redirecttosearch()"
                  >
                    <mat-icon>home</mat-icon>
                  </button>
                </div>
                <button
                  mat-icon-button
                  style="color: var(--color-input-grey)"
                  [attr.aria-label]="
                    'Communication center - ' +
                    commCenterService.unreadMessageCount() +
                    ' New messages'
                  "
                  class="nopadding"
                  routerLink="/communication-center"
                  role="link"
                  tabindex="0"
                  [attr.title]="
                    'Communication center - ' +
                    commCenterService.unreadMessageCount() +
                    ' New messages'
                  "
                  *ngIf="!isAuditor && !isIncidentalClient"
                >
                  <mat-icon
                    [matBadge]="commCenterService.unreadMessageCount()"
                    matBadgeColor="accent"
                    >mail_outline</mat-icon
                  >
                </button>
                <div *ngIf="showHeaderIcons">
                  <ng-container *ngIf="showSearch">
                    <app-overlay
                      [origin]="searchOverlayOrigin"
                      #searchOverlay
                      [overlayClassName]="'tw-w-[600px]'"
                    >
                      <app-quick-search
                        *ngIf="searchOverlay.opened"
                        [showTitle]="false"
                      ></app-quick-search>
                    </app-overlay>
                    <button
                      aria-label="Search Popup"
                      mat-icon-button
                      cdkOverlayOrigin
                      #searchOverlayOrigin="cdkOverlayOrigin"
                      (click)="searchOverlay.open()"
                    >
                      <mat-icon class="toolbar-icon-slate">search</mat-icon>
                    </button>
                  </ng-container>
                </div>
                <div *ngIf="showHeaderIcons" class="tw-hidden md:tw-block">
                  <ng-container
                    *ngIf="showWatchlist && !session.isDelegating()"
                  >
                    <app-overlay
                      [origin]="watchListOverlayOrigin"
                      #watchListOverlay
                      [overlayClassName]="'watchlist-overlay'"
                    >
                      <app-watch-list
                        *ngIf="watchListOverlay.opened"
                        [overlay]="true"
                      ></app-watch-list>
                    </app-overlay>
                    <button
                      mat-icon-button
                      aria-label="Watch List Popup"
                      cdkOverlayOrigin
                      #watchListOverlayOrigin="cdkOverlayOrigin"
                      (click)="watchListOverlay.open()"
                    >
                      <mat-icon>star</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="showHistory && !session.isDelegating()">
                    <app-overlay
                      [overlayClassName]="'history-overlay'"
                      [origin]="historyOverlayOrigin"
                      #historyOverlay
                    >
                      <app-claim-history
                        *ngIf="historyOverlay.opened"
                        [overlay]="true"
                      ></app-claim-history>
                    </app-overlay>
                    <button
                      aria-label="History Popup"
                      mat-icon-button
                      cdkOverlayOrigin
                      #historyOverlayOrigin="cdkOverlayOrigin"
                      (click)="historyOverlay.open()"
                    >
                      <mat-icon class="toolbar-icon-slate">history</mat-icon>
                    </button>
                  </ng-container>
                </div>
                <div
                  *ngIf="
                    (display.actAsDelegateSignal() ||
                      manageDelegates ||
                      manageOthersDelegators) &&
                    !this.session.isDelegating()
                  "
                >
                  <button
                    title="Delegate Settings"
                    mat-icon-button
                    [matMenuTriggerFor]="delegateMenu"
                  >
                    <mat-icon>people_alt</mat-icon>
                  </button>

                  <mat-menu #delegateMenu="matMenu">
                    <button
                      *ngIf="display.actAsDelegateSignal()"
                      mat-menu-item
                      routerLink="/act-as-delegate"
                    >
                      Act as delegate
                    </button>
                    <button
                      *ngIf="manageDelegates || manageOthersDelegators"
                      mat-menu-item
                      routerLink="/manage-delegates"
                    >
                      Manage delegates
                    </button>
                  </mat-menu>
                </div>
                <div
                  *ngIf="
                    !hasEulaAgreement &&
                    showAccountSetting &&
                    !session.isDelegating()
                  "
                  class="tw-hidden md:tw-block"
                >
                  <button
                    mat-icon-button
                    id="preferenceButtonId"
                    *ngIf="!isPreference && showAccountSetting"
                    aria-label="Preferences page"
                    mat-icon-button
                    (click)="redirectToPreferences()"
                    (keydown.enter)="redirectToPreferences()"
                    role="link"
                  >
                    <mat-icon class="toolbar-icon-slate">settings</mat-icon>
                  </button>
                </div>
                <div class="tw-hidden md:tw-block" *ngIf="!hasEulaAgreement">
                  <button
                    mat-button
                    class="logout"
                    aria-label="Logout and open the login page"
                    (click)="logOut()"
                  >
                    <b>Log out</b>
                  </button>
                </div>
              </mat-toolbar>
            </div>

            <div id="main-content" role="main" class="outlet tw-grow">
              <div class="outlet">
                <div *ngIf="hasEulaAgreement">
                  <app-panel
                    title="User Agreement and License"
                    [largeHeader]="true"
                    [canExpand]="false"
                  >
                    <app-enduser-licenseagreement
                      [hasEulaAgreement]="true"
                      (acceptedEulaAgreement)="acceptedEulaAgreement($event)"
                      [eulaAgreements]="eulaAgreements"
                    >
                    </app-enduser-licenseagreement>
                  </app-panel>
                </div>
                <div *ngIf="this.session.user.isEmulating || !hasEulaAgreement">
                  <ng-template #dialogAnnouncements>
                    <app-dialog
                      title="Announcements"
                      ariaLabel="Announcements dialog"
                      [width]="700"
                      [showActions]="false"
                      [showHeader]="true"
                      ariaLabelMessage="Close announcement popup"
                    >
                      <app-announcements></app-announcements>
                    </app-dialog>
                  </ng-template>
                  <router-outlet></router-outlet>
                  <div class="tw-h-10"></div>
                </div>
              </div>
            </div>
            <div
              class="footer tw-grow-0 tw-grid tw-grid-cols-1 tw-justify-items-center "
              role="contentinfo"
            >
              <div
                class="tw-flex tw-flex-wrap tw-max-w-screen-xl tw-w-full tw-items-center"
              >
                <div class="tw-grow">
                  <img
                    src="/assets/images/SDWK_PrimaryLogo_White.webp"
                    width="155"
                    height="37"
                    loading="lazy"
                    class="tw-pr-2"
                    style="margin:auto;"
                    alt="Sedgwick Logo"
                  />
                </div>
                <div
                  class="tw-grow-0 tw-pt-2  tw-flex tw-flex-col sm:tw-flex-row tw-gap-1"
                  style="margin:auto;"
                >
                  <a
                    aria-label="Opens Technical Support popup"
                    (click)="openSupportDialog()"
                    (keydown.enter)="openSupportDialog()"
                    role="button"
                    class="app-pr3 app-uppercase app-pb2 app-pt2"
                    tabindex="0"
                    >TECHNICAL SUPPORT</a
                  >
                  <a
                    label="Site Map"
                    routerLink="/sitemap"
                    class="app-pr3 app-uppercase app-pb2 app-pt2"
                    >Site Map</a
                  >
                  <a
                    href="https://www.sedgwick.com/global-privacy-policy"
                    target="privacy-notice"
                    class="app-pr3 app-uppercase app-pb2 app-pt2"
                    >Privacy Policy</a
                  >
                  <a
                    href="https://www.sedgwick.com/terms-conditions"
                    target="privacy-notice"
                    class="app-pr3 app-uppercase app-pb2 app-pt2"
                    >Terms and Conditions</a
                  >
                  <a
                    href="{{ this.helpUrl }}"
                    target="privacy-notice"
                    class="app-pr3 app-uppercase app-pb2 app-pt2"
                    >Help</a
                  >
                  <div class="app-pr3 app-uppercase app-pb2 app-pt2">
                    <span>{{ appCompany }}</span>
                  </div>
                </div>
                <ng-template #dialogReportNewClaim>
                  <app-dialog
                    title="Confirmation"
                    ariaLabel="Confirmation dialog"
                    [width]="700"
                    [showActions]="false"
                    [showHeader]="false"
                    ariaLabelMessage="Close announcement popup"
                  >
                    <app-report-new-claim></app-report-new-claim>
                  </app-dialog>
                </ng-template>
                <ng-template #dialogReportNewClaimList>
                  <app-dialog
                    title="Confirmation"
                    ariaLabel="Confirmation dialog"
                    [showActions]="false"
                    ariaLabelMessage="Close announcement popup"
                  >
                    <app-claim-list-report-absence
                      [claims]="this.myClaims"
                      [showContinue]="true"
                      [redirectToSmartly]="true"
                    ></app-claim-list-report-absence>
                  </app-dialog>
                </ng-template>
              </div>
            </div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
      <ng-template #dialogLogoutConfirmation>
        <app-dialog
          [showHeader]="false"
          ariaLabel="Logout confirmation dialog"
          [width]="700"
          [showActions]="false"
          ariaLabelMessage="Close logout confirmation popup"
        >
          <app-end-claimant-preview></app-end-claimant-preview>
        </app-dialog>
      </ng-template>
    </div>
  `,
  styles: [
    `
      @media (max-width: 480px) {
        .stick {
          position: fixed !important;
          border-bottom: 1px solid #e0e0e0;
        }
      }

      .overlay h2 {
        padding-top: 20px;
      }

      .footer {
        color: white;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.2em;
        background-color: #01334c;
        padding: 20px;
      }
      .footer a {
        color: white;
      }
      .footer a:hover {
        color: #ffffff;
        text-decoration: solid underline #ffffff 2px !important;
        cursor: pointer;
      }
      .footer a:focus {
        border: 2px solid black !important;
        outline: 2px solid white !important;
      }
      .footer span {
        color: #ffffff;
        padding: 0 7px 0 7px;
      }
      .footer-box {
        display: flex;
        flex-grow: 1;
        max-width: 1280px;
        padding-left: 40px;
        align-items: center;
        text-align: center;
        margin: auto;
      }
      .footer-txt-center {
        text-align: center;
      }
      .sidenav-container {
        overflow-y: auto;
        height: 100%;
      }
      .loading-container {
        height: 100%;
        color: var(--color-input-grey);
        font-size: 14px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
      ::ng-deep .mat-list-item-content {
        justify-content: center !important;
      }
      mat-divider {
        border-top-color: #55b1e8 !important;
        margin-left: 20px;
        margin-right: 20px;
      }
      mat-sidenav-content {
        height: 100vh;
      }
      .contentmini {
        height: 100%;
      }
      .contentmax {
        height: 100vh;
      }
      ::ng-deep .mat-list-item {
        font-size: 13px !important;
        font-weight: bold;
        color: white !important;
        letter-spacing: 0.2em;
        text-align: center;
      }

      .mdc-list-item__content {
        text-align: center;
      }

      .app-mat-list-item {
        text-align: center;
      }

      .app-mat-list-item-span {
        font-size: 13px !important;
        font-weight: bold;
        color: white !important;
        letter-spacing: 0.2em;
        text-transform: uppercase;
      }

      ::ng-deep .mat-mdc-list-item-unscoped-content {
        font-size: 13px !important;
        font-weight: 700 !important;
        color: #fff !important;
        letter-spacing: 0.2em;
        text-align: center;
        text-transform: uppercase;
      }

      ::ng-deep .mat-progress-spinner circle,
      .mat-spinner circle {
        stroke: var(--color-sedgwick-blue);
      }
      .outlet {
        --max-width: 1280px;
        background-color: #f1f4f7;
      }

      .sidenav {
        width: 300px;
        background-color: #007dbc;
        color: white;
      }

      .sidenav .mat-toolbar {
        background: inherit;
      }

      .mat-toolbar.mat-primary {
        position: sticky;
        top: 0;
        z-index: 1;
        min-height: 64px;
      }

      .mat-toolbar {
        position: relative;
      }

      .toolbar {
        position: sticky;
        top: 0;
        z-index: 1;
        border-bottom: 1px solid #e0e0e0;
      }

      .more {
        position: absolute;
        width: 100%;
        bottom: 0;
      }

      .more-label {
        margin: 0 auto;
        z-index: 1;
        width: 70px;
        text-align: center;
        border-radius: 4px;
        padding: 4px;
        color: var(--color-accent);
        font-weight: bold;
        margin-bottom: 8px;
        background-color: white;
        border: 1px solid var(--color-accent);
      }

      .logo-image {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 20px;
      }

      .inflate-body {
        min-height: 100vh;
      }

      @media (max-width: 760px) {
        .footer {
          width: 100%;
          text-align: center;
        }
      }
      .example-spacer {
        flex: 1 1 auto;
      }

      .content {
        background-color: #f1f4f7;
      }

      .mat-mdc-toolbar {
        position: relative;
      }
      .active-link {
        background-color: rgb(3, 94, 129);
      }

      .app-sidenav-header {
        color: var(--color-sedgwick-blue);
        font-size: 16px;
        font-weight: bold;
        justify-content: center !important;
        letter-spacing: 0.1em;
      }

      .overlay {
        width: 800px;
      }

      .header {
        background-color: white;
        color: var(--color-input-grey);
      }

      .header-xs {
        background-color: white;
      }

      .welcome {
        color: #67707a;
        font-weight: bold;
      }
      mat-mdc-nav-list a {
        text-transform: uppercase;
      }

      .clientnamemob {
        font-size: 14px !important;
      }
      @media only screen and (max-device-width: 480px) {
        .clientname {
          display: none !important;
        }

        .mobscreensearch {
          margin-left: -40%;
          margin-top: 10%;
        }

        .mobscreen {
          margin-top: 10%;
        }

        .fixtoolbar {
          position: sticky;
          top: 64px;
          z-index: 999999999;
          min-height: 64px;
        }
        .fixheadertoolbar {
          position: sticky !important;
        }
      }

      .flex-left {
        width: 30%;
        float: left;
      }
      .flex-right {
        width: 0%;
      }
      .flex-mid {
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: center;
        float: left;
      }

      .lnks-mt {
        margin-top: 15px;
      }
      .lnks-inner-mt {
        margin-top: 5px;
      }
      .footer-fl-l {
        float: left;
      }
      .footer-fl-r {
        float: right;
      }
      .footer-font {
        font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.2em;
      }
      .prev-mode {
        width: 100vw !important;
      }
      .prev-mode-nav {
        margin-top: 63px;
      }
      .divider {
        outline: 3px solid grey !important;
        margin-left: 0px;
        margin-right: 0px;
      }

      .logo-above {
        max-width: 100%;
        padding-left: 20px;
        height: 62px;
      }
      .logo-inline {
        min-width: 324px;
        max-width: 100%;
        height: 62px;
      }

      .last-head-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      .mat-toolbar-row,
      .mat-toolbar-single-row {
        min-height: 64px;
        height: auto !important;
      }
    `,
  ],
})
export class LayoutComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  development = false;

  scrolled = false;
  isPreference = false;
  hasReportClaim = false;
  isIncidentalClient = false;
  isClaimant = false;
  isEmployee = false;
  showHistory = true;
  showHome = false;
  showWatchlist = true;
  showSearch = true;
  showMyClaims = false;
  showAccountSetting: boolean = true;
  showReportClaim: boolean = true;
  showDelegation: boolean = true;
  showHeaderIcons = false;
  welcomeMessageInfo: any;
  isAuditor = false;
  hideEmulatedUserHeader = false;
  myClaims: MyClaim[];
  isManager: boolean = false;
  terminated: boolean = false;

  clientHeader: string;
  hasEulaAgreement: any = undefined;
  helpUrl: string;
  logoalt: string;

  @ViewChild('dialogAnnouncements') dialogRefAnnouncements: TemplateRef<any>;
  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  @ViewChild('dialogReportNewClaimList')
  dialogRefReportNewClaimList: TemplateRef<any>;
  @ViewChild('dialogLogoutConfirmation')
  dialogRefLogoutConfirmation: TemplateRef<any>;

  eulaAgreements: EulaAgreements[];
  logoImage: string;
  eulaAgreementtext = '';
  appCompany: string = '© #YEAR SEDGWICK';
  userLogout: UserLogout;
  isSupervisorRole: boolean = false;
  claimant: IClaimant | null;
  mgrAction: UserActions | null;
  hasWorkSchedule: boolean = false;

  isController: boolean = false;
  showMenu: string = 'My claims';
  announcements: Announcements[];
  delegationEnabled: boolean = false;
  canActAsDelegatee: boolean = false;
  canManageOwnDelegates = false;
  canManageDelegators = false;
  clientLevelConfig: ConfigurationSetting;

  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  empUnum: string;
  isEmpDb: boolean;
  delegateeName: string;
  messageTitle: string;
  messageCount: number;

  showDelegationMenu = false;
  manageDelegates = false;
  actAsDelegate = false;
  manageOthersDelegators = false;
  hostname = window.location.hostname;

  @ViewChild('removeDialog') removeDialog: TemplateRef<any>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    private recentClaimService: RecentClaimService,
    public session: Session,
    public userdetails: UserDetails,
    public dialog: MatDialog,
    private http: Http,
    private config: ConfigurationService,
    public logService: LogService,
    private messageBus: MessageBus,
    private claimService: ClaimService,
    private userAction: UserActionService,
    public display: DisplayService,
    public delegationService: DelegationService,
    private titleCase: TitleCasePipe,
    private userRole: UserRoleService,
    private cookieService: CookieService,
    private delegateService: DelegationService,
    public commCenterService: CommCenterService
  ) {
    super();
    this.eulaAgreements = new Array<EulaAgreements>();
    this.development = !environment.production;
    this.subs.sink = this.messageBus
      .topic('layout:report-claim')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe((value) => {
        this.reportClaim(null);
      });
    this.subs.sink = this.delegationService.delegateName$.subscribe((name) => {
      this.delegateeName = name;
    });

    if (this.cookieService.get('ClearSessionStorage') === 'true') {
      this.cookieService.delete('ClearSessionStorage');
      sessionStorage.clear();
    }

    document.addEventListener('scroll', (ev) => {
      const deviceWidth = window.innerWidth;
      if (deviceWidth <= 480) {
        if (window.scrollY > 30) {
          document
            .getElementById('headerContext')
            .setAttribute('style', 'position: fixed !important');
        }
        if (window.scrollY <= 30) {
          document
            .getElementById('imagelogoabove')
            .setAttribute('style', 'display:block');
          document
            .getElementById('headerContext')
            .setAttribute('style', 'position: relative !important');
        }
      } else {
        if (document.getElementById('headerContext') != null) {
          document.getElementById('headerContext').removeAttribute('style');
        }
        if (document.getElementById('imagelogoabove') != null) {
          document.getElementById('imagelogoabove').removeAttribute('style');
        }
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.router.url.toLowerCase().indexOf('preferencespage') > -1 &&
        this.session.user.role === 'AUDITOR'
      ) {
        if (
          document.getElementById('homepagelink') != null &&
          document.getElementById('homepagelink') != undefined
        ) {
          document
            .getElementById('homepagelink')
            .setAttribute('style', 'display:block');
        }
      } else {
        if (
          document.getElementById('homepagelink') != null &&
          document.getElementById('homepagelink') != undefined
        ) {
          document
            .getElementById('homepagelink')
            .setAttribute('style', 'display:none');
        }
      }
    }, 200);
  }

  async ngOnInit() {
    await this.session.loadUser();
    // remove after pepsico transistions to 5.0
    if (
      this.session.user.isDelegating &&
      this.session.user.delegateeContext == null
    ) {
      const delegators: Delegatees = await this.delegateService.getDelegators(
        this.session.user.empUnum
      );
      const delegatee = delegators.delegatees.find(
        (delegatee: Delegatee) =>
          delegatee.delegatorEmpUnum === this.session.user.delegatorEmpUnum
      );
      await this.session.startDelegation(delegatee);
    }
    if (
      this.session.isDelegating() &&
      this.session.user.delegateeContext != null
    ) {
      this.delegateeName =
        this.session.user.firstName + ' ' + this.session.user.lastName;
    }
    // remove after pepsico transistions to 5.0

    const delegationFeatures = await this.display.delegationFeatures();
    this.showDelegationMenu = delegationFeatures.showDelegationMenu;
    this.actAsDelegate = this.display.actAsDelegateSignal();
    this.manageDelegates = delegationFeatures.manageOwnDelegates;
    this.manageOthersDelegators = delegationFeatures.manageOthersDelegates;
    this.isSupervisorRole = this.session.user.roles.supervisor;
    this.isManager = this.session.user.roles.manager;
    this.isController = this.session.user.roles.controller;
    this.terminated = this.session.user.emulatorContext?.isTerminated;

    if (this.isManager || this.session.user.roles.controller) {
      await this.recentClaimService.initWatchList(); //call watchlist to populate signal
    }

    //TODO condition to call delegation service
    // await this.delegationService.loadUpdateDelegatees(''); //call delegation to populate signal
    //await this.session.isDelegating; //populate delegation signal

    if (this.isManager) {
      this.mgrAction = this.userAction.getAction();
      if (this.mgrAction == UserActions.MgrViewEmpDb) {
        this.claimant = this.userAction.getClaimant();
        if (this.claimant?.empUnum) {
          this.empUnum = this.claimant.empUnum;
          this.isEmpDb = true;
        }
      }
    }

    this.helpUrl = environment.helpUrl;

    if (this.isController) {
      this.showMenu = 'Manager View';
    }
    this.hideEmulatedUserHeader = false;
    await this.hasIncidental();
    await this.refreshValues();

    if (this.session.user.role === 'AUDITOR' || this.session.user.isEmulating) {
      this.subs.sink = this.session.isEmulating$.subscribe(async () => {
        await this.refreshValues();
      });
    }

    if (this.isIncidentalClient) {
      this.router.navigate(['absence-dashboard']);
    }

    this.session.GetLogoDetails().then((dataLogo) => {
      if (dataLogo) {
        this.logoImage = dataLogo.clientLogoImg;
        if (this.logoImage != null && dataLogo.defaultLogo != 'Y') {
          this.logoalt = this.session.user.clientName + ' / MySedgwick Logo';
        } else {
          this.logoalt = 'MySedgwick Logo';
        }
      }
    });

    let data: EulaAgreements[] = [];
    if (
      !this.session.user.userType.includes('EMULATION') ||
      !this.session.user.isEmulating
    ) {
      const agreaments = await this.http.get<EulaAgreements[]>(
        `/api/user/Eula/eulaagreements`
      );
      data = agreaments;
    }

    if (
      data &&
      data.length > 0 &&
      !this.isIncidentalClient &&
      (this.session.user.emulatorContext == null ||
        (this.session.user.emulatorContext != null &&
          !this.session.user.emulatorContext.isEmulating))
    ) {
      const eulaAccepted = sessionStorage.getItem('eulaAccepted') === 'true';
      if (!eulaAccepted) {
        data.forEach((element) => {
          this.eulaAgreements.push(element);
        });
        this.hasEulaAgreement = true;
      } else {
        this.hasEulaAgreement = false;
        this.displayAnnouncements();
      }
    } else {
      this.hasEulaAgreement = false;
      this.displayAnnouncements();
    }

    this.showHeaderIcons =
      !this.hasEulaAgreement &&
      !this.isIncidentalClient &&
      !this.isClaimant &&
      !this.isEmployee
        ? true
        : false;

    this.subs.sink = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        // scroll the content back to the top on navigationEnd
        document.querySelector('.sidenav-container') != null
          ? (document.querySelector('.sidenav-container').scrollTop = 0)
          : null;
      });

    this.appCompany = this.appCompany.replace(
      '#YEAR',
      new Date().getFullYear().toString()
    );

    this.clientHeader =
      this.session.user.clientName + ' (' + this.session.user.contNum + ')';

    if (
      this.userAction.getAction() !== UserActions.MgrViewEmpClaim &&
      this.userAction.getAction() !== UserActions.MgrViewEmpSubpage &&
      this.userAction.getAction() !== UserActions.MgrViewOwnSubpage &&
      this.userAction.getAction() !== UserActions.ViewOwnMgrDb
    ) {
      if (this.isEmpDb && this.claimant?.empUnum) {
        this.myClaims = await (<any>(
          this.claimService.getClaimsEmpUNum(this.claimant?.empUnum)
        ));
      } else {
        this.myClaims = await (<any>this.claimService.getClaims());
      }
    }
    if (this.isAuditor) {
      await this.session.loadUser();
      let redirectLocation = await this.session.GetAuditorRedirectLocation();

      if (
        !this.session.user.isEmulating &&
        (redirectLocation === null || redirectLocation === undefined)
      ) {
        redirectLocation = this.router.url.toLowerCase().includes('preferences')
          ? 'preferences'
          : null;
      }
      switch (redirectLocation) {
        case 'preferences':
          this.router.navigate(['/PreferencesPage'], {
            queryParams: { focus: 'changepassword' },
          });
          break;
        case 'search':
        default:
          this.router.navigate(['search']);
      }
    }
    if (!this.userRole.auditor) {
      this.commCenterService.loadMessages();
    }
  }

  async displayAnnouncements() {
    if (
      this.session.hideAnnouncements &&
      this.session.user.role !== 'AUDITOR'
    ) {
      return;
    }

    // only show these once per session
    this.session.hideAnnouncements =
      this.session.user.role === 'AUDITOR' ? false : true;

    this.announcements = await this.http.get<Announcements[]>(
      `/api/user/Announcements`
    );
    if (this.announcements?.length != 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        announcements: this.announcements,
      };

      if (this.dialogRefAnnouncements) {
        this.dialog.open(this.dialogRefAnnouncements, dialogConfig);
      }
    }
  }

  async refreshValues() {
    this.isClaimant =
      this.session.user.role.toUpperCase() === 'HRM' &&
      (this.session.user.authenticationType === 'SAML' ||
        this.session.user.authenticationType === 'CLM' ||
        this.session.user.authenticationType === 'EMULATEE')
        ? true
        : false;
    this.isEmployee =
      this.session.user.role.toUpperCase() === 'HRM' &&
      (this.session.user.authenticationType === 'SAML' ||
        this.session.user.authenticationType === 'HRM' ||
        this.session.user.authenticationType === 'EMULATEE')
        ? true
        : false;
    this.isAuditor = this.session.user.role.toUpperCase() === 'AUDITOR';

    //setup visibility for nav menu and header items
    this.showMyClaims = this.isClaimant || this.isEmployee ? true : false;
    this.showHistory =
      this.isClaimant ||
      this.isEmployee ||
      this.isIncidentalClient ||
      this.isAuditor
        ? false
        : true;
    this.showSearch =
      this.isClaimant ||
      this.isEmployee ||
      this.isIncidentalClient ||
      this.isAuditor
        ? false
        : true;
    this.showWatchlist =
      this.isClaimant ||
      this.isEmployee ||
      this.isIncidentalClient ||
      this.isAuditor
        ? false
        : true;
    this.hasReportClaim = this.display.reportClaim;
    if (!this.isAuditor || this.session.isEmulating$) {
      this.commCenterService.loadMessages();
    }
  }

  tryClose(drawer: MatDrawer) {
    if (drawer && drawer?.mode === 'over') {
      drawer.close();
    }
  }

  showReportAbsence() {
    return (
      /*
        KP: Checking here that user is not delegating so, in left nav it will not display list of intermittent absences.
        Delegator is always going to be Manager
      */
      !this.session.isDelegating() &&
      this.display.reportIntermittentAbsence &&
      this.myClaims &&
      this.myClaims.findIndex(
        (claim) =>
          claim.status === 'Open' &&
          claim.lineOfBusiness === 'LV' &&
          claim.claimDescription.toLowerCase().indexOf('intermittent') > -1
      ) > -1
    );
  }

  reportClaim(drawer: MatDrawer) {
    if (!this.userRole.manager && this.showReportAbsence()) {
      this.dialog.open(this.dialogRefReportNewClaimList, {
        panelClass: 'claimListDialog',
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Confirmation',
      };
      const dlg = this.dialog.open(this.dialogRefReportNewClaim, dialogConfig);
      // this one already has padding
      dlg.updateSize('700px', '380px');
      this.tryClose(drawer);
    }
  }

  LogFeature(featureName: string, tabName: string) {
    this.logService.LogFeature(
      undefined,
      featureName,
      'Navigation' + ' - ' + tabName
    );
  }

  onScroll(event: Event) {
    this.scrolled = (event.target as HTMLElement).scrollTop > 0;

    // fire custom event so nested components can react to scrolling.
    // This is needed as scrollbar is on a div rather than window object.
    // const scrollEvent = new Event("app:layout:scroll");
    this.messageBus.publish('layout:scroll', event);
  }

  acceptedEulaAgreement(event: boolean) {
    this.hasEulaAgreement = event;

    if (this.hasEulaAgreement == false) {
      this.displayAnnouncements();
    }
  }

  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent, {
      disableClose: true,
    });
    this.subs.sink = dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.dialog.closeAll();
      }
    });
  }

  async hasIncidental() {
    this.isIncidentalClient = this.config.getConfigFeatureAccess(
      'IncidentalAbsences5'
    );
    const isManagerForIncident = this.config.getConfigFeatureAccess(
      'ManagerTextingPreferences'
    );
    if (this.isIncidentalClient) {
      this.showAccountSetting = isManagerForIncident;
    }
    this.hasWorkSchedule = this.display.myWorkSchedule;
  }

  async logOut() {
    if (
      this.session.isEmulating$ &&
      this.session.user.emulatorContext != null
    ) {
      this.dialog.open(this.dialogRefLogoutConfirmation);
    } else {
      sessionStorage.removeItem('eulaAccepted');
      await this.session.logoff();
    }
  }

  async redirecttosearch() {
    this.session.user.isEmulating ? await this.session.stopEmulation() : null;
    document
      .getElementById('homepagelink')
      .setAttribute('style', 'display:none');
    this.router.navigate(['search']);
  }

  getDashboardRoute(): string {
    return this.isController ? '/controller' : '/dashboard';
  }

  endDelegationConfirmation() {
    const dialogConfigDelegation = new MatDialogConfig();
    dialogConfigDelegation.ariaLabelledBy = 'confirmEndDelegation';
    this.dialog.open(this.removeDialog, dialogConfigDelegation);
  }

  async endDelegation() {
    const result = await this.session.endDelegation();
    if (result) {
      switch (true) {
        // remove after pepsico Transistions to 5.0
        case this.isIncidentalClient:
          this.router.navigate(['/absence-dashboard']);
          break;
        case this.userRole.supervisor:
          this.router.navigate(['/act-as-delegate']);
          break;
        case this.userRole.employee:
          this.router.navigate(['/act-as-delegate']);
          break;
        case this.userRole.overseer:
          this.router.navigate(['/act-as-delegate']);
          break;
        case this.userRole.omni:
          this.router.navigate(['/act-as-delegate']);
          break;
        default:
        // Handle other roles if necessary
      }
    }
  }

  getUserDisplayName() {
    return this.titleCase.transform(this.session.user.firstName);
  }

  redirectToPreferences() {
    this.router.navigate(['/PreferencesPage']);
  }
}
