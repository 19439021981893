import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  BaseComponent,
  ConfigurationSetting,
  RouterService,
  SearchResult,
  UserActionService,
  getUrlParams,
} from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { ClaimKey } from '../../../shared/models/claim-key';
import { UserActions } from '../../../shared/models/user';
import { HistoryInfo } from '../../models/historyinfo';
import { AccommodationsService } from '../../services/accommodations-service';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DisplayService } from '../../services/display-service';
import { RecentClaimService } from '../../services/recent-claim.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { UserDetails } from '../../services/userdetails.service';

@Component({
  selector: 'app-claims-page',
  template: `
    <app-section class="tw-w-full">
      <app-claim-breadcrumb class="tw-w-[80%]"></app-claim-breadcrumb>
      <div class="tw-w-[20%]  tw-right">
        <div
          class="CommCenterIcon breadcrumb fullPanelFL ln-height"
          *ngIf="countLoaded"
          (click)="setNotificationFocus('Notifications')"
        >
          <mat-icon [matBadge]="notificationCount" matBadgeColor="accent"
            >notifications_none</mat-icon
          >
        </div>

        <!--   <div
          class="CommCenterIcon breadcrumb fullPanelFL app-pl1 ln-height"
          *ngIf="unreadMessagesWithMax() >= 0"
          (click)="setCommCenterFocus()"
        >
          <mat-icon [matBadge]="unreadMessagesWithMax()" matBadgeColor="accent"
            >mail_outline</mat-icon
          >
        </div> -->
      </div>

      <ng-container *ngIf="claimId && claimConfig">
        <!-- ** Panel for Summary -->

        <app-claim-detail
          class="tw-w-full"
          [claim]="claim"
          [claimConfig]="claimConfig"
          id="claimDetail"
        ></app-claim-detail>

        <div class="tw-w-full tw-flex tw-flex-wrap">
          <div class="tw-w-full md:tw-w-[50%]" *ngIf="showClaimActivity()">
            <app-notifications-panel
              [claimInfo$]="claimInfo$"
            ></app-notifications-panel>
            <app-off-work-calendar
              *ngIf="
                displayService.offWorkCalendar &&
                (this.claim.lob === 'DS' ||
                  this.claim.lob === 'LV' ||
                  this.claim.lob === 'AD')
              "
              [claims]="claimKey"
              [showClaimNoLink]="true"
              [IsAccomodation]="this.claim.lob === 'AD'"
            ></app-off-work-calendar>
          </div>
          <div
            class="tw-w-full "
            [ngClass]="
              showClaimActivity() ? 'md:tw-w-[50%]' : 'tw-flex tw-flex-wrap'
            "
          >
            <app-comm-center2-messages-card
              [ngClass]="showClaimActivity() === false ? 'md:tw-w-[50%]' : ''"
              [claim]="claim"
              [focus]="focusCommCenter"
            />
            <app-contact-preferences
              *ngIf="
                !isController &&
                this.claimConfigService.getFeatureAccess('updatecontactinfo') &&
                !mgrViewingEmpClaim
              "
              class="tw-w-full"
              [ngClass]="showClaimActivity() === false ? 'md:tw-w-[50%]' : ''"
            ></app-contact-preferences>
          </div>
        </div>
      </ng-container>
    </app-section>
  `,
  styles: [
    `
      @media (min-width: 700px) {
        .CommCenterIcon {
          float: right;
          margin-right: 15px;
          height: 20px;
        }
      }
      .ln-height {
        line-height: 60px !important;
      }
      .prevpage {
        color: #007dbc;
        font-weight: bold;
        font-size: 14pt;
      }
      .prevpage:hover {
        text-decoration: none;
        border-bottom: 1px solid #035e81;
      }
      .currentpage {
        color: #171c20 !important;
        opacity: 1 !important;
      }
    `,
  ],
})
export class ClaimsPageComponent extends BaseComponent implements OnInit {
  claimId: string = '';

  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  claims: SearchResult[] = null;
  claim: SearchResult = <SearchResult>{};
  primaryClaim: SearchResult;
  previousUrlLink: string;
  previousUrlLabel: string;
  claimConfig: ConfigurationSetting;
  claimKey: ClaimKey[] = [];
  hasReturnToWork: boolean;
  isController: boolean = this.userRole.controller;
  isClaimant: boolean = this.userRole.claimant;
  isEmployee: boolean = this.userRole.employee;
  isSupervisor: boolean = this.userRole.supervisor;
  hasUploadDocs: boolean;
  historyinfo: HistoryInfo = <HistoryInfo>{};
  notificationCount: string;
  countLoaded: boolean = false;
  updatecont: boolean;
  commcenter: boolean;
  mgrAction: UserActions | null;
  mgrViewingEmpClaim: boolean = false;
  focusCommCenter: boolean;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    public claimService: ClaimService,
    private recentClaimService: RecentClaimService,
    public session: Session,
    public routerService: RouterService,
    private configurationService: ConfigurationService,
    public userdetails: UserDetails,
    public alertService: ActivityService,
    private cd: ChangeDetectorRef,
    public claimConfigService: ConfigurationService,
    public displayService: DisplayService,
    public userRole: UserRoleService,
    private userAction: UserActionService,
    private accommodationsService: AccommodationsService
  ) {
    super();
  }

  async ngOnInit() {
    this.subs.sink = this.route.queryParams.subscribe(() =>
      this.loadClaimFromUrl()
    );
    this.subs.sink = this.alertService.notificationCount.subscribe(
      (count: string) => {
        this.notificationCount = count;
        this.countLoaded = true;
        this.cd.detectChanges();
      }
    );

    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
      if (this.mgrAction == UserActions.MgrViewEmpClaim) {
        this.mgrViewingEmpClaim = true;
      }
    }

    const params = getUrlParams();
    if (params?.focus === 'comm-center') {
      this.focusCommCenter = true;
    }
  }

  claimChanged(claim: SearchResult) {
    this.setClaim(claim);
    this.location.replaceState(
      `/claims?claimId=${claim.claimId}&source=${claim.source}`
    );
  }

  private async loadClaimFromUrl() {
    const claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.historyinfo.claimId = claimId;
    const source = this.route.snapshot.queryParamMap.get('source');
    if (claimId) {
      const claim = await this.claimService.getSummary(source, claimId);
      this.setClaim(claim);
      this.recentClaimService.saveToHistory(
        source,
        claimId,
        this.claim.lob,
        this.historyinfo
      );
    }
  }

  private async setClaim(claim: SearchResult) {
    if (claim === null) {
      return;
    }
    this.claimId = claim.claimId;
    this.claimInfo$.next(claim);
    this.claim = claim;
    this.claimConfig = await this.configurationService.getClaimConfiguration(
      claim
    );

    this.claimKey = [];
    this.claimKey.push({
      claimId: this.claim.claimId,
      claimNumber: this.claim.claimNumber,
      lob: this.claim.lob,
      source: this.claim.source,
      userOwns: true,
      hrMasterUid: this.claim.hrMasterUid,
      empUnum: this.claim.empUnum,
    });
  }

  setNotificationFocus(type: any) {
    const notification = document.getElementById('activitypnl');
    if (notification != null) {
      this.alertService.setFocusToNotification(type);
      notification.scrollIntoView();
    }
  }

  setCommCenterFocus() {
    const commcenter = document.getElementById('commCenterPnl');

    if (commcenter != null) {
      commcenter.scrollIntoView();
    }
  }

  showClaimActivity(): boolean {
    const excludeLobs = ['AU', 'GL', 'PR'];
    return !excludeLobs.includes(this.claim.lob);
  }
}
