import {
  Component,
  Input,
  OnInit,
  Signal,
  ViewChild,
  computed,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  LogService,
  RecentClaim,
  RecentClaimService,
  UserRoleService,
} from 'src/app/my-sedgwick';
import {
  BaseComponent,
  ListComponent,
  SnackbarService,
  SnackbarType,
} from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-watch-list',
  template: `
    <app-panel
      title="Watch List"
      [largeHeader]="overlay ? false : true"
      [headerLevel]="overlay ? 2 : 1"
      [minHeight]="minHeightPnl"
      [canExpand]="false"
      [attr.role]="overlay ? 'dialog' : 'null'"
      [ariaLabel]="'Watch list'"
      [attr.role]="overlay ? 'dialog' : 'null'"
      [attr.aria-modal]="overlay ? true : false"
    >
      <app-loading-panel [state]="isLoading ? 'Loading' : 'Loaded'">
        <ng-template #content> </ng-template>
      </app-loading-panel>

      <app-list
        #table
        [dataSource]="
          overlay && isController
            ? loadOverlayControllerWatch()
            : recentClaimService.watchList()
        "
        [template]="row"
        [DefaultSortColumn]="isSupervisorRole ? 'openDate' : ''"
        [DefaultSortDir]="isSupervisorRole ? 'desc' : ''"
        class="app-pt2 app-rl8-selectall"
        [ShowSort]="overlay || claimsLength() === 0 ? false : true"
        [filterEnabled]="false"
        loadingMessage=""
        [noDataIcon]="isLoading ? '' : 'star'"
        [noDataHeader]="
          isLoading ? '' : 'No claims have been added to the watch list'
        "
        [noDataMessage]="
          isLoading
            ? ''
            : 'To add claims, click the star icon next to the Claim value in any list or page'
        "
        [loaded]="isLoading"
        [paginatorEnabled]="false"
        [showRowSelection]="overlay || claimsLength() === 0 ? false : true"
        [pageSize]="overlay ? 3 : 25"
        [overlay]="overlay"
        ariaLabelSelect="Select this claim to remove from watchlist"
        ariaLabelSelectAll="Select all claims to remove to watchlist"
        [tableAriaLabel]="'Watchlist'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap">
            <app-list-header
              name="claimNumber"
              label="Claim"
              class="tw-w-full md:tw-w-[40%] header-value"
              [names]="['claimDescription']"
              [sortEnabled]="!overlay"
            ></app-list-header>
            <app-list-header
              name="name"
              label="Name"
              class="tw-w-full md:tw-w-[40%] header-value"
              [names]="['name', 'employeeID']"
              [sortEnabled]="!overlay"
            ></app-list-header>
            <app-list-header
              name="openDate"
              label="Date Added"
              filterType="Date"
              [sortEnabled]="!overlay"
              class="tw-w-full md:tw-w-[20%] header-value"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>

      <ng-template #row let-element role="rowgroup">
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <td class="tw-w-full md:tw-w-[40%]">
            <app-value label="Claim" layout="row-xs">
              <app-claim-label
                value
                [claim]="element"
                layout="row"
                [hideWatchList]="true"
              >
              </app-claim-label>
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[40%]">
            <app-value label="Name" layout="row-xs">
              <app-claimant-label
                value
                [claimant]="element"
                [disabled]="!userRole.manager"
                layout="row"
              >
              </app-claimant-label>
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[10%]">
            <app-value
              [tabindex]="element.openDate ? '0' : '1'"
              label="Date Added"
              [value]="element.openDate | format : 'date'"
              layout="row-xs"
            >
            </app-value>
          </td>
          <div class="tw-w-full md:tw-hidden tw-block app-pb1">
            <a
              class="tw-block md:tw-hidden"
              (click)="deleteSelectedWatchList([element])"
              tabindex="0"
            >
              <b>Remove</b>
            </a>
          </div>
        </tr>
      </ng-template>
      <div class="md:tw-block tw-hidden">
        <app-button
          emphasis="Low"
          *ngIf="!overlay && !(claimsLength() === 0)"
          (click)="deleteSelectedWatchList(table.selectedItems)"
        >
          Remove
        </app-button>
      </div>
      <a routerLink="watchlist" *ngIf="overlay && !(claimsLength() === 0)"
        >View All</a
      >
    </app-panel>
  `,
  styles: [
    `
      .header-value {
        position: relative;
        left: 8px;
        padding-top: 10px;
        margin-bottom: -10px;
      }
    `,
  ],
})
export class WatchListComponent extends BaseComponent implements OnInit {
  @Input() overlay = false;

  @ViewChild('table', { static: false }) table: ListComponent;

  claims: RecentClaim[];
  isLoaded: boolean = false;
  isLoading: boolean = true;
  MaxWatchListCount: number;
  minHeightPnl: string = '70px';
  isSupervisorRole: boolean = false;
  isController: boolean = false;
  mgrAction: UserActions | null;
  watchListClaims: Signal<RecentClaim[]>;

  claimsLength = computed(() => {
    if (this.watchListClaims) {
      return this.watchListClaims()?.length;
    } else {
      return 0;
    }
  });

  constructor(
    public recentClaimService: RecentClaimService,
    public userRole: UserRoleService,
    public logService: LogService,
    private session: Session,
    private snackBarService: SnackbarService,
    private el: ElementRef
  ) {
    super();
  }

  waitForData() {
    if (this.table) {
      this.table.updateDataSource();
      this.isLoading = false;
      this.logFeature();
    } else {
      this.isLoading = true;
      setTimeout(() => {
        this.waitForData();
      }, 500);
    }
  }

  async ngOnInit() {
    this.isController = this.userRole.controller;
    this.isSupervisorRole = this.userRole.manager;
    this.mgrAction =
      this.isSupervisorRole || this.isController
        ? UserActions.ViewOwnMgrDb
        : null;
    this.watchListClaims = this.recentClaimService.watchList;
    this.waitForData();
  }

  async deleteSelectedWatchList(items: RecentClaim[]) {
    if (items.length == 0) {
      return;
    }
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackBarService.show(
        'Preview-only mode. Information not sent.',
        SnackbarType.Info
      );
    } else {
      const ClaimKeyList: string[] = [];

      for (let i = 0; i < items.length; i++) {
        ClaimKeyList.push(items[i].claimId);
      }

      await this.recentClaimService.deleteWatchList(ClaimKeyList);
    }
    this.isLoaded = true;
  }

  loadOverlayControllerWatch() {
    const items: RecentClaim[] = [];
    if (
      this.recentClaimService.watchList() != undefined &&
      this.recentClaimService.watchList().length > 0
    ) {
      this.recentClaimService.watchList().forEach((val, ind) => {
        if (ind < 3) {
          items.push(val);
        }
      });
    }
    return items;
  }

  logFeature() {
    const sectionName = this.overlay
      ? 'Recent Claims' + ' - ' + 'Watch List - Overlay'
      : 'Recent Claims' + ' - ' + 'Watch List';
    this.logService.LogFeature(undefined, 'WatchList', sectionName);
  }
}
