import { Component, Input, OnInit, effect } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimService, RecentClaimService } from 'src/app/my-sedgwick';
import {
  ClaimDataService,
  MyClaim,
  SnackbarService,
  SnackbarType,
} from 'src/app/shared';
import { UserRoleService } from '../..';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { EncryptionService } from '../../services/encryption.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-claim-label',
  template: `
    @if(claim) {
    <!---->
    @if(showNameLabel) {
    <div class="tw-font-bold">CLAIM</div>
    }
    <div class="tw-flex tw-content-center tw-items-center  tw-pr-1">
      @if(disabled) {
      <div class="tw-flex-auto">
        @if(showDescription){
        {{ claim.claimDescription | titlecase }} - }
        {{ claim.claimNumber }}
      </div>
      } @else {

      <a
        routerLink=""
        (keydown.enter)="navigateToClaim()"
        (click)="navigateToClaim()"
        (keydown.space)="navigateToClaim()"
      >
        @if(showDescription){
        {{ (claim.claimDescription | titlecase) + ' - ' }}
        }
        {{ claim.claimNumber }}
      </a>
      } @if(canShowWatchList && !hideWatchList && !userOwnsClaim &&
      !session.isDelegating()) { @if(updatingWatchList) {
      <mat-icon class="icon-updating">hourglass_top</mat-icon>
      } @else {
      <app-watch-list-button
        tabindex="0"
        role="button"
        [attr.aria-label]="
          watching ? 'Remove from watchlist' : 'Add to watchlist'
        "
        class="above-required"
        (click)="matMenuClick()"
        (keydown.enter)="matMenuClick()"
        [watching]="watching"
      ></app-watch-list-button>
      }}
    </div>
    }
  `,
  styles: [
    `
      .icon-updating {
        cursor: default;
        font-size: 20px;
        width: 20px;
        height: 20px;
        color: var(--color-input-grey);
      }
      .above-required {
        z-index: 1;
      }
    `,
  ],
})
export class ClaimLabelComponent implements OnInit {
  @Input()
  disabled = false;

  @Input()
  claim: any;

  /**
   * @deprecated Use disabled instead
   */
  @Input()
  get noLink(): boolean {
    return this.disabled;
  }
  set noLink(value: boolean) {
    this.disabled = value;
  }

  @Input()
  hideWatchList: boolean = false;

  @Input()
  showNameLabel: boolean = false;

  @Input()
  showDescription: boolean = true;

  watching: boolean = false;

  updatingWatchList = false;

  userOwnsClaim: boolean = true;

  canShowWatchList: boolean = false;

  isEmulating: boolean = false;

  /**
   * Effect will notify us when watchlist is modified
   */
  private watchListEffect = effect(() => {
    this.watching = this.recentClaimService
      .watchList()
      ?.some((x) => x.claimId === this.claim.claimId);
  });

  constructor(
    private router: Router,
    public recentClaimService: RecentClaimService,
    public session: Session,
    public userRole: UserRoleService,
    public claimDataService: ClaimDataService,
    private userAction: UserActionService,
    private encrypt: EncryptionService,
    private claimService: ClaimService,
    private snackBarService: SnackbarService
  ) {}

  async ngOnInit() {
    this.canShowWatchList =
      !this.userRole.claimant &&
      !this.userRole.employee &&
      !this.userRole.auditor;

    this.getUserOwnsClaim();
    this.session.loadUser();
    this.isEmulating =
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating;
  }

  async navigateToClaim() {
    let defaultClaim: MyClaim;
    const myClaim = { ...defaultClaim, ...this.claim };
    await this.claimDataService.setMyClaim(myClaim);
    await this.userAction.setClaimant(this.claim);
    this.userAction.setAction(
      (await this.userOwnsClaim)
        ? UserActions.ViewOwnClaim
        : UserActions.MgrViewEmpClaim
    );
    const encryptedEmpUnum = this.encrypt.encrypt(this.claim.empUnum);
    this.router.navigate(['claims'], {
      replaceUrl: true,
      queryParams: {
        claimId: this.claim.claimId,
        source: this.claim.source,
        lob: this.claim.lob,
        emp: !myClaim.userOwns ? encryptedEmpUnum : null,
      },
    });
  }

  async matMenuClick() {
    this.updatingWatchList = true;
    try {
      if (!this.watching) {
        if (this.isEmulating) {
          this.snackBarService.show(
            'Claim added to watch list - Preview-only mode. Information not sent.',
            SnackbarType.Info
          );
          this.watching = true;
        } else {
          await this.recentClaimService.addToWatchList(this.claim);
        }
      } else {
        if (this.isEmulating) {
          this.snackBarService.show(
            'Claim removed from watch list - Preview-only mode. Information not sent.',
            SnackbarType.Info
          );
          this.watching = false;
        } else {
          await this.recentClaimService.deleteWatchListItem(
            this.claim.source,
            this.claim.claimId
          );
        }
      }
    } finally {
      this.updatingWatchList = false;
    }
  }

  async getUserOwnsClaim() {
    const isClaimant = this.userRole.claimant || this.userRole.employee;
    if (isClaimant) {
      this.userOwnsClaim = true;
    } else if (this.userRole.controller) {
      this.userOwnsClaim = false;
    } else {
      const hasEmpUnums = this.session.user.empUnum && this.claim.empUnum;
      if (hasEmpUnums) {
        //match claim by empUnum
        this.userOwnsClaim = this.session.user.empUnum === this.claim.empUnum;
      } else {
        // match claim by claim id
        const claims = await this.claimService.getClaims();
        this.userOwnsClaim =
          claims.findIndex((x) => x.claimId === this.claim.claimId) >= 0;
      }
    }
  }
}
