import { Component, Input, OnInit } from '@angular/core';
import { ApprovedJobFunction } from '../../models/accommodations-interactions';

@Component({
  selector: 'app-approved-job-function',
  template: `
    <div
      class="tw-w-full"
      *ngFor="
        let essentialJFs of approvedJobFunction.essentialJobFunctions;
        let i = index
      "
    >
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <div>
          <span class="tw-font-bold">
            <mat-icon class="tw-text-green-500 check-icon">check</mat-icon>Job
            Function
          </span>
          <span> - </span>
          <span>{{ essentialJFs.approvedTask }}</span>
        </div>
        <div class="tw-flex tw-items-center " (click)="toggleExpand(i)">
          <mat-icon
            tabindex="0"
            class="expand-icon"
            [attr.aria-label]="
              expandState[i]
                ? 'Collapse this section'
                : 'Expand approved job functions'
            "
            >{{ expandState[i] ? 'expand_less' : 'expand_more' }}</mat-icon
          >
        </div>
      </div>
      <div [hidden]="!expandState[i]">
        <div class="tw-flex tw-flex-wrap tw-w-full">
          <div class="tw-w-full md:tw-w-[33%]">
            <h4 class="tw-font-bold tw-mb-2 header">IMPACTED PERIOD</h4>
            <app-value
              *ngIf="essentialJFs.impactStart"
              layout="row"
              class="tw-w-full"
              label="BEGIN"
              [value]="essentialJFs.impactStart | date : 'M/d/yyyy'"
            ></app-value>
            <app-value
              *ngIf="essentialJFs.impactEnd"
              layout="row"
              class="tw-w-full"
              label="END"
              [value]="essentialJFs.impactEnd | date : 'M/d/yyyy'"
            ></app-value>
            <app-value
              *ngIf="essentialJFs.impactedLongTerm"
              layout="row"
              class="tw-w-full"
              label="LONG TERM"
              [value]="essentialJFs.impactedLongTerm"
            ></app-value>
          </div>
          <div class="tw-w-full md:tw-w-[33%]">
            <h4 class="tw-font-bold tw-mb-2 header">RESTRICTION PERIOD</h4>
            <app-value
              *ngIf="essentialJFs.restrictionBegin"
              layout="row"
              class="tw-w-full"
              label="BEGIN"
              [value]="essentialJFs.restrictionBegin | date : 'M/d/yyyy'"
            ></app-value>
            <app-value
              *ngIf="essentialJFs.restrictionEnd"
              layout="row"
              class="tw-w-full"
              label="END"
              [value]="essentialJFs.restrictionEnd | date : 'M/d/yyyy'"
            ></app-value>
            <app-value
              *ngIf="essentialJFs.restrictionLongTerm"
              layout="row"
              class="tw-w-full"
              label="LONG TERM"
              [value]="essentialJFs.restrictionLongTerm"
            ></app-value>
          </div>
          <div class="tw-w-full md:tw-w-[33%]">
            <h4 class="tw-font-bold tw-mb-2 header">DETAILS</h4>
            <app-value
              *ngIf="essentialJFs.medicalNeeded"
              layout="row"
              label="MEDICAL NEEDED"
              class="tw-w-full"
              [value]="essentialJFs.medicalNeeded"
            ></app-value>
            <div
              *ngFor="
                let cognitiveRestriction of essentialJFs.cognitiveRestriction
              "
            >
              <app-value
                *ngIf="cognitiveRestriction"
                layout="row"
                label="COGNITIVE RESTRICTIONS"
                class="tw-w-full"
                [value]="cognitiveRestriction"
              ></app-value>
            </div>
            <div
              *ngFor="
                let physicalRestriction of essentialJFs.physicalRestriction
              "
            >
              <app-value
                *ngIf="physicalRestriction"
                layout="row"
                label="PHYSICAL RESTRICTIONS"
                [value]="physicalRestriction"
              ></app-value>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-w-full tw-mt-4">
          <div class="tw-w-full md:tw-w-[34%]">
            <h4 class="tw-font-bold tw-mb-2 header">MAJOR LIFE ACTIVITIES</h4>
            <div
              *ngFor="let majorLifeActivity of essentialJFs.majorLifeActivity"
            >
              <app-value
                *ngIf="majorLifeActivity"
                layout="col"
                [value]="majorLifeActivity"
              ></app-value>
            </div>
          </div>
          <div class="tw-w-full md:tw-w-[66%]">
            <h4 class="tw-font-bold tw-mb-2 header">BODY PARTS IMPACTED</h4>
            <div *ngFor="let bodyPart of essentialJFs.bodyPartImpacted">
              <app-value
                *ngIf="bodyPart"
                layout="col"
                [value]="bodyPart"
              ></app-value>
            </div>
          </div>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-w-full tw-mt-4 restriction-description"
        >
          <h4 class="tw-font-bold tw-mb-2 label">RESTRICTION DESCRIPTION:</h4>
          <span class="tw-mb-2">{{ essentialJFs.restrictionDescription }}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  `,
  styles: [
    `
      .check-icon {
        position: relative;
        top: 6px;
      }

      .header {
        background-color: #f1f4f7;
        color: var(--color-teal);
        padding: 8px 0px;
        display: flex;
        justify-content: space-between;
        align-items: left;
      }

      .label {
        color: var(--color-teal);
        padding: 8px 0px;
        display: flex;
        justify-content: space-between;
        align-items: left;
      }

      .restriction-description {
        display: flex;
        align-items: center;
      }

      .restriction-description .label {
        margin-right: 8px;
      }

      .expand-icon {
        color: var(--color-slate);
        cursor: pointer;
      }

      @media (max-width: 768px) {
        .header {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    `,
  ],
})
export class ApprovedJobFunctionComponent implements OnInit {
  @Input()
  approvedJobFunction: ApprovedJobFunction;

  expanded = false;
  expandState: any[] = [];

  constructor() {}
  ngOnInit(): void {
    this.approvedJobFunction.essentialJobFunctions.forEach((val, ind) => {
      this.expandState[ind] = false;
    });
  }

  toggleExpand(itemIndex: any) {
    //this.expanded = !this.expanded;
    this.expandState[itemIndex] = !this.expandState[itemIndex];
  }
}
