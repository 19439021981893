import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRoleService } from 'src/app/my-sedgwick';
import { BaseComponent } from 'src/app/shared';
@Component({
  selector: 'app-dialog',
  template: `
    <div class="app-p2 title" *ngIf="showHeader">
      <app-action-row>
        <h2 left1 class="app-header-2">{{ title }}</h2>
        <div right1 style="text-align: left">
          <button
            mat-icon-button
            (click)="close(cancelResult)"
            tabindex="0"
            aria-label="Close this dialog"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </app-action-row>
    </div>
    <div
      [attr.aria-label]="ariaLabel"
      mat-dialog-content
      class="content"
      [style.max-width.px]="width"
      [style.padding-bottom.px]="showActions ? 24 : 0"
      title=""
    >
      <ng-content></ng-content>
    </div>

    <div mat-dialog-actions align="end" *ngIf="showActions">
      <app-button
        autofocus
        (click)="confim(confirmResult)"
        emphasis="High"
        [id]="'Save' + confirmLabel"
        *ngIf="showConfirm"
        >{{ confirmLabel }}</app-button
      >
      <span class="app-button-margin"></span>
      <app-button
        id="btnCancel"
        (click)="close(cancelResult)"
        emphasis="Low"
        *ngIf="showCancel"
        >{{ cancelLabel }}</app-button
      >
    </div>
  `,
  styles: [
    `
      @media (min-width: 700px) {
        .content {
          min-width: 340px;
        }
      }
      .mat-icon-no-color {
        color: #67707a;
      }

      @media (max-width: 700px) {
        .calHeading {
          color: #171c20;
          font-size: 13px !important;
          font-weight: bold !important;
        }
      }
    `,
  ],
})
export class DialogComponent extends BaseComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  width: number;

  @Input()
  confirmLabel: string = 'Confirm';

  @Input()
  confirmResult: string = 'confirm';

  @Input()
  cancelLabel: string = 'Cancel';

  @Input()
  cancelResult: string = 'cancel';

  @Input()
  showCancel: boolean = true;

  @Input()
  showConfirm: boolean = true;

  @Input()
  closeOnConfirm: boolean = false;

  @Input()
  confirmOnce: boolean = false;

  @Input()
  showActions: boolean = true;

  @Input()
  showHeader: boolean = false;

  @Input()
  actionStyle: string;

  @Input()
  ariaLabelMessage: string = 'Close';

  @Output() confirmEvent = new EventEmitter();

  @Input() hideConfirm = () => {
    this.showConfirm = false;
  };

  @Input()
  SaveAlt: string = '';

  @Input()
  CancelAlt: string = '';

  @Output()
  testClosed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  afterClosed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  confirmAction = new EventEmitter<object>();

  @Input()
  btnConfirmAltText: string = '';

  @Input()
  btnConfirmTitleText: string = '';

  @Input()
  btnCloseAltText: string = '';

  @Input()
  btnCloseTitleText: string = '';

  @Input()
  customeHeadingClass: string;

  @Input()
  ariaLabel: string;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private userRole: UserRoleService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.showHeader = this.userRole.supervisor;
  }
  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe((result) => {
      this.showConfirm = true;
      this.testClosed.emit(result);
      this.afterClosed.emit(result);
    });
  }

  close(result: string) {
    this.dialogRef.close(result);
    this.afterClosed.emit(result);
    this.showConfirm = true;
  }

  confim(result: string) {
    this.confirmEvent.emit(result);
    if (this.confirmOnce === true) {
      this.showConfirm = false;
    }
    if (this.closeOnConfirm === true) {
      this.dialogRef.close();
    }
  }
}
