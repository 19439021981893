import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DisplayService } from 'src/app/my-sedgwick';
import { BaseComponent, UserRole } from '../../../shared';
import { Session } from '../../services/session.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportNewClaimComponent } from '../report-new-claim/report-new-claim.component';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';
import { environment } from 'src/environments/environment';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-site-map',
  template: `
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        class="tw-w-full"
        title="Site Map"
        [largeHeader]="true"
        [headerLevel]="1"
        [canExpand]="false"
      >
        <ul class="app-pl5" *ngIf="session.user">
          <li *ngIf="display.summaryView()">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                role="link"
                aria-label="Summary view"
                routerLink="/summary"
                >Summary view</a
              >
            </h2>
          </li>
          <li *ngIf="display.managerView() && !display.incidentalClient">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                role="link"
                aria-label="Manager view"
                routerLink="/supervisor"
                >Manager view</a
              >
            </h2>
          </li>
          <li *ngIf="display.controllerView()">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                role="link"
                aria-label="Manager view"
                routerLink="/controller"
                >Manager view</a
              >
            </h2>
          </li>
          <li
            *ngIf="
              display.myClaimsView() &&
              !display.incidentalClient &&
              !session.isDelegating()
            "
          >
            <h2 class="app-header-2">
              <a
                tabindex="0"
                role="link"
                aria-label="My claims"
                routerLink="/dashboard"
                >My claims</a
              >
            </h2>
          </li>
          <li *ngIf="display.search">
            <h2 class="app-header-2">
              <a
                routerLink="/search"
                title="Search"
                tabindex="0"
                role="link"
                aria-label="search"
                >{{ searchLabel }}</a
              >
            </h2>
          </li>
          <li *ngIf="display.incidentalClient">
            <h2 class="app-header-2">
              <a
                role="link"
                aria-label="Absences"
                routerLink="/absence-dashboard"
                title="Absences"
                tabindex="0"
                >Absences</a
              >
            </h2>
          </li>
          <li *ngIf="hasReportClaim && !display.incidentalClient">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                role="button"
                aria-label="Report a new claim"
                (keydown.enter)="reportClaim()"
                (click)="reportClaim()"
                >Report a new claim</a
              >
            </h2>
          </li>

          <li *ngIf="!session.isDelegating() && display.watchList">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                routerLink="/watchlist"
                title="Watch List"
                role="link"
                aria-label="Watch list"
                >Watch list</a
              >
            </h2>
          </li>
          <li *ngIf="!session.isDelegating() && display.history">
            <h2 class="app-header-2">
              <a
                routerLink="/history"
                title="History"
                tabindex="0"
                role="link"
                aria-label="History"
                >History</a
              >
            </h2>
          </li>
          <li *ngIf="display.myWorkSchedule">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                role="link"
                aria-label="My work schedule"
                routerLink="/my-work-schedule"
                title="My Work Schedule"
                >My work schedule</a
              >
            </h2>
          </li>
          <li
            *ngIf="
              !display.incidentalClient && this.session.user.role !== 'AUDITOR'
            "
          >
            <h2 class="app-header-2">
              <a
                tabindex="0"
                routerLink="/communication-center"
                title="Communication center"
                role="link"
                aria-label="Communication center"
                >Communication center</a
              >
            </h2>
          </li>
          <li
            *ngIf="
              !display.incidentalClient && this.session.user.role !== 'AUDITOR'
            "
          >
            <h2 class="app-header-2">
              <a
                tabindex="0"
                [queryParams]="{ focus: 'learningcenter' }"
                title="Helpful Resources"
                role="link"
                routerLink="/help"
                aria-label="Helpful Resources"
                >Helpful resources</a
              >
            </h2>
            <ul class="app-pl2">
              <li *ngIf="display.learningCenter">
                <h3 class="app-header-3">
                  <a
                    tabindex="0"
                    routerLink="/help"
                    [queryParams]="{ focus: 'learningcenter' }"
                    title="Learning center"
                    role="link"
                    aria-label="Learning center"
                    >Learning Center</a
                  >
                </h3>
              </li>
              <li>
                <h3 class="app-header-3">
                  <a
                    tabindex="0"
                    routerLink="/help"
                    [queryParams]="{ focus: 'links' }"
                    title="Helpful links"
                    role="link"
                    aria-label="Helpful links"
                    >Helpful links</a
                  >
                </h3>
              </li>
              <li>
                <h3 class="app-header-3">
                  <a
                    tabindex="0"
                    routerLink="/help"
                    [queryParams]="{ focus: 'videos' }"
                    title="Helpful videos"
                    role="link"
                    aria-label="Helpful videos"
                    >Helpful videos</a
                  >
                </h3>
              </li>
              <li>
                <h3 class="app-header-3">
                  <a
                    tabindex="0"
                    routerLink="/help"
                    [queryParams]="{ focus: 'documents' }"
                    title="Helpful documents"
                    role="link"
                    aria-label="Helpful documents"
                    >Helpful documents</a
                  >
                </h3>
              </li>
            </ul>
          </li>
          <li *ngIf="display.actAsDelegateSignal() && !session.isDelegating()">
            <h2 class="app-header-2">
              <a
                tabindex="0"
                routerLink="/act-as-delegate"
                title="Act as delegate"
                role="link"
                aria-label="Act as delegate"
                >Act as delegate</a
              >
            </h2>
          </li>
          <li
            *ngIf="
              (manageDelegates || manageOthersDelegators) &&
              !session.isDelegating()
            "
          >
            <h2 class="app-header-2">
              <a
                tabindex="0"
                routerLink="/manage-delegates"
                title="Manage delegates"
                role="link"
                aria-label="Manage delegates"
                >Manage delegates</a
              >
            </h2>
          </li>
          <li *ngIf="!session.isDelegating()">
            <h2 class="app-header-2">
              <a
                routerLink="/PreferencesPage"
                title="Account settings"
                role="link"
                aria-label="Account settings"
                >Account settings</a
              >
            </h2>
          </li>

          <li>
            <h2 class="app-header-2">
              <a
                tabindex="0"
                aria-label="Opens Technical Support popup"
                (click)="openSupportDialog($event)"
                (keydown.enter)="openSupportDialog($event)"
                role="button"
                >Technical support</a
              >
            </h2>
          </li>
          <li>
            <h2 class="app-header-2">
              <a
                tabindex="0"
                href="https://www.sedgwick.com/global-privacy-policy"
                target="privacy-notice"
                aria-label="Opens Privacy Policy"
                role="link"
                >Privacy policy</a
              >
            </h2>
          </li>
          <li>
            <h2 class="app-header-2">
              <a
                tabindex="0"
                href="https://www.sedgwick.com/terms-conditions"
                target="privacy-notice"
                aria-label="Terms and conditions"
                role="link"
                >Terms and conditions</a
              >
            </h2>
          </li>
          <li>
            <h2 class="app-header-2">
              <a
                tabindex="0"
                href="{{ this.helpUrl }}"
                target="privacy-notice"
                aria-label="open help"
                role="link"
                >Help</a
              >
            </h2>
          </li>
        </ul>
      </app-panel>
    </app-section>
  `,
  styles: [``],
})
export class SiteMapPageComponent extends BaseComponent implements OnInit {
  hasReportClaim = false;
  development = false;
  helpUrl: string;
  showDelegationMenu = false;
  manageDelegates = false;
  actAsDelegate = false;
  manageOthersDelegators = false;
  searchLabel: string = 'Claim search';

  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  @ViewChild('dialogReportNewClaimList')
  dialogRefReportNewClaimList: TemplateRef<any>;

  constructor(
    public session: Session,
    public dialog: MatDialog,
    public display: DisplayService,
    public userRole: UserRoleService
  ) {
    super();
  }

  async ngOnInit() {
    //console.log(this.session.user);
    this.hasReportClaim = this.display.reportClaim;
    this.development = !environment.production;
    this.helpUrl = environment.helpUrl;
    const delegationFeatures = await this.display.delegationFeatures();
    this.showDelegationMenu = delegationFeatures.showDelegationMenu;
    this.actAsDelegate = this.display.actAsDelegateSignal();
    this.manageDelegates = delegationFeatures.manageOwnDelegates;
    this.manageOthersDelegators = delegationFeatures.manageOthersDelegates;
    this.searchLabel =
      !this.session.user.isEmulating && this.userRole.auditor
        ? 'Search'
        : 'Claim search';
  }

  reportClaim() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
    };
    const dlg = this.dialog.open(ReportNewClaimComponent, dialogConfig);
    dlg.addPanelClass('app-dlg-padding');
    dlg.updateSize('700px', '450px');
  }

  openSupportDialog(event: Event): void {
    const dialogRef = this.dialog.open(SupportDialogComponent, {
      disableClose: true,
    });
    this.subs.sink = dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.dialog.closeAll();
      }
    });
    event.stopPropagation();
  }
}
