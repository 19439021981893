import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import {
  BaseComponent,
  LoadingState,
  PolicyCalc,
  SearchResult,
  getUrlParams,
} from 'src/app/shared';
import { LeaveBalances } from 'src/app/shared/models/LeaveBalanceInformation';
import { MyClaim } from '../../../shared';
import { ClaimService } from '../../services/claim.service';
import { UserRoleService } from '../../services/user-role.service';
import { HeaderLevel } from '../../../shared/components/controls/panel.component';

@Component({
  selector: 'app-leave-balance',
  template: `
    <app-section class="tw-w-full" [IsSmallBox]="false">
      <div *ngIf="showClaimBreadcrum" class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [title]="titlePanel"
        [largeHeader]="false"
        [headerLevel]="headerLevel"
        class="tw-w-full"
        [labelTemplate]="labelTemplate"
        *ngIf="!hidePanel"
        [expanded]="panelExpanded"
        [canExpand]="canExpand"
      >
        <ng-template #labelTemplate>
          <app-help-button
            *ngIf="!showClaimBreadcrum"
            [size]="24"
            [message]="help"
            [id]="'leaveBalanceToolTip'"
          ></app-help-button>
        </ng-template>
        <app-loading-panel
          [skeletonLines]="3"
          [state]="loading"
          [error]="error"
        >
          <ng-template #content>
            <div class="tw-flex tw-flex-wrap tw-w-full" *ngIf="showBalance">
              <div class="app-full-width">
                <fieldset>
                  <legend id="balance-summary-group" class="tw-hidden">
                    View leave balance summary by:
                  </legend>
                  <mat-radio-group
                    [(ngModel)]="duration"
                    style="float:right;"
                    (change)="onSelectedValueChange($event.value)"
                    aria-labelledby="balance-summary-group"
                    role="radiogroup"
                    aria-live="polite"
                  >
                    <mat-radio-button
                      class=""
                      value="hours"
                      tabindex="0"
                      aria-label="Selected Hours"
                    >
                      Hours
                    </mat-radio-button>
                    <mat-radio-button
                      class="app-pl2"
                      value="days"
                      tabindex="0"
                      aria-label="Selected Days"
                    >
                      Days
                    </mat-radio-button>
                    <mat-radio-button
                      checked="true"
                      class="app-pl2"
                      value="weeks"
                      tabindex="0"
                      aria-label="Selected Weeks"
                    >
                      Weeks
                    </mat-radio-button>
                  </mat-radio-group>
                </fieldset>
              </div>

              <div class="tw-w-full tw-flex tw-flex-col tw-pb-4">
                <div
                  *ngFor="let item of chartItems"
                  class="tw-w-full app-blue-container tw-py-2 tw-mb-3 tw-flex tw-flex-wrap tw-items-center"
                >
                  <div class="chart-title tw-w-full lg:tw-w-[40%] tw-py-2">
                    {{ item.policyCalc.name }} ({{ item.policyCalc.maxWeeks }}
                    {{ duration }})
                  </div>
                  <div class="chart-canvas-div tw-w-full lg:tw-w-[60%] tw-pr-2">
                    <canvas
                      [attr.id]="item.id"
                      [attr.aria-label]="
                        'Chart for ' +
                        item.policyCalc.name +
                        ' ' +
                        item.policyCalc.availableWeeks +
                        ' available ' +
                        duration +
                        ', ' +
                        item.policyCalc.pendingWeeks +
                        ' pending ' +
                        duration +
                        ', ' +
                        item.policyCalc.usedWeeks +
                        ' used ' +
                        duration
                      "
                      class="chart-canvas"
                      >{{ item.chart }}</canvas
                    >
                  </div>
                </div>
              </div>

              <div style="max-width: 600px;" *ngIf="showClaimBreadcrum">
                <app-toast type="Info">
                  <div [innerHTML]="help"></div>
                </app-toast>
              </div>
              <app-action-row class="tw-w-full">
                <app-button
                  *ngIf="showClaimBreadcrum"
                  right2
                  emphasis="High"
                  class="btn-close"
                  (click)="onClose()"
                >
                  Close
                </app-button>
              </app-action-row>
            </div>
            <div
              class="tw-flex tw-flex-col tw-items-center"
              *ngIf="!showBalance"
            >
              <div><mat-icon color="accent">list_alt</mat-icon></div>
              <div
                class="tagline-header app-pt1 app-pb1"
                style="color: #67707A;"
              >
                <strong>{{ noDataMessage }}</strong>
              </div>
            </div>
            <div
              aria-live="polite"
              aria-atomic="true"
              aria-relevant="all"
              class="sr-only"
            >
              <span>{{ liveRegionContent }}</span>
            </div>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      .btn-close:hover {
        background-color: #035e81;
      }
      .btn-close {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background-color: #007dbc;
        font-weight: bold;
      }
      .pb15 {
        padding-bottom: 15px;
      }
      .chart-title {
        font-size: 16px;
        font-weight: bold;
        padding: 8px 0px 8px 16px;
      }
      .message {
        font-weight: bold;
      }
      .chart-canvas {
        max-width: 320px;
        max-height: 85px;
        x-min-width: 295px;
        x-min-height: 85px;
      }
      .chart-canvas-div {
        text-align: center;
      }
      .icon {
        font-size: 64px;
        width: 64px;
        height: 64px;
      }

      .view-link {
        font-size: 20px;
      }
    `,
  ],
})
export class LeaveBalanceComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  duration: string = 'weeks';
  titlePanel: string = 'Leave Balance Summary';
  chart: any;

  chartItems: ChartItem[] = [];

  @Input()
  showBalance = true;

  @Input()
  hidePanel = false;

  @Input()
  claims: MyClaim[];

  @Input()
  policyCalcs: PolicyCalc[] = [];

  @Input()
  canExpand: boolean = false;

  @Input()
  headerLevel: HeaderLevel = 1;

  leaveBalance: LeaveBalances[] = [];

  showClaimBreadcrum: boolean = false;
  claimId: string;
  panelExpanded: boolean = true;
  source: string;
  noDataMessage: string;
  hasOpenLeaveClaims: boolean = false;
  help: string =
    'Available hours and days are an estimate and can vary based on changes to the work schedule. They are meant for informational purposes. Actual leave balances are calculated in weeks and portion of weeks rather than hours or days per policy guidelines.<br><br> Pending represents future time away from work or time away from work where a decision has not yet been made.';

  error: any;
  loading: LoadingState = 'Loading';
  claim: SearchResult;
  liveRegionContent: string = '';

  constructor(
    private router: Router,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private userRole: UserRoleService
  ) {
    super();
  }
  private async loadClaimFromUrl() {
    const params = getUrlParams();
    const fromClaim = params.fromClaim;
    this.showClaimBreadcrum = fromClaim && fromClaim == 'yes' ? true : false;
    this.claimId = params.claimId;
    this.source = params.source;
  }

  loadBalance() {
    if (this.leaveBalance && this.leaveBalance.length > 0) {
      this.showBalance = true;
      sessionStorage.setItem('showBalance', 'true');
      setTimeout(() => {
        for (const item of this.chartItems) {
          this.buildChartBar(item);
        }
      }, 0);
    }
    // console.log(this.chartItems);
  }

  buildChartBar(chart: ChartItem) {
    const policyCalc = chart.policyCalc;
    const id = chart.id;
    if (!document.getElementById(id) || chart.chart) {
      return;
    }
    chart.chart = new Chart(id, {
      type: 'doughnut',
      data: {
        labels: [
          `${policyCalc.usedWeeks} Used ${
            policyCalc.calculationMethod.charAt(0).toUpperCase() +
            policyCalc.calculationMethod.slice(1)
          }`,
          `${policyCalc.pendingWeeks} Pending ${
            policyCalc.calculationMethod.charAt(0).toUpperCase() +
            policyCalc.calculationMethod.slice(1)
          }`,
          `${policyCalc.availableWeeks} Available ${
            policyCalc.calculationMethod.charAt(0).toUpperCase() +
            policyCalc.calculationMethod.slice(1)
          }`,
        ],
        datasets: [
          {
            label: '',
            data: [
              policyCalc.usedWeeks,
              policyCalc.pendingWeeks,
              policyCalc.availableWeeks,
            ],
            backgroundColor: ['#035e81', '#007dbc', '#3f7c4f'],
          },
        ],
      },
      options: {
        animation: false,
        responsive: true,
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        layout: {
          padding: {
            left: -80,
          },
        },
        plugins: {
          legend: {
            position: 'right',
            labels: {
              font: {
                size: 13,
                weight: 'bold',
                family: '"Source Sans Pro", "Helvetica Neue", sans-serif',
              },
            },
            onClick: (event: any) => {
              // remove click events on the chart as it is not kb accessible, WCAG
              event.native?.stopPropagation();
            },
          },
          title: {
            display: false,
            text: policyCalc.name,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
    chart.chart.update('none');
  }
  ngAfterViewInit(): void {
    // if (sessionStorage.getItem('showBalance') === 'true') {
    if (this.showBalance) {
      setTimeout(() => {
        this.loadBalance();
      }, 0);
    }
  }

  async ngOnInit() {
    Chart.register(
      DoughnutController,
      BarController,
      CategoryScale,
      BarElement,
      LinearScale,
      ArcElement,
      Legend,
      Title,
      Tooltip
    );

    this.subs.sink = this.route.queryParams.subscribe((p) =>
      this.loadClaimFromUrl()
    );

    try {
      await this.GetLeaveBalanceSummary();
      this.setPanelStatus();
      if (this.leaveBalance && this.leaveBalance.length > 0) {
        this.UpdateChart();
      } else {
        this.showBalance = false;
        this.noDataMessage = 'You do not have any leave balance information';
        if (!this.hasOpenLeaveClaims) {
          this.noDataMessage = 'You do not have any open leave claims.';
        }
        sessionStorage.setItem('showBalance', 'false');
      }
      this.loading = 'Loaded';
    } catch (error) {
      this.error = error;
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    for (const item of this.chartItems) {
      item.chart?.destroy();
    }
  }
  onClose() {
    this.router.navigate(['/']);
  }
  async onSelectedValueChange(event: string) {
    if (event === 'weeks') {
      this.duration = 'weeks';
    } else if (event === 'hours') {
      this.duration = 'hours';
    } else if (event === 'days') {
      this.duration = 'days';
    }
    if (!this.leaveBalance || this.leaveBalance.length == 0) {
      await this.GetLeaveBalanceSummary();
    }
    this.UpdateChart();
    for (let i = 0; i < this.chartItems.length; i++) {
      const currentItemAnnouncement = this.chartItems[i].policyCalc
        ? `Duration changed to ${this.duration}. ${this.chartItems[i].policyCalc.name} has ${this.chartItems[i].policyCalc.availableWeeks} available ${this.duration}, a maximum of ${this.chartItems[i].policyCalc.maxWeeks} ${this.duration}, and currently has ${this.chartItems[i].policyCalc.pendingWeeks}  ${this.duration} pending, and currently has ${this.chartItems[i].policyCalc.usedWeeks} ${this.duration} used.`
        : null;
      this.liveRegionContent += currentItemAnnouncement + '\n';
    }
  }
  async GetLeaveBalanceSummary() {
    let result;
    if (
      this.userRole.manager &&
      this.claims &&
      this.claims.length > 0 &&
      this.claims.some((x) => x.lineOfBusiness === 'LV')
    ) {
      result = await this.claimService.getLeaveBalanceSummary(
        this.claims[0].hrMasterUid
      );
    } else if (this.userRole.employee) {
      const claims = await this.claimService.getClaims();
      if (claims.some((x) => x.lineOfBusiness === 'LV')) {
        result = await this.claimService.getLeaveBalanceSummary();
      }
    } else {
      this.claim = await this.claimService.getSummary(
        this.source,
        this.claimId
      );
      result = await this.claimService.getLeaveBalanceSummary(
        this.claim.hrMasterUid
      );
    }
    if (result && result?.leaveBalances && result?.leaveBalances.length > 0) {
      this.leaveBalance = result.leaveBalances;
    }
  }
  UpdateChart() {
    this.policyCalcs = [];
    this.leaveBalance.forEach((element) => {
      if (this.duration === 'weeks') {
        const week = {
          name: element.leavePolicy,
          maxWeeks: element.maxWeeks ? element.maxWeeks : 0,
          availableWeeks: element.availableWeeks ? element.availableWeeks : 0,
          usedWeeks: element.usedWeeks ? element.usedWeeks : 0,
          pendingWeeks: element.pendingWeeks ? element.pendingWeeks : 0,
          calculationMethod: this.duration,
        };
        this.policyCalcs.push(week);
      } else if (this.duration === 'hours') {
        const hour = {
          name: element.leavePolicy,
          maxWeeks: element.maxHours ? element.maxHours : 0,
          availableWeeks: element.availableHours ? element.availableHours : 0,
          usedWeeks: element.usedHours ? element.usedHours : 0,
          pendingWeeks: element.pendingHours ? element.pendingHours : 0,
          calculationMethod: this.duration,
        };
        this.policyCalcs.push(hour);
      } else if (this.duration === 'days') {
        const day = {
          name: element.leavePolicy,
          maxWeeks: element.maxDays ? element.maxDays : 0,
          availableWeeks: element.availableDays ? element.availableDays : 0,
          usedWeeks: element.usedDays ? element.usedDays : 0,
          pendingWeeks: element.pendingDays ? element.pendingDays : 0,
          calculationMethod: this.duration,
        };
        this.policyCalcs.push(day);
      }
    });
    let i = 0;
    this.chartItems = [];
    for (const policyCalc of this.policyCalcs) {
      const chartItem = new ChartItem();
      chartItem.id = `chart-${i++}`;
      chartItem.policyCalc = policyCalc;
      this.chartItems.push(chartItem);
    }
    this.loadBalance();
  }

  setPanelStatus() {
    if (this.claims != undefined) {
      for (const claim of this.claims) {
        if (
          claim.lineOfBusiness === 'LV' &&
          claim.status.toUpperCase() === 'OPEN'
        ) {
          this.panelExpanded = true;
          this.hasOpenLeaveClaims = true;
          break;
        } else {
          this.panelExpanded = false;
        }
      }
    }
  }
}

class ChartItem {
  id: string;
  chart: any;
  policyCalc: PolicyCalc;
}
