import { Component, Input } from '@angular/core';
import { OmniDashboard } from 'src/app/my-sedgwick';

@Component({
  selector: 'app-notifications-card',
  template: `
    <app-panel
      title="Notifications"
      [headerLevel]="2"
      [labelTemplate]="details"
      class="tw-cursor-pointer"
      routerLink="notifications"
      [canExpand]="false"
      tabindex="-1"
    >
      <ng-template #details>
        <div class="tw-w-full tw-flex tw-justify-end">
          <a
            class="tw-flex tw-items-center"
            *ngIf="items"
            routerLink="/summary/notifications"
            aria-label="Go to Notifications Details page"
          >
            <div class="app-pr1">DETAILS</div>
          </a>
        </div>
      </ng-template>
      <div class="container">
        <app-loading-panel
          [error]="error"
          [state]="items ? 'Loaded' : 'Loading'"
          loadingStyle="Dot"
        >
          <ng-template #content>
            <div class="tw-h-full tw-overflow-y-auto">
              <div class="tw-w-full tw-text-[18px] tw-font-bold ">
                @for(item of items;track item) {
                <div class="tw-flex tw-py-1 tw-pr-1">
                  <div class="app-color-accent tw-grow">
                    {{ item.label }}
                  </div>
                  <div class="tw-grow-0 app-color-slate-600">
                    {{ item.count }}
                  </div>
                </div>
                <div class="tw-pr-1">
                  <mat-divider></mat-divider>
                </div>
                }
              </div>
            </div>
          </ng-template>
        </app-loading-panel>
      </div>
    </app-panel>
  `,
  styles: [
    `
      .container {
        height: 345px;
      }
    `,
  ],
})
export class NotificationsCardComponent {
  @Input()
  set omniDashboard(value: OmniDashboard) {
    if (value) {
      this.items = [];
      const n = value.notifications;
      this.addItem(
        'Anticipated first day absence',
        n.anticipatedFirstDayAbsent
      );
      this.addItem('Anticipated return to work', n.anticipatedReturnToWork);
      this.addItem('Appeal determination', n.appealDetermination);
      this.addItem('Appeal received', n.appealReceive);
      this.addItem('Approved benefits ending', n.approvedBenefitsEnding);
      this.addItem('Benefits will exhaust', n.benefitsWillExhaust);
      this.addItem('Claim determination made', n.claimDeterminationMade);
      this.addItem('Claim determination due', n.claimDeterminationDue);
      this.addItem('Medical documents needed', n.medicalDocumentsNeeded);
    }
  }

  @Input()
  error: any;

  loaded = false;

  items: NotificationItem[];

  addItem(label: string, count: number) {
    this.items.push(new NotificationItem(label, count));
  }
}

class NotificationItem {
  constructor(public label: string, public count: number) {}
}
