import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared';
import { UserRoleService } from '../..';

@Component({
  selector: 'app-landing-page',
  template: ``,
  styles: [],
})
export class LandingPageComponent extends BaseComponent implements OnInit {
  constructor(private userRole: UserRoleService, private router: Router) {
    super();
  }

  ngOnInit() {
    if (this.userRole.omni || this.userRole.overseer) {
      this.router.navigate(['summary']);
    } else if (this.userRole.supervisor) {
      this.router.navigate(['supervisor']);
    } else if (this.userRole.controller) {
      this.router.navigate(['controller']);
    } else {
      this.router.navigate(['dashboard']);
    }
  }
}
