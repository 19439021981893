import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectOption, States, hideString } from 'src/app/shared';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Http } from 'src/app/shared/services/http.service';
import { UserPreference } from '../../models/UserPreference';
import { AccountSettingsService } from '../../services/accountsettings.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-contact-preferences',
  template: `
    <app-panel
      title="Your contact information"
      fxFlex="100"
      [headerLevel]="2"
      [largeHeader]="false"
      titleCollapse="Collapse contact information"
      titleExpand="Expand contact information"
    >
      <div fxLayout="row wrap">
        <app-value fxFlex="50" label="CONTACT ADDRESS">
          <span
            value
            [innerHTML]="
              {
                street1:
                  contactInformation?.street1 !== undefined
                    ? (contactInformation?.street1 | titlecase)
                    : '',
                street2:
                  contactInformation?.street2 !== undefined
                    ? (contactInformation?.street2 | titlecase)
                    : '',
                city:
                  contactInformation?.city !== undefined
                    ? (contactInformation?.city | titlecase)
                    : '',
                state: stateVal !== undefined ? stateVal : '',
                zipCode:
                  contactInformation?.zipcode !== undefined
                    ? contactInformation?.zipcode
                    : ''
              } | address
            "
          >
          </span>
        </app-value>
        <app-action-link
          class="app-pb1"
          title="Change contact address"
          tabindex="-1"
          routerLink="/yourcontactinformation"
          [queryParams]="{
            claimId: claimId,
            source: source
          }"
        ></app-action-link>
        <br />
        <div fxLayout="row wrap" *ngIf="showNotificationPreference">
          <mat-divider class="app-pb1"></mat-divider>
          <app-value fxFlex="50" label="ELECTRONIC NOTIFICATIONS"></app-value>
          <br />
          <app-value
            layout="row"
            fxFlex="50"
            label="Email Address"
            value="{{ emailAddress }}"
          ></app-value>
          <br />
          <app-value
            layout="row"
            fxFlex="50"
            label="Text/SMS"
            [value]="mobileNumber"
          ></app-value>
          <app-action-link
            class="app-pb1"
            tabindex="-1"
            title="Change communication preferences"
            routerLink="../PreferencesPage"
            [queryParams]="{
              focus: 'changepreferences',
              claimId: claimId,
              source: source
            }"
          ></app-action-link>
        </div>
      </div>
    </app-panel>
  `,
  styles: [],
})
export class ContactPreferencesComponent implements OnInit {
  @Input()
  claim: SearchResult;
  states: SelectOption[] = States.options;
  contactInformation: any;
  stateVal: string;
  claimId: string = '';
  source: string = '';
  commPreference: UserPreference;
  emailAddress: string;
  mobileNumber: string;
  showNotificationPreference: boolean = false;

  constructor(
    public claimConfigService: ConfigurationService,
    private session: Session,
    private route: ActivatedRoute,
    private accountService: AccountSettingsService,
    private http: Http
  ) {
    this.route.queryParams.subscribe((p) => this.loadClaimFromUrl());
  }

  async ngOnInit() {
    await this.GetCommPreferences();
    this.showNotificationPreference =
      await this.claimConfigService.getConfigFeatureAccess(
        'notificationpreferences'
      );
  }

  private async loadClaimFromUrl() {
    this.session.loadUser();
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
    if (this.claimId) {
      // Pass query params for caching. Claim summary makes same call.
      this.claim = await this.http.get<SearchResult>(
        `/api/claim/${this.source}/${this.claimId}/${'NA'}/summary`,
        {
          queryParams: {
            excludeClaimTracker: false,
            excludeFeatureLog: false,
          },
        }
      );
    }

    const list = await this.claimConfigService.GetExistingContactPrefernces(
      this.claim
    );
    if (list != undefined) {
      this.contactInformation = list;
      const stateList = this.states;

      for (const lists of stateList) {
        const vals = lists.value;
        const lab = lists.label;
        if (vals == this.contactInformation.state) {
          this.stateVal = lab;
        }
      }
    }
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.contactInformation.street1 = hideString(
        this.contactInformation.street1,
        '*'
      );
      this.contactInformation.street2 = hideString(
        this.contactInformation.street2,
        '*'
      );
      this.contactInformation.address = hideString(
        this.contactInformation.address,
        '*'
      );
      this.contactInformation.city = hideString(
        this.contactInformation.city,
        '*'
      );
      this.contactInformation.zipcode = hideString(
        this.contactInformation.zipcode,
        '*'
      );
      this.stateVal = hideString(this.stateVal, '*');
      this.emailAddress = hideString(this.emailAddress, '*');
      this.mobileNumber = hideString(this.mobileNumber, '*');
    }
  }

  async GetCommPreferences() {
    this.commPreference = await this.accountService.getCommPreferences();
    if (this.commPreference) {
      if (this.commPreference?.email && this.commPreference.isEmail == true) {
        this.emailAddress = this.commPreference.email;
      } else {
        this.emailAddress = 'Not enabled';
      }
      if (
        this.commPreference?.messagePhone &&
        this.commPreference.isMessage == true
      ) {
        const formatedphone = this.formatPhoneNumber(
          this.commPreference.messagePhone
        );
        this.mobileNumber = formatedphone;
      } else {
        this.mobileNumber = 'Not enabled';
      }

      // Following masking is needed if user tries to do hard refresh on the page
      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.emailAddress = hideString(this.emailAddress, '*');
        this.mobileNumber = hideString(this.mobileNumber, '*');
      }
    }
  }

  formatPhoneNumber(phoneNumberString: string): string {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  }
}
