import { Component, Input, OnInit } from '@angular/core';
import {
  ConfigurationService,
  DisplayService,
  Session,
  UserRoleService,
} from 'src/app/my-sedgwick';
import { SearchResult } from 'src/app/shared';

@Component({
  selector: 'app-claim-summary',
  template: `
    <div class="app-pt1 tw-flex tw-flex-wrap ">
      <div class="tw-flex tw-flex-wrap tw-w-full  ">
        <div class="tw-w-full md:tw-basis-1/3">
          <div class="tw-grid tw-grid-cols-[8rem] tw-gap-0.5">
            <ng-container>
              <div class="app-header-4 tw-col-span-2 tw-pt-2 ">Claim</div>
              <!--General-->
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Name"
                [value]="
                  claim.employeeId !== null &&
                  claim.employeeId !== undefined &&
                  claim.employeeId !== ''
                    ? claim?.name + ' - ' + claim?.employeeId
                    : claim?.name
                "
                layout="vertical"
              >
              </app-value>

              <div class="tw-col-span-2 tw-pt-2">
                <div class="tw-flex label-vertical-top labelTemplateClass">
                  <div class="row-value1 tw-w-[50%] labelTemplateClass">
                    Claim
                  </div>
                  <div class="row-value1 tw-w-[50%] labelTemplateClass">
                    <app-claim-label
                      value
                      [claim]="claim"
                      layout="row"
                      [disabled]="true"
                      [hideWatchList]="!(isController || userRole.manager)"
                    ></app-claim-label>
                  </div>
                </div>
              </div>

              <app-value
                *ngIf="claim?.lob === 'AD' && claim.requestType"
                class="tw-col-span-2 tw-pt-2"
                [label]="'Request Type'"
                [value]="claim.requestType"
                layout="vertical"
              >
              </app-value>

              <ng-container
                *ngIf="
                  (claim?.lob === 'LV' && claim.dateFirstAbsent) ||
                  claim?.lossDate
                "
              >
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  [label]="
                    claim?.lob === 'LV' || claim?.lob === 'DS'
                      ? 'Begin Date'
                      : 'Date'
                  "
                  [value]="
                    claim?.lob === 'LV'
                      ? (claim.dateFirstAbsent | format : 'date')
                      : (claim?.lossDate | format : 'date')
                  "
                  layout="vertical"
                >
                </app-value>
              </ng-container>

              <app-value
                *ngIf="claim?.lob !== 'AD'"
                class="tw-col-span-2 tw-pt-2"
                label="Status"
                [value]="claim?.status"
                layout="vertical"
              >
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD'"
                class="tw-col-span-2 tw-pt-2"
                label="Status"
                [value]="claim?.status + ' - ' + claim?.subStatus"
                layout="vertical"
              >
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD'"
                class="tw-col-span-2 tw-pt-2"
                label="Job Title"
                [value]="claim?.jobTitle"
                layout="vertical"
              >
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD' && claim?.exhaustionDate"
                class="tw-col-span-2 tw-pt-2"
                label="Job Protection Exhaustion Date"
                [value]="claim?.exhaustionDate | format : 'date'"
                layout="vertical"
              >
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD' && claim.certificationRequired"
                class="tw-col-span-2 tw-pt-2 "
                label="&nbsp;"
                layout="vertical"
                [valueTemplate]="certRequired"
              >
                <ng-template #certRequired>
                  <span class="app-indicator-blue ">
                    Certification Required
                  </span>
                </ng-template>
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD' && claim.filedLeaveOrSTD"
                class="tw-col-span-2 tw-pt-2"
                label="&nbsp;"
                layout="vertical"
                [valueTemplate]="fileLeave"
              >
                <ng-template #fileLeave>
                  <span class="app-indicator-blue">Filed Leave or STD</span>
                </ng-template>
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD' && claim.workerscompcase"
                class="tw-col-span-2 tw-pt-2 tams-bubble"
                label="&nbsp;"
                layout="vertical"
                [valueTemplate]="wc"
              >
                <ng-template #wc>
                  <span class="app-indicator-blue">Workers Compensation</span>
                </ng-template>
              </app-value>
              <app-value
                *ngIf="claim?.lob === 'AD' && claim.atWorkInjuryIllness"
                class="tw-col-span-2 tw-pt-2"
                label="&nbsp;"
                layout="vertical"
                [valueTemplate]="atWork"
              >
                <ng-template #atWork>
                  <span class="app-indicator-blue">At Work Injury/Illness</span>
                </ng-template>
              </app-value>
              <!--End General-->
            </ng-container>

            <ng-container *ngIf="claim?.lob === 'LV'">
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Leave Type"
                [value]="claim?.type"
                layout="vertical"
              >
              </app-value>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Leave Caused By"
                [value]="claim?.causedBy"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container
              *ngIf="
                (claim?.lob === 'AU' || claim?.lob === 'GL') && isController
              "
            >
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Total Paid"
                [value]="claim?.paidTotal"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container
              *ngIf="
                claim?.lob === 'AU' ||
                claim?.lob === 'PR' ||
                claim?.lob === 'GL'
              "
            >
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Type"
                [value]="claim?.type"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container *ngIf="claim?.lob === 'WC'">
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Anticipated Return to Work Date"
                [value]="claim?.anticipatedReturnToWorkDate"
                layout="vertical"
              >
              </app-value>

              <app-value
                *ngIf="isController && claim?.anticipatedReturnToWorkDuty"
                class="tw-col-span-2 tw-pt-2"
                label="Anticipated Return to Work Status"
                [value]="claim?.anticipatedReturnToWorkDuty"
                layout="vertical"
              >
              </app-value>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Work Status"
                [value]="claim?.workStatus"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container *ngIf="claim?.lob === 'AU'">
              <ng-container *ngIf="claim?.exPolicy">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Policy Number"
                  [value]="claim?.exPolicy"
                  layout="vertical"
                >
                </app-value>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="claim?.lob === 'AU' && displayOwnerDiverSection"
            >
              <div class="app-header-4 tw-col-span-2 tw-pt-2 ">
                Owner and Driver
              </div>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Vehicle Owner Name"
                [value]="claim?.vehicleInfo?.ownerName"
                layout="vertical"
              >
              </app-value>

              <ng-container *ngIf="claim?.vehicleInfo?.firstOwnerType">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Owner Type"
                  [value]="claim?.vehicleInfo?.firstOwnerType"
                  layout="vertical"
                >
                </app-value>
              </ng-container>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Driver Name"
                [value]="claim?.vehicleInfo?.driverName"
                layout="vertical"
              >
              </app-value>

              <ng-container *ngIf="claim?.vehicleInfo?.firstDriverTitle">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Driver Title"
                  [value]="claim?.vehicleInfo?.firstDriverTitle"
                  layout="vertical"
                >
                </app-value>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="claim?.lob === 'AU' && displayOwnerDiverSection"
            >
              <div class="app-header-4 tw-col-span-2 tw-pt-2 ">Vehicle</div>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Year"
                [value]="claim?.vehicleInfo?.year"
                layout="vertical"
              >
              </app-value>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Make"
                [value]="claim?.vehicleInfo?.make"
                layout="vertical"
              >
              </app-value>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Model"
                [value]="claim?.vehicleInfo?.model"
                layout="vertical"
              >
              </app-value>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="VIN Number"
                [value]="claim?.vehicleInfo?.vehicleNumber"
                layout="vertical"
              >
              </app-value>

              <ng-container *ngIf="claim?.vehicleInfo?.firstVehicleLocation">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Vehicle Location"
                  [value]="claim?.vehicleInfo?.firstVehicleLocation"
                  layout="vertical"
                >
                </app-value>
              </ng-container>

              <ng-container *ngIf="claim?.vehicleInfo?.firstVehicleEstimate">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Vehicle Estimate"
                  [value]="claim?.vehicleInfo?.firstVehicleEstimate"
                  layout="vertical"
                >
                </app-value>
              </ng-container>

              <ng-container *ngIf="claim?.vehicleInfo?.firstVehicleDamage">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Vehicle Damage"
                  [value]="claim?.vehicleInfo?.firstVehicleDamage"
                  layout="vertical"
                >
                </app-value>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                claim?.lob === 'AU' && claim?.vehicleInfo?.propertyDescription
              "
            >
              <div class="app-header-4 tw-col-span-2 tw-pt-2 ">
                Property Damage
              </div>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Description"
                [value]="claim?.vehicleInfo?.propertyDescription"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container
              *ngIf="claim?.lob === 'AU' && displayThirdPartySection"
            >
              <div class="app-header-4 tw-col-span-2 tw-pt-2 ">
                Third Party Carrier
              </div>

              <ng-container
                *ngIf="claim?.vehicleInfo?.carrierClaimNumber.length > 0"
              >
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Carrier Claim Number"
                  [value]="claim?.vehicleInfo?.carrierClaimNumber"
                  layout="vertical"
                >
                </app-value>
              </ng-container>

              <ng-container
                *ngIf="claim?.vehicleInfo?.carrierClaimOfficeName.length > 0"
              >
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Claim Office Phone"
                  [value]="claim?.vehicleInfo?.carrierClaimOfficeName"
                  layout="vertical"
                >
                </app-value>
              </ng-container>

              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Reported to Carrier"
                [value]="claim?.vehicleInfo?.carrierReported"
                layout="vertical"
              >
              </app-value>
            </ng-container>
            <ng-container
              *ngIf="
                (claim?.lob === 'WC' ||
                  claim?.lob === 'DS' ||
                  claim?.lob === 'LV') &&
                claim?.companionClaims?.length > 0
              "
            >
              <app-value
                class="tw-col-span-2"
                label="Companion Claims"
                layout="vertical"
                [valueTemplate]="companionClaimsTemplate"
              >
              </app-value>

              <ng-template
                #companionClaimsTemplate
                class="tw-col-span-2 md:tw-w-[50%]"
              >
                <div class="tw-col-span-1 md:tw-w-[50%]">
                  <ng-container *ngFor="let item of claim.companionClaims">
                    <a
                      routerLink="/claims"
                      [queryParams]="{
                        claimId: item?.claimUid,
                        source: item.recordSource
                      }"
                      >{{ item.fileNum }}</a
                    >
                    <br />
                  </ng-container>
                </div>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="claim?.lob === 'DS'">
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Claim in Appeal"
                [value]="claim?.erisaFlag"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container *ngIf="claim?.lob === 'LV'">
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Relationship"
                [value]="claim?.relationShip"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container *ngIf="claim?.lob === 'LV'">
              <app-value
                class="tw-col-span-2 tw-pt-2"
                label="Hours worked in the last 12 months"
                [value]="claim?.fmlaNumberHoursLast12Months"
                layout="vertical"
              >
              </app-value>
            </ng-container>

            <ng-container
              *ngIf="
                claim?.erisaFlag === 'Yes' &&
                this.claimConfigService.getFeatureAccess('appealdetails')
              "
            >
              <div class="app-header-4 tw-col-span-2 tw-pt-2 ">Appeal</div>
              <ng-container *ngFor="let item of claim?.listAppeals">
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Appeal Received"
                  [value]="item.appealReceived | format : 'date'"
                  layout="vertical"
                >
                </app-value>

                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Appeal Decision Due"
                  [value]="item.appealDecisionDue | format : 'date'"
                  layout="vertical"
                >
                </app-value>
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Appeal Decision Made"
                  [value]="item.appealDecisionMade | format : 'date'"
                  layout="vertical"
                >
                </app-value>
                <app-value
                  class="tw-col-span-2 tw-pt-2"
                  label="Appeal Determination"
                  [value]="item.appealDetermination"
                  layout="vertical"
                >
                </app-value>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="tw-w-full md:tw-basis-2/3">
          <app-action-tiles
            [showReturnToWork]="true"
            [showReimbursement]="true"
          ></app-action-tiles>
        </div>
      </div>

      <!-- Old Code -->
      <div class="app-pt3 app-pb2 tw-flex tw-flex-wrap tw-w-full">
        <ng-container *ngIf="ShowClaimTacker">
          <div class="line tw-w-full app-mt2 app-pt2"></div>

          <app-claim-timeline
            class="tw-w-full"
            [source]="claim?.source"
            [claimId]="claim?.claimId"
            [lob]="claim?.lob"
            [claim]="claim"
          ></app-claim-timeline>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .line {
        border-top: 1px solid #d2d8e1;
      }
    `,
  ],
})
export class ClaimSummaryComponent implements OnInit {
  @Input()
  claim: SearchResult;

  displayOwnerDiverSection: boolean = false;
  displayVehicleSection: boolean = false;
  displayThirdPartySection: boolean = false;
  isController: boolean = this.session.user.role.toUpperCase() === 'CONTROLLER';

  get ShowClaimTacker(): boolean {
    return this.dService.claimTracker;
  }

  constructor(
    public session: Session,
    public dService: DisplayService,
    public userRole: UserRoleService,
    public claimConfigService: ConfigurationService
  ) {}

  ngOnInit(): void {
    if (this.claim) {
      if (
        this.claim?.vehicleInfo?.ownerName ||
        this.claim?.vehicleInfo?.firstOwnerType ||
        this.claim?.vehicleInfo?.driverName ||
        this.claim?.vehicleInfo?.driverName
      ) {
        this.displayOwnerDiverSection = true;
      }
      if (
        this.claim?.vehicleInfo?.year ||
        this.claim?.vehicleInfo?.make ||
        this.claim?.vehicleInfo?.model ||
        this.claim?.vehicleInfo?.vehicleNumber ||
        this.claim?.vehicleInfo?.firstVehicleLocation ||
        this.claim?.vehicleInfo?.firstVehicleEstimate ||
        this.claim?.vehicleInfo?.firstVehicleDamage
      ) {
        this.displayVehicleSection = true;
      }

      if (
        this.claim?.vehicleInfo?.carrierClaimNumber ||
        this.claim?.vehicleInfo?.carrierClaimOfficeName ||
        this.claim?.vehicleInfo?.carrierOfficePhone ||
        this.claim?.vehicleInfo?.carrierReported
      ) {
        this.displayThirdPartySection = true;
      }
    }
  }
}
