import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  BaseComponent,
  ClaimDataService,
  ConfigurationSetting,
  MyClaim,
  RouterService,
  UserActionService,
} from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { UserRoleService } from '../..';
import { ActivityRequest } from '../../../shared/models/activity';
import { ClaimKey } from '../../../shared/models/claim-key';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { ActivityComponent } from '../activity/activity.component';

@Component({
  selector: 'app-controller-dashboard-page',
  template: ` <app-section>
    <div class="tw-w-full" [ngClass]="{ 'app-pt1': xSmall$ | async }">
      <app-page-header [label]="'Welcome'">
        <div class="container-header app-pb1">
          <div class="tw-flex tw-w-full tw-items-center">
            <div class="tw-flex-grow">
              <div class="tw-item-center tw-flex">
                <app-header-label class="tw-flex-none" type="Header1">
                  <div
                    class="app-header-1 app-pl2 headerPdl20"
                    *ngIf="!isController"
                  >
                    {{ 'Welcome' }}
                  </div>
                  <app-claim-breadcrumb
                    class="tw-w-[90%]"
                  ></app-claim-breadcrumb>
                </app-header-label>
              </div>
            </div>
            <div class="tw-flex-none">
              <div action *ngIf="isController">
                <button
                  mat-icon-button
                  style="color: var(--color-input-grey)"
                  alt="New Notifications"
                  class="nopadding"
                  role="button"
                  aria-label="View new notification/s"
                  tabindex="0"
                  *ngIf="countLoaded"
                  (click)="setNotificationFocus('Notifications')"
                >
                  <mat-icon
                    class="mat-badge-accent"
                    [matBadge]="notificationCount"
                    matBadgeColor="accent"
                    >notifications_none</mat-icon
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </app-page-header>
    </div>

    <div
      class="tw-w-full"
      *ngIf="this.welcomeMessageInfo && this.welcomeMessageInfo.length > 0"
    >
      <app-welcome [welcomeMessageInfo]="welcomeMessageInfo"></app-welcome>
    </div>

    <div class="tw-w-full">
      <app-myclaimspanel
        [isClaim]="true"
        [commCenterMsgCount]="unreadConversationMessagesCount"
        [ClientLevelConfig]="ClientLevelConfig"
        [activitiesMyClaims]="activitiesDashboard"
        [myclaimsLoaded]="isDashboardActivitiesLoaded"
        [hideNameColumn]="hideNameColumn"
        [mgrAction]="mgrAction"
        [employeeLabel]="employeeLabel"
        [myclaims]="dashboardmyClaims"
      ></app-myclaimspanel>
    </div>

    <div class="tw-w-full md:tw-w-[60%] comfloat-left">
      <div class="tw-flex tw-flex-wrap">
        <app-comm-center2-card />
      </div>
    </div>
    <div class="tw-w-full md:tw-w-[40%] comfloat-right">
      <div class="tw-flex tw-flex-wrap">
        <div class="tw-w-full">
          <app-visit-learning-center
            class="tw-w-full"
          ></app-visit-learning-center>
        </div>
      </div>
    </div>
  </app-section>`,
  styles: [
    `
      .CommCenterIcon {
        float: right;
        margin-right: 15px;
        margin-top: -15px;
      }
      @media (max-width: 740px) {
        .headerPdl20 {
          padding-left: 20px !important;
        }
        .nopadding {
          padding-left: 0px !important;
          width: 50px !important;
        }
      }
    `,
  ],
})
export class ControllerDashboardPageComponent
  extends BaseComponent
  implements OnInit
{
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  @ViewChild('activityPnl') activityPanel: ActivityComponent;
  xSmall$: Observable<boolean>;
  ClientLevelConfig: ConfigurationSetting;
  maxBadgeCount = 99;
  showLowerPanels: boolean = true;
  notificationCount: number;
  countLoaded: boolean = false;
  unreadConversationMessagesCount: number = 0;
  hasReportClaim: boolean = false;
  searchClasses: string;
  isClaimant: boolean;
  isEmployee: boolean;
  isController: boolean = false;
  showQuickSearch: boolean;
  showReportNewClaimWidget: boolean = true;
  welcomeMessageInfo: any;
  isDashboardActivitiesLoaded: boolean = false;
  activitiesDashboard: any;
  claimkey: ClaimKey[];
  displayClrButn: boolean = false;
  displayToastButn: boolean = false;
  activityRequest: ActivityRequest;
  welcomeLoaded: boolean = false;
  mgrAction: UserActions = UserActions.ViewOwnMgrDb;
  hideNameColumn: boolean = false;
  employeeLabel: string;
  dashboardmyClaims: MyClaim[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private alertService: ActivityService,
    private cd: ChangeDetectorRef,
    public configurationService: ConfigurationService,
    public routerService: RouterService,
    public claimService: ClaimService,
    public session: Session,
    private claimDataService: ClaimDataService,
    private userRole: UserRoleService,
    private activityService: ActivityService,
    public config: ConfigurationService,
    private userAction: UserActionService
  ) {
    super();
    this.xSmall$ = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((result) => result.matches));
  }

  async ngOnInit() {
    this.subs.sink = this.alertService.notificationCount.subscribe(
      (count: number) => {
        this.notificationCount = count > this.maxBadgeCount ? 99 : count;
        this.countLoaded = true;
        this.cd.detectChanges();
      }
    );

    this.mgrAction = UserActions.ViewOwnMgrDb;
    this.userAction.setAction(this.mgrAction);

    this.welcomeMessageInfo = await this.session.GetWelcomeMessage();
    this.welcomeLoaded = true;
    this.hasReportClaim =
      !this.session.user.hasIntake || this.session.user.hasIntake === undefined
        ? false
        : true;
    this.isController = this.userRole.controller;
    this.searchClasses = this.getSearchClasses();
    this.ClientLevelConfig =
      await this.configurationService.getAdditionalUserInformation();

    this.dashboardmyClaims = await (<any>this.claimService.getClaims());
    const path = '/api/manager/get-activities';
    this.activityRequest = {
      includeIndirects: false,
      userActions: UserActions.ViewOwnMgrDb,
      claimKeys: [],
      userOwns: false,
      empUnum: '',
    };
    try {
      this.activitiesDashboard = await this.activityService.getActivities(
        path,
        this.activityRequest
      );
      // console.log(this.activitiesDashboard);
    } catch (error) {
      console.error('Error getting activities:', error);
    } finally {
      this.isDashboardActivitiesLoaded = true;
    }

    this.showQuickSearch = this.isClaimant || this.isEmployee ? false : true;

    this.showReportNewClaimWidget = !this.hasReportClaim ? false : true;

    this.employeeLabel =
      this.session.user.firstName +
      ' ' +
      this.session.user.lastName +
      ' - ' +
      this.session.user.empUnum;
    if (this.userRole.controller) {
      this.hideNameColumn = true;
    }

    //this.setBreadcrumb(this.routerService.previousUrl);
  }

  unreadMessagesWithMax = (): number => {
    return this.unreadConversationMessagesCount > 99
      ? this.maxBadgeCount
      : this.unreadConversationMessagesCount;
  };

  unreadMessageCountCallback = (evt: any) => {
    const cnt = evt as number;
    this.unreadConversationMessagesCount += cnt;
  };

  visible(visible: boolean) {
    if (visible) {
      this.showLowerPanels = true;
    }
  }

  setNotificationFocus(type: any) {
    const notification = document.getElementById('activityPnl');
    if (notification != null) {
      this.alertService.setFocusToNotification(type);
      notification.scrollIntoView();
    }
  }

  setCommCenterFocus() {
    const commcenter = document.getElementById('commCenterPnl');
    if (commcenter != null) {
      commcenter.scrollIntoView();
    }
  }

  getSearchClasses() {
    let classList = '';
    if (this.hasReportClaim) {
      classList = 'tw-w-full md:tw-w-[50%] comfloat-left';
    } else {
      classList = 'tw-w-full md:tw-w-[100%] comfloat-left';
    }

    return classList;
  }

  previousUrlLink: string;

  previousUrlLabel: string;

  private setBreadcrumb(prevUrl: string) {
    this.previousUrlLink = prevUrl;
    this.previousUrlLabel =
      prevUrl === '/search'
        ? 'Search'
        : prevUrl === '/history'
        ? 'History'
        : prevUrl === '/watchlist'
        ? 'Watch List'
        : 'Home';

    if (this.previousUrlLabel === 'Home') {
      this.previousUrlLink = '';
    }
  }
}
