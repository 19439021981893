import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseComponent,
  ListComponent,
  RouterService,
  SearchResult,
  hideString,
} from 'src/app/shared';
import { Http } from 'src/app/shared/services/http.service';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-benefits',
  template: `
    <div *ngIf="hideDiv">
      <div>
        <h3 class="NotBoldH3header">BENEFIT PLAN</h3>
        <span *ngIf="showMaxBenefitDurationDate"
          ><span class="H1header">MAXIMUM BENEFIT DURATION DATE :</span></span
        ><span *ngIf="showMaxBenefitDurationDate">
          {{ maxBenifitDuration | format : 'date' }}</span
        >
        <br />
      </div>

      <app-list
        #benefitsLayout
        [dataSource]="benefitDetails"
        [template]="row"
        class="app-pt2"
        [filterEnabled]="false"
        loadingMessage=""
        [loaded]="isLoaded"
        [paginatorEnabled]="false"
        *ngIf="showBenefitDetails"
        [tableAriaLabel]="'Benefits'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full">
            <app-list-header
              name="fromDate"
              label="Begin Date"
              class="tw-w-full md:tw-w-[30%]"
              [names]="['fromDate']"
            ></app-list-header>

            <app-list-header
              name="thruDate"
              label="End Date"
              class="tw-w-full md:tw-w-[30%]"
              [names]="['thruDate']"
            ></app-list-header>

            <app-list-header
              name="frequency"
              label="Benefit Frequency"
              class="tw-w-full md:tw-w-[15%]"
            ></app-list-header>

            <app-list-header
              name="rate"
              label="Benefit Rate"
              class="tw-w-full md:tw-w-[20%]"
              *ngIf="showBenefitRate"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>
      <ng-template #row let-element>
        <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
          <td class="tw-w-full md:tw-w-[30%]">
            <app-value
              label="Begin Date"
              [value]="element.fromDate | format : 'date'"
              layout="row-xs"
              [tabindex]="0"
            >
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[30%]">
            <app-value
              label="End Date"
              [value]="element.thruDate | format : 'date'"
              layout="row-xs"
              [tabindex]="0"
            >
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[15%]">
            <app-value
              label="Benefit Frequency"
              [value]="element.frequency"
              layout="row-xs"
              [tabindex]="0"
            >
            </app-value>
          </td>
          <td class="tw-w-full md:tw-w-[20%]">
            <app-value
              label="Benefit Rate"
              [value]="
                !showMaskedRate ? (element.rate | currency) : element.rate
              "
              layout="row-xs"
              *ngIf="showBenefitRate"
              [tabindex]="0"
            >
            </app-value>
          </td>
        </tr>
      </ng-template>
    </div>
    <br />
    <h3 class="NotBoldH3header">Work Status</h3>
    <div class="tw-w-full tw-h-[40px]">
      <div class="tw-float-right">
        <mat-slide-toggle (change)="filterData($event)"> </mat-slide-toggle>
        <span class="tw-font-bold"> View Prior Entries</span>
      </div>
    </div>
    <app-list
      #table
      [dataSource]="workstatusinfo"
      [template]="row1"
      class="app-pt2"
      [filterEnabled]="false"
      loadingMessage=""
      noDataIcon="work_outline"
      noDataMessage="<br><strong>No work status details available</strong>"
      [loaded]="isLoaded"
      *ngIf="showWorkStatus"
      [tableAriaLabel]="'Work Status'"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="workstatus"
            label="Work Status"
            class="tw-w-full md:tw-w-[40%]"
            [names]="['worsStatus']"
          ></app-list-header>

          <app-list-header
            name="AsOf"
            label="AS OF"
            class="tw-w-full md:tw-w-[15%]"
          ></app-list-header>

          <app-list-header
            name="benefitBegin"
            label="Benefits Begin"
            class="tw-w-full md:tw-w-[15%]"
            [names]="['benefitBegin']"
          ></app-list-header>

          <app-list-header
            name="benefitEnd"
            label="Benefits End"
            class="tw-w-full md:tw-w-[15%]"
            [names]="['benefitEnd']"
          ></app-list-header>

          <!-- <app-list-header
              name="type"
                label="Type"
              class="tw-w-full md:tw-w-[15%]"
            ></app-list-header>-->

          <app-list-header
            name="benefit status"
            label="Benefit status"
            class="tw-w-full md:tw-w-[15%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row1 let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[40%]">
          <app-value
            label="Work Status"
            [value]="element.workstatus"
            layout="row-xs"
            [tabindex]="0"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="AS OF"
            [value]="element.effectiveDate | format : 'date'"
            layout="row-xs"
            [tabindex]="0"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Benefits Begin"
            [value]="
              element.benefitBegin !== null
                ? (element.benefitBegin | format : 'date')
                : null
            "
            layout="row-xs"
            [tabindex]="0"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Benefits End"
            [value]="
              element.benefitEnd !== null
                ? (element.benefitEnd | format : 'date')
                : null
            "
            layout="row-xs"
            [tabindex]="0"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Benefit status"
            [value]="element.benefitStatus"
            layout="row-xs"
            [tabindex]="0"
          >
          </app-value>
        </td>
      </tr>
    </ng-template>
    <br />
    <br />
  `,
  styles: [
    `
      .NotBoldH3header {
        font-size: 24px;
      }
      .H3header {
        font-size: 24px;
        font-weight: bold;
      }
      .H3headerBenefitsPlan {
        font-size: 24px;
      }
      .H1header {
        font-weight: bold;
      }
    `,
  ],
})
export class BenefitsComponent extends BaseComponent implements OnInit {
  @ViewChild('benefitsLayout') benefitsLayout: ListComponent;
  claimId: any;
  @Input()
  claim: SearchResult;
  source: any;
  isLoaded: boolean = false;
  maxBenifitDuration: any;
  workstatusinfo: WorkStatus[];
  data: any;
  benefitDetails: BenefitTable[];
  userLOB: any;
  workstatusdetails: WorkStatus[];
  isToggle: boolean = false;
  hasBenefits: boolean = false;
  hideDiv: boolean;
  isEmployee: boolean = this.userRole.employee;
  mgrAction: UserActions | null;
  showWorkStatus: boolean = false;
  showBenefitDetails: boolean = false;
  lob: string;
  showBenefitRate: boolean = true;
  showMaxBenefitDurationDate: boolean = true;
  showMaskedRate: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private activityService: ActivityService,
    public routerService: RouterService,
    public session: Session,
    private http: Http,
    private userRole: UserRoleService,
    public claimConfigService: ConfigurationService,
    private userAction: UserActionService
  ) {
    super();
    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
    }
    this.hasBenefits =
      this.claimConfigService.getFeatureAccess('Benefits') &&
      (this.userRole.employee ||
        (this.userRole.manager && this.mgrAction == UserActions.ViewOwnClaim) ||
        this.mgrAction == UserActions.MgrViewEmpClaim);
    this.showWorkStatus =
      this.isEmployee ||
      (this.userRole.manager && this.mgrAction == UserActions.ViewOwnClaim) ||
      (this.userRole.manager && this.mgrAction == UserActions.MgrViewEmpClaim);
  }

  async ngOnInit() {
    this.subs.sink = this.route.queryParams.subscribe(() =>
      this.loadClaimFromUrl()
    );
    this.lob = this.claim.lob;
    if (this.hasBenefits) {
      await this.getBenefitPlan();
    }
    this.getWorkStatusInfo();
  }
  private async loadClaimFromUrl() {
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
  }

  async getBenefitPlan() {
    const data = await this.http.get<any>(
      `/api/claim/${this.claimId}/${this.lob}/GetBenefits`
    );
    if (data != null || data != undefined) {
      this.benefitDetails = data.benefitSchedules;
      this.benefitDetails.forEach((element) => {
        if (
          this.session.user.emulatorContext &&
          this.session.user.emulatorContext.isEmulating
        ) {
          element.rate = hideString(element.rate.toString(), '*');
          this.showMaskedRate = true;
        }

        if (
          element.rate == null
          /*||
          (this.session.user.emulatorContext &&
            this.session.user.emulatorContext.isEmulating)*/
        ) {
          this.showBenefitRate = false;
        }
      });
      this.maxBenifitDuration = data.maximumBenefitDurationDate;
      if (this.maxBenifitDuration == '') {
        this.showMaxBenefitDurationDate = false;
      }
    }
    this.hideDiv =
      this.benefitDetails && this.benefitDetails.length > 0 ? true : false;
    this.showBenefitDetails =
      this.isEmployee ||
      (this.benefitDetails != null &&
        this.userRole.manager &&
        this.mgrAction == UserActions.ViewOwnClaim) ||
      this.mgrAction == UserActions.MgrViewEmpClaim;
  }

  async getWorkStatusInfo() {
    const data = await this.activityService.getWorkStatusInfo(
      this.source,
      this.claimId,
      this.claim.lob
    );
    if (
      data != null ||
      (data != undefined && this.isEmployee) ||
      (data != undefined &&
        this.userRole.manager &&
        (this.mgrAction == UserActions.ViewOwnClaim ||
          this.mgrAction == UserActions.MgrViewEmpClaim))
    ) {
      this.workstatusdetails = data;
      this.workstatusdetails.forEach((element) => {
        if (element.workstatus === 'AT WORK') {
          element.benefitBegin = null;
          element.benefitEnd = null;
          element.benefitStatus = '';
        }
      });
      this.filterData(false);
      this.isLoaded = true;
    }
    this.benefitsLayout?.setSort('fromDate', 'desc');
  }

  filterData(event: any) {
    if (this.workstatusdetails != null && this.workstatusdetails != undefined) {
      if (event.checked) {
        this.workstatusinfo = this.workstatusdetails;
        this.isLoaded = true;
      } else {
        this.workstatusdetails.forEach((element) => {
          if (
            element.benefitEnd != null &&
            element.benefitEnd.toString() > Date()
          ) {
            const data = this.workstatusdetails.reduce((a, b) =>
              a.benefitBegin > b.benefitBegin ? a : b
            );
            this.workstatusinfo = [];
            this.workstatusinfo.push(data);
          } else {
            this.workstatusinfo = [];
          }
        });
      }
    }
  }
}

class WorkStatus {
  workstatus?: string;
  duration?: string;
  benefitBegin?: Date;
  benefitEnd?: Date;
  type?: string;
  benefitStatus?: string;
  workStatusNumber?: number;
  effectiveDate?: Date;
}

class BenefitTable {
  BenefitFreq: string;
  rate: string;
  BenfitBeginDate: Date;
  BenefitEndDate: Date;
  BenefitDuration: Date;
}
