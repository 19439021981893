import { Component, OnInit } from '@angular/core';
import {
  DisplayService,
  OmniApi,
  OmniDashboard,
  SearchParameters,
  SearchService,
} from 'src/app/my-sedgwick';
import { BaseComponent } from 'src/app/shared';

@Component({
  selector: 'app-card-dashboard',
  template: `
    <div class="tw-w-full app-pt2 app-pb2">
      <div class="tw-w-full tw-flex tw-flex-wrap">
        <app-new-claims-card
          class="tw-w-full md:tw-w-[33%]"
          [omniDashboard]="omniDashboard"
          [error]="error"
        />
        <app-return-to-work-card
          class="tw-w-full md:tw-w-[33%]"
          [omniDashboard]="omniDashboard"
          [error]="error"
        />
        <app-off-work-card
          class="tw-w-full md:tw-w-[34%]"
          [omniDashboard]="omniDashboard"
          [error]="error"
        >
        </app-off-work-card>
        <div class="tw-w-full tw-flex tw-flex-wrap">
          @if(displayService.accommodationsCard) {
          <app-pending-accommodations-card
            class="tw-w-full md:tw-w-[33%]"
            [omniDashboard]="omniDashboard"
            [error]="error"
          />
          } @else {
          <app-report-claim-card class="tw-w-full md:tw-w-[33%]" />
          }

          <app-notifications-card
            class="tw-w-full md:tw-w-[33%]"
            [omniDashboard]="omniDashboard"
            [error]="error"
          />
          <app-open-claims-card
            class="tw-w-full md:tw-w-[34%]"
            [omniDashboard]="omniDashboard"
            [error]="error"
          />
        </div>
        <div class="tw-w-full md:tw-w-[33%] tw-flex-col tw-flex-wrap">
          @if(displayService.accommodationsCard) {
          <app-report-claim-card class="tw-w-full" />
          }
        </div>
        <div class="tw-w-full md:tw-w-[67%] tw-flex-col tw-flex-wrap">
          <app-comm-center2-card />
          <app-visit-learning-center class="tw-w-full containers-min-height" />
        </div>
      </div>
    </div>
  `,
  styles: [``],
})
export class CardDashboardComponent extends BaseComponent implements OnInit {
  omniDashboard: OmniDashboard = null;

  error: any;

  constructor(
    private omniApi: OmniApi,
    private searchApi: SearchService,
    public displayService: DisplayService
  ) {
    super();
  }

  async ngOnInit() {
    try {
      const conf = await this.searchApi.getConfiguration();
      const sp = new SearchParameters();
      sp.status = conf.status.map((x) => x.value);
      sp.lob = conf.lineOfBusiness.map((x) => x.value);
      sp.dsClaimType = conf.claimTypeDs.map((x) => x.value);
      sp.dsClaimSubstatus = conf.claimSubstatusDs.map((x) => x.value);
      sp.lvClaimType = conf.claimTypeLv.map((x) => x.value);
      sp.lvClaimSubstatus = conf.absenceSubstatusLv.map((x) => x.value);
      sp.lvAbsenceType = conf.absenceTypeLv.map((x) => x.value);
      sp.lvCausedBy = conf.causedBy.map((x) => x.value);
      sp.includeIndirectReports = false;
      this.omniDashboard = await this.omniApi.getOmniDashboard(sp);
    } catch (error) {
      this.error = error;
    }
  }
}
