import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayService } from 'src/app/my-sedgwick';
import { OutboundSsoService } from 'src/app/my-sedgwick/services/outbound-sso.service';
import { MessageBus } from 'src/app/shared';
import { OutboundSSO } from 'src/app/shared/models/user';

@Component({
  selector: 'app-report-claim-card',
  template: `
    <app-panel
      [border]="'Empty'"
      class="tw-cursor-pointer"
      (click)="panelClick()"
      [attr.aria-label]="a"
      tabindex="-1"
    >
      <div
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white app-bg-color-green tw-h-[425px] "
      >
        <ng-container *ngIf="canReport">
          <h2
            class="tw-text-[26px] app-mt5 app-mb4 tw-font-light"
            role="heading"
          >
            Report a claim
          </h2>
          <div class="app-mb5">
            <img
              src="./assets/images/reportaclaim-image.png"
              height="250"
              width="150"
              alt=""
              role="icon"
            />
          </div>
          <div class="app-mb5">
            <app-button emphasis="Low" [arialabel]="'Report a claim'"
              >Start</app-button
            >
          </div>
        </ng-container>

        <ng-container *ngIf="!canReport && this.outboundSSO === null">
          <div class="tw-text-[26px] app-mt5 app-mb4 tw-font-light">
            Search claims
          </div>
          <div class="app-mb5">
            <img
              src="./assets/images/search-icon.png"
              height="250"
              width="150"
              alt=""
            />
          </div>
          <div class="app-mb5">
            <app-button emphasis="Low">Search</app-button>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            this.outboundSSO !== null &&
            this.outboundSSO.ssoLinkManager !== null
          "
        >
          <div class="tw-text-[26px] app-mt5 app-mb4 tw-font-light">
            {{ this.outboundSSO.ssoLinkManager }}
          </div>
          <div class="app-mb5">
            <img
              src="./assets/images/search-icon.png"
              height="250"
              width="150"
              alt=""
            />
          </div>
          <div class="app-mb5"></div>
        </ng-container>
      </div>
    </app-panel>
  `,
  styles: [``],
})
export class ReportNewClaimCardComponent {
  canReport = true;
  outboundSSO: OutboundSSO;
  a: string = 'a';
  constructor(
    private display: DisplayService,
    private router: Router,
    private messageBus: MessageBus,
    public outboundSsoService: OutboundSsoService
  ) {
    this.outboundSSO = this.display.outboundSSO;

    this.canReport = this.display.reportClaim && !this.outboundSSO;
  }

  panelClick() {
    if (this.outboundSSO !== null && this.outboundSSO.ssoLinkManager !== null) {
      this.outboundSsoService.openSsoUrl(
        this.outboundSSO.ssoType,
        this.outboundSSO.ssoEndPoint
      );
    } else if (this.canReport) {
      this.messageBus.publish('layout:report-claim');
    } else {
      this.router.navigate(['/search']);
    }
  }
}
