import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  BaseComponent,
  NewClaim,
  ToastType,
  UserActionService,
  deepCopy,
} from 'src/app/shared';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { Excel, ExportRequest, UserRoleService } from '../..';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { RecentClaimService } from '../../services/recent-claim.service';
import { DismissDialogComponent } from './dismiss-dialog.component';
import { NewClaimsEditComponent } from './new-claims-edit.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-new-claims',
  template: `
    <app-list
      [dataSource]="newClaims"
      *ngIf="loaded"
      [template]="row"
      class="app-pt2"
      [filterEnabled]="true"
      [exportEnabled]="true"
      [noDataIcon]="noDataIcon"
      noDataHeader="No new claims"
      noDataMessage="Use the Search feature to find a claim"
      [toastInfoMessage]="toastInfoMessage"
      [displayInfoToastMsg]="displayInfoToastMsg"
      [toastErrorMessage]="toasErrorMsg"
      [displayErrorToastMsg]="displayErrorToastMsg"
      exportButtonAriaLabel="Export and download these new claims as a XLSX file"
      [tableAriaLabel]="'New Claims'"
      (export)="_export()"
    >
      <ng-container header>
        <tr class="tw-flex tw-flex-wrap tw-w-full">
          <app-list-header
            name="claimDescription"
            label="Claim"
            class="tw-w-full md:tw-w-[40%] pdl40"
            [names]="['claimNumber']"
          ></app-list-header>
          <app-list-header
            name="name"
            label="Name"
            class="tw-w-full md:tw-w-[20%]"
            [names]="['empId']"
          ></app-list-header>
          <app-list-header
            name="dateLoss"
            label="Detail"
            sortLabel="Date"
            filterType="Date"
            class="tw-w-full md:tw-w-[30%]"
            [names]="['firstDayAbsent', 'firstDayAbsentFmLv', 'lastDayWorked']"
          >
          </app-list-header>
          <app-list-header
            name=""
            label="{{ confirmLabel }}"
            readOnly
            class="tw-w-full md:tw-w-[10%]"
          ></app-list-header>
        </tr>
      </ng-container>
    </app-list>

    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
        <td class="tw-w-full md:tw-w-[40%]">
          <app-value label="Claim" layout="row-xs">
            <app-claim-label
              value
              [claim]="element"
              layout="row"
              (displayWatchListErrorMsg)="displayWatchListErrorMsg($event)"
            >
            </app-claim-label>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[20%]">
          <app-value label="Name" layout="row-xs">
            <app-claimant-label
              value
              [claimant]="element"
              [disabled]="isDisabled"
            >
            </app-claimant-label>
          </app-value>
        </td>

        <td class="tw-w-full md:tw-w-[30%]">
          <ng-container>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="
                (element.lob === 'DS' || element.lob === 'LV') && isSupervisor
              "
              layout="row"
              label="First Day Absent"
              [value]="
                (element.firstDayAbsent
                  ? element.firstDayAbsent
                  : element.firstDayAbsentFmLv
                ) | format : 'date'
              "
            >
            </app-value>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="element.lob === 'DS' && isSupervisor"
              layout="row"
              label="Last Day Worked"
              [value]="element.lastDayWorked | format : 'date'"
            >
            </app-value>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="element.lob === 'LV' && isSupervisor"
              layout="row"
              label="Hours Worked"
              [value]="element.hoursWorked | number"
            >
            </app-value>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="(element.lob === 'WC' && isSupervisor) || !isSupervisor"
              layout="row"
              label="Date of Loss"
              [value]="element.dateLoss | format : 'date'"
            >
            </app-value>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="(element.lob === 'WC' && isSupervisor) || !isSupervisor"
              layout="row"
              label="Date Reported To Sedgwick"
              [value]="element.dateReported | format : 'date'"
            >
            </app-value>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="(element.lob === 'WC' && isSupervisor) || !isSupervisor"
              layout="row"
              label="Claim Reopened"
              [value]="element.reopened | titlecase"
            >
            </app-value>
            <app-value
              [tabindex]="0"
              class="tw-w-full  "
              *ngIf="element.lob === 'AD' && isSupervisor"
              layout="row"
              label="Date Reported to Sedgwick"
              [value]="element.dateReported | format : 'date'"
            >
            </app-value>
          </ng-container>
        </td>

        <td
          class="tw-w-full md:tw-w-[10%] app-pb1"
          *ngIf="element.status !== 'INCIDENT'"
        >
          <app-icon-button
            (click)="
              !(isSupervisor || isController)
                ? controllerDismiss(element)
                : dismiss(element)
            "
            (keydown.enter)="
              !(isSupervisor || isController)
                ? controllerDismiss(element)
                : dismiss(element)
            "
            [icon]="!(isSupervisor || isController) ? 'highlight_off' : 'done'"
            label="Dismiss"
            [ariaLabelText]="'Confirm new claim for ' + element.claimLabel"
            tabindex="0"
            role="button"
          >
          </app-icon-button>
        </td>
      </tr>
    </ng-template>
  `,
  styles: [``],
})
export class NewClaimsComponent extends BaseComponent implements OnInit {
  @Input()
  newClaims: NewClaim[];

  @Output()
  export = new EventEmitter<ExportRequest>();

  userAction = UserActions.ViewOwnMgrDb;
  noDataIcon: string = 'list_alt';
  saveAlertRequest: SaveAlertActionRequest;
  displayToastMsg: boolean = false;
  displayInfoToastMsg: boolean = false;
  displayErrorToastMsg: boolean = false;
  toastMsg: string;
  toastInfoMessage: string;
  toasErrorMsg: string;
  toastIcon: string;
  toastType: ToastType;
  isController: boolean;
  isSupervisor: boolean;
  isEmployee: any;
  loaded = false;
  confirmLabel: string = 'Dismiss';
  isDisabled: boolean;
  constructor(
    public dialog: MatDialog,
    private activityService: ActivityService,
    private recentClaimService: RecentClaimService,
    public userrole: UserRoleService,
    private excel: Excel,
    private userActions: UserActionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isController = this.userrole.controller;
    this.isSupervisor = this.userrole.manager;
    this.subs.sink = this.activityService.alertSelected.subscribe(
      (x: string) => {
        this.newClaims = this.activityService.filterNewClaimAlerts(
          this.newClaims,
          x
        );
      }
    );
    if (
      this.userActions.getAction() == UserActions.ViewOwnMgrDb &&
      !this.userrole.controller
    ) {
      this.isDisabled = window.location.pathname === '/dashboard';
    } else {
      this.isDisabled = true;
    }
    if (this.newClaims?.length > 200) {
      this.displayInfoToastMsg = true;
      this.toastInfoMessage =
        'Please use the Search page to find a claim that is not available in this list.';
    }
    if (this.isSupervisor || this.isController) {
      this.confirmLabel = 'Confirm';
    }
    this.loaded = true;
  }

  displayWatchListErrorMsg(event: any) {
    this.displayErrorToastMsg = true;
    this.toasErrorMsg =
      this.recentClaimService.displayWatchListErrorMsg1(event);
  }
  dismiss(newClaim: NewClaim) {
    this.dialog.open(NewClaimsEditComponent, {
      data: newClaim,
    });
  }
  controllerDismiss(claim: any) {
    this.saveAlertRequest = new SaveAlertActionRequest({
      alertMasterUid: Number(claim.alertId),
      action: 'DISMISSED - VOE',
      sourceSystem: claim.source,
      claimNumber: claim.claimNumber,
      claimId: claim.claimId,
      userOwns: claim.userOwns,
      type: 'NewClaim',
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      saveAlertRequest: this.saveAlertRequest,
    };
    this.dialog.open(DismissDialogComponent, dialogConfig);
  }

  _export() {
    const titleCase = new TitleCasePipe();
    const claimRows = deepCopy(this.newClaims);
    for (const claim of claimRows) {
      if (claim.lob === 'LV') {
        claim.firstDayAbsent =
          claim.firstDayAbsent !== ''
            ? claim.firstDayAbsent
            : claim.dateReported;
        claim.lastDayWorked = null;
      }

      if (claim.lob === 'DS' || claim.lob === 'LV') {
        claim.dateLoss = null;
        claim.dateReported = null;
      }

      if (claim.lob === 'WC') {
        claim.lastDayWorked = null;
        claim.firstDayAbsent = null;
      }

      const formatName = (claim.name += '');
      if (formatName && formatName.length > 0 && formatName.indexOf(' ') > -1) {
        const arr1 = formatName.split(' ').map(item => item.trim());
        claim.name = arr1.map(x => titleCase.transform(x)).join(' ');
      }
    }
    const request = new ExportRequest();
    request.fileName = 'New Claims.xlsx';
    request.sheets = [
      {
        name: 'New Claims',
        rows: claimRows,
        headers: [
          { label: 'Line of Business', value: 'lob' },
          { label: 'Claim Type', value: 'claimDescription' },
          { label: 'Claim Number', value: 'claimNumber' },
          { label: 'First Name', value: 'name', format: 'first' },
          { label: 'Last Name', value: 'name', format: 'last' },
          { label: 'Employee ID', value: 'empId' },
          { label: 'Date of Loss', value: 'dateLoss', format: 'date' },
          { label: 'Last Day Worked', value: 'lastDayWorked', format: 'date' },
          {
            label: 'First Day Absent',
            value: 'firstDayAbsent',
            format: 'date',
          },
          { label: 'Hours Worked', value: 'hoursWorked' },
          {
            label: 'Date Reported To Sedgwick',
            value: 'dateReported',
            format: 'date',
          },
          { label: 'Claim Reopened', value: 'reopened' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }
}
