import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchResult, getUrlParams } from 'src/app/shared';
import {
  CertificationDetails,
  PreviousCertifications,
} from 'src/app/shared/models/CertificationDetails';
import { TimelineItem } from '../../models/Timeline';
import { ClaimService } from '../../services/claim.service';

@Component({
  selector: 'app-certifications',
  template: `
    <div class="app-pb1">
      <app-custom-message
        [listClientMessage]="this.claim?.listClientMessage"
        [keyForMessage]="'Certifications'"
      >
      </app-custom-message>
      <div class="header app-pt2">
        Remember, multiple certifications may exist for each claim.
        <br />The current status of this leave is based on the most recent
        certification received from the treatment provider as shown below.
      </div>

      <app-loading-panel [state]="!isLoaded ? 'Loading' : 'Loaded'">
      </app-loading-panel>
      <div *ngIf="isLoaded">
        <div
          *ngIf="showIllness"
          class="app-pt3 app-pb2 tw-flex tw-flex-wrap tw-w-full"
        >
          <div class="app-header-3 tw-py-2 tw-w-full col-black">
            Certified Absence Frequency & Duration
          </div>
          <ng-container>
            <div class="tw-grid tw-grid-cols-[150px_auto] tw-py-2">
              <div class="app-label font-size-11">ILLNESS</div>
              <div>{{ this.incapacityFrequency }}</div>
              <div class="app-label  font-size-11">APPOINTMENT</div>
              <div>
                {{ this.treatmentFrequency }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="app-header-3 tw-py-2 col-black">
          <h3>Certifications</h3>
        </div>
        <div *ngIf="this.timelineDetails && this.timelineDetails.length > 0">
          <div class="header app-pt1">
            The certification(s) below are related to your Family Medical leave
            for your {{ this?.relationship }}
          </div>
          <span>
            <label><b>Status</b></label>
            <label
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                this.currentCertificationStatus
              }}</label
            >
          </span>
        </div>
        <div
          class="app-pt3 app-pb2 tw-flex tw-flex-wrap tw-w-full"
          *ngIf="this.timelineDetails && this.timelineDetails.length > 0"
        >
          <ng-container>
            <div class="line tw-w-full app-mt2 app-pt2"></div>

            <app-claim-timeline
              class="tw-w-full"
              [source]="source"
              [claimId]="claimId"
              [lob]="claim?.lob"
              [claim]="claim"
              [isFromCertifications]="true"
              [tracker]="timelineDetails"
            ></app-claim-timeline>
          </ng-container>
        </div>
        <div *ngIf="!(this.timelineDetails && this.timelineDetails.length > 0)">
          <div class="header app-pt1">
            <h3>Current Certification</h3>
          </div>
          <div class="tw-flex tw-flex-col tw-items-center">
            <div>
              <!-- <mat-icon color="accent">Medical Information</mat-icon> -->
              <mat-icon
                class="material-symbols-outlined icon-md"
                [style.color]="iconColor"
              >
                medical_information
              </mat-icon>
            </div>
            <div class="tagline-header app-pt1 app-pb1" style="color: #67707A;">
              <strong>No current certifications</strong>
            </div>
          </div>
        </div>
        <div>
          <mat-slide-toggle
            *ngIf="
              this.previousCertifications &&
              this.previousCertifications.length > 0
            "
            (change)="showPrevious($event.checked)"
          >
            <span class="font-style-authPref"
              ><b>Show previous certifications</b>
            </span>
          </mat-slide-toggle>
        </div>
        <div
          *ngIf="
            showPreviousCerts &&
            this.previousCertifications &&
            this.previousCertifications.length > 0
          "
        >
          <app-list
            [dataSource]="previousCertifications"
            [template]="row"
            class="app-pt2"
            [exportEnabled]="false"
            [paginatorEnabled]="true"
            [paginatorTemplate]="certificatonPagination"
            noDataIcon="monetization_on"
            [tableAriaLabel]="'Certifications'"
          >
            <ng-container header>
              <tr class="tw-flex tw-flex-wrap tw-w-full">
                <app-list-header
                  name="beginDate"
                  label="date range"
                  class="tw-w-[20%]"
                ></app-list-header>
                <app-list-header
                  name="status"
                  label="Status"
                  class="tw-w-[15%]"
                ></app-list-header>
                <app-list-header
                  name="subStatus"
                  label="sub status"
                  class="tw-w-[15%]"
                ></app-list-header>
                <app-list-header
                  name="dateSent"
                  label="details"
                  class="tw-w-[17%]"
                ></app-list-header>
                <app-list-header
                  name="clarificationRequested"
                  label="clarification requested"
                  class="tw-w-[17%]"
                ></app-list-header>
                <app-list-header
                  name="clarificationSent"
                  label="clarification details"
                  class="tw-w-[16%]"
                ></app-list-header>
              </tr>
            </ng-container>
          </app-list>

          <ng-template #row let-element>
            <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
              <td class="tw-w-full md:tw-w-[20%]">
                <app-value
                  label="date range"
                  layout="row-xs"
                  [tabindex]="0"
                  [value]="
                    (element.beginDate === undefined
                      ? ''
                      : (element.beginDate | format : 'date') + ' - ') +
                    (element.endDate === undefined
                      ? ''
                      : (element.endDate | format : 'date'))
                  "
                >
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[15%]">
                <app-value
                  label="status"
                  [tabindex]="0"
                  layout="row-xs"
                  [value]="element.status"
                >
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[15%]">
                <app-value
                  label="sub status"
                  [tabindex]="0"
                  layout="row-xs"
                  [value]="element.subStatus"
                >
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[17%] ">
                <app-value
                  *ngIf="element.dateSent"
                  layout="row"
                  [tabindex]="0"
                  label="SENT"
                  [value]="element.dateSent | format : 'date'"
                ></app-value>
                <app-value
                  *ngIf="element.dateReceived"
                  layout="row"
                  label="RECEIVED"
                  [tabindex]="0"
                  [value]="element.dateReceived | format : 'date'"
                ></app-value>
                <app-value
                  *ngIf="element.dateReviewed"
                  layout="row"
                  label="REVIEWED"
                  [tabindex]="0"
                  [value]="element.dateReviewed | format : 'date'"
                ></app-value>
                <app-value
                  *ngIf="element.dateDue"
                  layout="row"
                  [tabindex]="0"
                  label="DUE"
                  [value]="element.dateDue | format : 'date'"
                ></app-value>
              </td>
              <td class="tw-w-full md:tw-w-[17%]">
                <app-value
                  label="clarification requested"
                  layout="row-xs"
                  [tabindex]="0"
                  [value]="element.clarificationRequested"
                >
                </app-value>
              </td>
              <td class="tw-w-full md:tw-w-[16%] ">
                <div *ngIf="element.clarificationRequested === 'Yes'">
                  <app-value
                    *ngIf="element.clarificationSent"
                    layout="row"
                    label="SENT"
                    [tabindex]="0"
                    [value]="element.clarificationSent | format : 'date'"
                  ></app-value>
                  <app-value
                    *ngIf="element.clarificationReceived"
                    layout="row"
                    label="RECEIVED"
                    [tabindex]="0"
                    [value]="element.clarificationReceived | format : 'date'"
                  ></app-value>
                  <app-value
                    *ngIf="element.clarificationReviewed"
                    layout="row"
                    label="REVIEWED"
                    [tabindex]="0"
                    [value]="element.clarificationReviewed | format : 'date'"
                  ></app-value>
                  <app-value
                    *ngIf="element.clarificationDue"
                    layout="row"
                    label="DUE"
                    [tabindex]="0"
                    [value]="element.clarificationDue | format : 'date'"
                  ></app-value>
                  <app-value
                    *ngIf="element.clarificationType"
                    layout="row"
                    [tabindex]="0"
                    label="TYPE"
                    [value]="element.clarificationType"
                  ></app-value>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template #certificatonPagination>
            <div
              class="inlineBlock"
              #tooltip="matTooltip"
              [matTooltip]=""
              matTooltipPosition="below"
              (mouseenter)="$event.stopImmediatePropagation()"
              (mouseleave)="$event.stopImmediatePropagation()"
              (click)="tooltip.toggle()"
            ></div>
          </ng-template>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .header {
        font-weight: bold;
      }
      .col-black {
        color: #171c20 !important;
      }
      .icon-md {
        font-size: 60px;
        width: 60px;
        height: 60px;
      }
      .font-size-11 {
        font-size: 11px !important;
        font-weight: 700 !important;
      }
    `,
  ],
})
export class CertificationsComponent implements OnInit {
  certifications$ = new BehaviorSubject([]);
  certification: CertificationDetails;
  previousCertifications: PreviousCertifications[];
  showIllness: boolean = false;
  showPreviousCerts: boolean = false;
  timelineDetails: TimelineItem[];
  source: string;
  iconColor = 'var(--color-slate)';
  @Input()
  claim: SearchResult;
  claimId: string;
  currentCertificationStatus: string;
  treatmentFrequency: string;
  incapacityFrequency: string;
  relationship: string;
  isLoaded: boolean = false;
  constructor(public claimService: ClaimService) {}
  async ngOnInit() {
    if (this.claim?.isIntermittent) {
      this.showIllness = true;
    }

    //let claimNumber = 'C310180257300004TC';
    const params = getUrlParams();
    this.source = params.source;
    this.claimId = params.claimId;
    const result = await this.claimService.getCertifications(
      this.claim.claimNumber,
      this.source,
      this.claimId,
      this.claim.lob
    );
    this.isLoaded = true;
    if (result) {
      this.currentCertificationStatus = result.currentCrertificateStatus;
      this.treatmentFrequency = result.treatmentFrequency;
      this.incapacityFrequency = result.incapacityFrequency;

      this.relationship = result.relationship;
      if (
        result.previousCertifications &&
        result.previousCertifications.length > 0
      ) {
        this.previousCertifications = result.previousCertifications;
      }
      if (
        result.currentCertificationTimeline &&
        result.currentCertificationTimeline.length > 0
      ) {
        this.timelineDetails = result.currentCertificationTimeline;
      }
    }
  }

  showPrevious(show: boolean) {
    this.showPreviousCerts = show;
  }
}
