import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IntakeService } from '../../services/intake.service';
import { LogService } from '../../services/log.service';
import { Session } from '../../services/session.service';
import { SnackbarService, SnackbarType } from 'src/app/shared';

@Component({
  selector: 'app-report-new-claim',
  template: `
    <ng-container *ngIf="isBusy">
      <mat-spinner
        color="accent"
        diameter="40"
        mode="indeterminate"
      ></mat-spinner>
    </ng-container>
    <div class="overlay">
      <div mat-dialog-title class="dialog-container tw-flex ">
        <app-action-row>
          <h2 left1 class="app-header-2 heading-popup">{{ title }}</h2>
          <div right1>
            <button
              mat-icon-button
              (click)="closeModalPopUp()"
              aria-label="ariaLabelMessage"
              tabindex="0"
              class="closebtn"
              alt="close"
              title="close"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </app-action-row>
      </div>
      <mat-dialog-content class="modal-body fltleft">
        <div>
          <p>{{ callout }}</p>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end" class="mySedwick-popupFooter">
        <app-button
          mat-button
          alt="Cancel and go back"
          [mat-dialog-close]="true"
          emphasis="Low"
          (click)="closeModalPopUp()"
          class="app-pr2"
        >
          Back
        </app-button>
        <app-button
          mat-button
          alt="Continue to Report a Claim"
          [mat-dialog-close]="true"
          emphasis="High"
          (click)="redirectToSmartly()"
        >
          Continue
        </app-button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
    `
      .titleheading {
        color: #171c20 !important;
      }
      .close.mat-button {
        float: right;
        position: relative;
        color: #67707a;
      }
      .modal-body {
        height: 200px;
      }
      .pad-right-5 {
        padding-right: 2em;
      }
      .closepnl {
        float: left;
        text-align: right;
      }
      .fltleft {
        float: left;
      }
      .continuebtn {
        background-color: #007dbc !important;
        color: #fff;
      }
      .continuebtn:hover {
        background-color: #035e81 !important;
      }
      .closebtn {
        background-color: #ffffff !important;
        color: #3f464d;
        border: 1px solid #3f464d;
      }
      .closebtn:hover {
        background-color: #f1f4f7 !important;
      }
      .roundbtn {
        border-radius: 24px;
      }

      ::ng-deep .cdk-overlay-pane {
        max-width: 60vw !important;
      }
    `,
  ],
})
export class ReportNewClaimComponent {
  title: string = 'Confirmation';
  callout: string = `You are about to open a new tab (or window) to report a claim. Ensure that any Popup Blockers are disabled.
    Refer to browser or device documentation for more information. You can either proceed or cancel this action using the buttons below.`;
  isBusy: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ReportNewClaimComponent>,
    private intakeService: IntakeService,
    private snackbarService: SnackbarService,
    public logService: LogService,
    public session: Session
  ) {}

  closeModalPopUp() {
    this.dialogRef.close();
  }

  async redirectToSmartly() {
    this.LogFeature('ReportNewClaim', 'Report New Claim');
    this.isBusy = true;

    const response = await this.intakeService.getSmartlyUrl();
    window.open(response.url, '_blank');
  }

  LogFeature(featureName: string, tabName: string) {
    this.logService.LogFeature(
      undefined,
      featureName,
      'Navigation' + ' - ' + tabName
    );
  }
}
