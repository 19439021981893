import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { reportNewClaim } from 'src/app/my-sedgwick/functions/report-newclaim';
import { scrollToElement } from 'src/app/shared';
import { SubSink } from 'subsink';
import { DynamicEventBindService } from '../..';
import { ExternalLinkDialogComponent } from '../../../shared/components/controls/dialog/external-link-dialog.component';
import { ConfigurationService } from '../../services/configuration.service';
import { LearningCenterService } from '../../services/learning-center.service';
import { OpenFileService } from '../../services/openfile.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-learning-center-ds',
  template: `
    <div #dynamicContentContainer class="tw-w-full tw-flex">
      <!-- CONTENT -->
      <!-- Introduction starts -->
      <div *ngIf="!loading" class="tw-w-full">
        <h2
          class="app-lc-header-2"
          id="lc-intro"
          *ngIf="isIntroduction"
          role="heading"
        >
          Introduction
        </h2>
        <div class="text">
          <p [innerHTML]="learningCenter.introductionResults[0].value"></p>
        </div>
        <h2
          *ngIf="isWhatisDS"
          [innerHTML]="learningCenter.introductionResults[1].value"
          class="app-lc-header-2"
          id="lc-what"
          role="heading"
        ></h2>
        <div class="app-lc-text" *ngIf="isWhatisDS">
          <div class="right-wrap tw-flex-none app-pl2 tw-hidden md:tw-block">
            <img
              src="./assets/images/img_LC_introphone_desktop.webp"
              width="69"
              height="100"
              alt=""
              aria-hidden="true"
              role="img"
            />
          </div>
          <p [innerHTML]="learningCenter.introductionResults[2].value"></p>
          <p [innerHTML]="learningCenter.introductionResults[3].value"></p>
          <p [innerHTML]="learningCenter.introductionResults[4].value"></p>
        </div>
        <!-- Introduction ends -->
        <!-- Disability Benefits Calculation -->
        <h2
          class="app-lc-header-2"
          id="lc-ben"
          role="heading"
          *ngIf="isBenefitsCalc"
        >
          Disability Benefits Calculations
        </h2>
        <div class="app-lc-text">
          <p [innerHTML]="learningCenter.benefits[0].value"></p>
        </div>
        <!-- Disability Benefits Calculation Ends -->

        <!-- Submitting Claim Information -->
        <h2 class="app-lc-header-2" id="lc-sub" role="heading">
          Submitting Claim Information
        </h2>
        <div *ngIf="isMgr; then mgrSubmitInfo; else nonMgrSubmitInfo"></div>
        <ng-template #mgrSubmitInfo>
          <div>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[0].value }}"
              icon="1"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[1].value
                "
              ></p>
              <p>
                <b>{{ learningCenter.submittingclaiminformations[2].value }}</b>
              </p>
              <div class="app-p">
                <ul class="tw-list-disc tw-list-inside">
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[3]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[3].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[4]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[4].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[5]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[5].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[6]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[6].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[7]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[7].value }}
                  </li>
                </ul>
              </div>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[8].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[9].value }}"
              icon="2"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[10].value
                "
              ></p>
              <p
                *ngIf="isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[11].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[12].value }}"
              icon="3"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[13].value
                "
              ></p>
              <p
                *ngIf="isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[14].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[15].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[16].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[17].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[18].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[19].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[20].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              *ngIf="
                (this.claimConfigService.getFeatureAccess('UploadDocuments') &&
                  isMgr &&
                  isShown) ||
                learningCenter.submittingclaiminformations[23]?.value ||
                learningCenter.submittingclaiminformations[24]?.value ||
                learningCenter.submittingclaiminformations[25]?.value
              "
              label="{{ learningCenter.submittingclaiminformations[21].value }}"
              icon="4"
              layout="bullet"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
              >
                <app-learning-center-section
                  *ngIf="
                    this.claimConfigService.getFeatureAccess(
                      'UploadDocuments'
                    ) &&
                    isMgr &&
                    isShown
                  "
                  class="tw-basis-1/4 app-color-teal"
                  layout="icon-small"
                  icon="upload_file"
                >
                  <a
                    tabindex="0"
                    label="Documents"
                    class="go-to-documents-upload"
                    >Upload Documents</a
                  >
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[23]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="mail"
                >
                  Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[23]?.value
                    "
                  ></span>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[24]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="fax"
                >
                  Fax:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[24]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[25]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="laptop_mac"
                >
                  E-Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[25]?.value
                    "
                  ></span>
                </app-learning-center-section>
              </div>
            </app-learning-center-section>
          </div>
        </ng-template>
        <ng-template #nonMgrSubmitInfo>
          <div>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[0].value }}"
              icon="1"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[1].value
                "
              ></p>
              <p>
                <b>{{ learningCenter.submittingclaiminformations[2].value }}</b>
              </p>
              <div class="app-p">
                <ul class="tw-list-disc tw-list-inside">
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[3]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[3].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[4]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[4].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[5]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[5].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[6]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[6].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[7]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[7].value }}
                  </li>
                </ul>
              </div>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[8].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[9].value }}"
              icon="2"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[10].value
                "
              ></p>
              <ng-container *ngIf="isShown">
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[11].value
                  "
                ></p>
              </ng-container>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[12].value }}"
              icon="3"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[13].value
                "
              ></p>
              <ng-container *ngIf="isShown">
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[14].value
                  "
                ></p>
              </ng-container>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[15].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[16].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[17].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[18].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[19].value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[20].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              *ngIf="
                (this.claimConfigService.getFeatureAccess('UploadDocuments') &&
                  !isMgr &&
                  isShown) ||
                learningCenter.submittingclaiminformations[23]?.value ||
                learningCenter.submittingclaiminformations[24]?.value ||
                learningCenter.submittingclaiminformations[25]?.value
              "
              label="{{ learningCenter.submittingclaiminformations[21].value }}"
              icon="4"
              layout="bullet"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
              >
                <app-learning-center-section
                  *ngIf="
                    this.claimConfigService.getFeatureAccess(
                      'UploadDocuments'
                    ) &&
                    !isMgr &&
                    isShown
                  "
                  class="tw-basis-1/4 app-color-teal"
                  layout="icon-small"
                  icon="upload_file"
                >
                  <a
                    tabindex="0"
                    label="Documents"
                    class="go-to-documents-upload"
                    >Upload Documents</a
                  >
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[23]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="mail"
                >
                  Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[23]?.value
                    "
                  ></span>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[24]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="fax"
                >
                  Fax:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[24]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[25]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="laptop_mac"
                >
                  E-Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[25]?.value
                    "
                  ></span>
                </app-learning-center-section>
              </div>
            </app-learning-center-section>
          </div>
        </ng-template>
        <!--submittingclaiminformations ends-->
        <!--whatsnexts starts-->
        <h2
          class="app-lc-header-2"
          role="heading"
          id="lc-next"
          *ngIf="isWhatsNext"
        >
          What's Next
        </h2>
        <div *ngIf="isMgr; then mgrNext; else nonMgrNext"></div>
        <ng-template #mgrNext>
          <div class="app-lc-text">
            <div
              class="right-wrap app-pl2 tw-hidden md:tw-block"
              *ngIf="learningCenter.whatsnexts[0]?.value"
            >
              <img
                src="./assets/images/img_LC_WhatsNext_computer.webp"
                width="141"
                height="100"
                alt=""
                role="img"
              />
            </div>
            <p [innerHTML]="learningCenter.whatsnexts[0]?.value"></p>
            <p
              *ngIf="
                isShown ||
                learningCenter.whatsnexts[8]?.value ||
                learningCenter.whatsnexts[5]?.value
              "
              [innerHTML]="learningCenter.whatsnexts[1]?.value"
            >
              >
            </p>
            <div>
              <app-learning-center-section
                *ngIf="isShown && learningCenter.whatsnexts[3]?.value"
                label="{{ learningCenter.whatsnexts[2]?.value }}"
                icon="laptop_mac"
                layout="icon"
              >
                <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
              </app-learning-center-section>
            </div>
            <app-learning-center-section
              layout="icon"
              icon="chat"
              *ngIf="learningCenter.whatsnexts[5]?.value"
              label="{{ learningCenter.whatsnexts[4].value }}"
            >
              <p [innerHTML]="learningCenter.whatsnexts[5].value"></p>
            </app-learning-center-section>
            <div>
              <app-learning-center-section
                *ngIf="learningCenter.whatsnexts[8]?.value"
                label="{{ learningCenter.whatsnexts[6]?.value }}"
                icon="contact_phone"
                layout="icon"
              >
                <p>
                  {{
                    learningCenter.whatsnexts[7]?.value +
                      ' ' +
                      learningCenter.whatsnexts[8]?.value +
                      ' ' +
                      learningCenter.whatsnexts[9]?.value
                  }}
                </p>
              </app-learning-center-section>
            </div>
          </div>
        </ng-template>
        <ng-template #nonMgrNext>
          <div class="app-lc-text">
            <div
              class="right-wrap app-pl2 tw-hidden md:tw-block"
              *ngIf="learningCenter.whatsnexts[0]?.value"
            >
              <img
                src="./assets/images/img_LC_WhatsNext_computer.webp"
                width="141"
                height="100"
                alt=""
                role="img"
              />
            </div>
            <p [innerHTML]="learningCenter.whatsnexts[0]?.value"></p>
            <p
              *ngIf="
                (isShown &&
                  (learningCenter.whatsnexts[3]?.value ||
                    learningCenter.whatsnexts[8]?.value)) ||
                (this.claimConfigService.getConfigFeatureAccess(
                  'notificationpreferences'
                ) &&
                  learningCenter.whatsnexts[5]?.value)
              "
              [innerHTML]="learningCenter.whatsnexts[1]?.value"
            >
              >
            </p>
            <div>
              <app-learning-center-section
                *ngIf="isShown && learningCenter.whatsnexts[3]?.value"
                label="{{ learningCenter.whatsnexts[2]?.value }}"
                icon="laptop_mac"
                layout="icon"
              >
                <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
              </app-learning-center-section>
            </div>
            <app-learning-center-section
              layout="icon"
              icon="chat"
              label="{{ learningCenter.whatsnexts[4].value }}"
              *ngIf="
                this.claimConfigService.getConfigFeatureAccess(
                  'notificationpreferences'
                ) && learningCenter.whatsnexts[5]?.value
              "
            >
              <p [innerHTML]="learningCenter.whatsnexts[5].value"></p>
            </app-learning-center-section>
            <div>
              <app-learning-center-section
                *ngIf="learningCenter.whatsnexts[8]?.value"
                label="{{ learningCenter.whatsnexts[6]?.value }}"
                icon="contact_phone"
                layout="icon"
              >
                <p>
                  {{
                    learningCenter.whatsnexts[7]?.value +
                      ' ' +
                      learningCenter.whatsnexts[8]?.value +
                      ' ' +
                      learningCenter.whatsnexts[9]?.value
                  }}
                </p>
              </app-learning-center-section>
            </div>
          </div>
        </ng-template>
        <!--whatsnexts ends-->
        <!--afterDecide starts-->
        <h2
          class="app-lc-header-2"
          id="lc-after"
          role="heading"
          *ngIf="isAfterDecision"
        >
          After Your Decision is Made
        </h2>
        <div *ngIf="isMgr; then mgrDecision; else nonMgrDecision"></div>
        <ng-template #mgrDecision>
          <div class="app-lc-text">
            <div class="right-wrap app-pl2 tw-hidden md:tw-block">
              <img
                src="./assets/images/img_LC_MedTreatment_desktop.webp"
                width="176"
                height="200"
                alt=""
                role="img"
              />
            </div>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[0].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[1].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[2].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[3].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[4].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[5].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[6].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[7].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[8].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[9].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[10].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[11].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[12].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[13].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[14].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[15].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[16].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[17].value"></span>
            </p>
          </div>
        </ng-template>
        <ng-template #nonMgrDecision>
          <div class="app-lc-text">
            <div class="right-wrap app-pl2 tw-hidden md:tw-block">
              <img
                src="./assets/images/img_LC_MedTreatment_desktop.webp"
                width="176"
                height="200"
                alt=""
                role="img"
              />
            </div>
            <p>
              <b
                [innerHTML]="learningCenter.afterDecide[0].value"
                *ngIf="learningCenter.afterDecide[1].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[1].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[2].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[3].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[4].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[5].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[6].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[7].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[8].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[9].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[10].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[11].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[12].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[13].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[14].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[15].value"></span>
            </p>
            <p>
              <b [innerHTML]="learningCenter.afterDecide[16].value"></b><br />
              <span [innerHTML]="learningCenter.afterDecide[17].value"></span>
            </p>
          </div>
        </ng-template>
        <!--afterDecide end-->
        <!--returntoworks starts-->
        <h2
          class="app-lc-header-2"
          role="heading"
          id="lc-rtw"
          *ngIf="isPlanningReturn"
        >
          Planning your Return
        </h2>
        <div *ngIf="isMgr; then mgrReturn; else nonMgrReturn"></div>
        <ng-template #mgrReturn>
          <div class="app-lc-text tw-flex" *ngIf="isPlanningReturn">
            <div class="tw-grow">
              <div class="left-wrap app-pr2 tw-hidden md:tw-block">
                <img
                  src="./assets/images/img_LC_RTW_desktop.webp"
                  width="270"
                  height="200"
                  alt=""
                  role="img"
                />
              </div>
              <p [innerHTML]="learningCenter.returntoworks[1].value"></p>
              <p>
                <b [innerHTML]="learningCenter.returntoworks[2].value"></b>
              </p>
              <p [innerHTML]="learningCenter.returntoworks[3].value"></p>
            </div>
          </div>
          <app-learning-center-section
            *ngIf="isPlanningReturn"
            layout="icon"
            icon="work"
            label="{{ learningCenter.returntoworks[4].value }}"
          >
            {{ learningCenter.returntoworks[5].value }}
          </app-learning-center-section>
        </ng-template>
        <ng-template #nonMgrReturn>
          <div class="app-lc-text tw-flex" *ngIf="isPlanningReturn">
            <div class="tw-grow">
              <div class="left-wrap app-pr2 tw-hidden md:tw-block">
                <img
                  src="./assets/images/img_LC_RTW_desktop.webp"
                  width="270"
                  height="200"
                  alt=""
                  role="img"
                />
              </div>
              <p [innerHTML]="learningCenter.returntoworks[1].value"></p>
              <p>
                <b [innerHTML]="learningCenter.returntoworks[2].value"></b>
              </p>
              <p [innerHTML]="learningCenter.returntoworks[3].value"></p>
            </div>
          </div>
          <app-learning-center-section
            *ngIf="isPlanningReturn"
            layout="icon"
            icon="work"
            label="{{ learningCenter.returntoworks[4].value }}"
          >
            {{ learningCenter.returntoworks[5].value }}
          </app-learning-center-section>
        </ng-template>
        <!--returntoworks end-->
        <!-- TODO -->

        <h2 *ngIf="!isShown" id="lc-how" class="app-lc-header-2" role="heading">
          How Do All of These Claims Work?
        </h2>
        <div *ngIf="!isShown" class="text">
          Wondering what types of claims apply to what situations? Take a look
          at the table below for a quick overview.
        </div>

        <ng-container *ngIf="!isShown">
          <table class="tw-w-full" aria-label="Claims Information">
            <thead>
              <tr>
                <th style="width:20%" colspan="1" scope="col"></th>
                <th style="width:20%" class="table-header" scope="col">
                  Short Term Disability
                </th>
                <th style="width:20%" class="table-header" scope="col">
                  FLMA/State Leave
                </th>
                <th style="width:20%" class="table-header" scope="col">
                  Workers' Compensation
                </th>
                <th style="width:20%" class="table-header" scope="col">ADA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="table-header-left" scope="row">
                  Unable to work due to an injury that occured outside of work
                </th>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured outside of work checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured outside of work checked"
                    tabindex="0"
                    scope="row"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th class="table-header-left" scope="row">
                  Unable to work due to an injury that occured at work
                </th>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured at work checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured at work checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
              </tr>
              <tr>
                <th class="table-header-left" scope="row">
                  Unable to work 2 days every month due to my own chronic health
                  condition
                </th>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work 2 days every month due to my own chronic health
            condition checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th scope="row" class="table-header-left">
                  Unable to work for 3 weeks to care for an ill family member
                </th>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work for 3 weeks to care for an ill family member checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row" class="table-header-left">
                  Unable to be totally successful at work due to limited
                  functionality
                </th>
                <td></td>
                <td></td>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to be totally successful at work due to limited functionality checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
      <!-- RHS MENU -->
      <div
        class="tw-hidden lg:tw-block tw-flex-none tw-max-w-[220px] app-lc-menu"
      >
        <app-sticky-menu>
          <div class="app-pt2">
            <div class="app-lc-sticky-link"><b>Disability</b></div>
            <div class="app-lc-sticky-link" *ngIf="isIntroduction">
              <a (click)="scrollToElement('lc-intro', false, 64)"
                >Introduction</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isWhatisDS">
              <a (click)="scrollToElement('lc-what', false, 64)"
                >What is a Short Term Disability Claim</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isBenefitsCalc">
              <a (click)="scrollToElement('lc-ben', false, 64)"
                >Benefits Calculations</a
              >
            </div>
            <div class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-sub', false, 64)"
                >Submitting Claim Information</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isWhatsNext">
              <a (click)="scrollToElement('lc-next', false, 64)">What's Next</a>
            </div>
            <div class="app-lc-sticky-link" *ngIf="isAfterDecision">
              <a (click)="scrollToElement('lc-after', false, 64)"
                >After Your Decision is Made</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isPlanningReturn">
              <a (click)="scrollToElement('lc-rtw', false, 64)"
                >Planning your Return</a
              >
            </div>
            <div *ngIf="!isShown" class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-how', false, 64)"
                >How Do All of These Claims Work</a
              >
            </div>
          </div>
        </app-sticky-menu>
      </div>
    </div>
  `,
  styles: [
    `
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-header-2 {
        color: var(--color-teal);
        font-size: 28px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .table-container {
      }
      .table-container > div {
        border-color: silver;
        border-style: solid;
        border-width: 0 1px 1px 0;
      }
      .check-container {
        text-align: center;
        vertical-align: middle;
        line-height: 48px;
      }
      .check {
        font-size: 48px;
        width: 48px;
        height: 48px;
        color: var(--color-teal);
      }
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }

      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .left-wrap {
        float: left;
      }
      .right-wrap {
        float: right;
      }
      .center {
        text-align: center;
      }
      .mat-icon {
        margin: auto;
      }
      p + p {
        margin-top: 10px;
      }
      .app-p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      table td {
        border-color: silver;
        border-style: solid;
        border-width: 0 1px 1px 0;
      }
      .tblcolumn {
        width: 20%;
      }
    `,
  ],
})
export class LearningCenterDsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  isMgr: boolean;

  @Input()
  learningCenter: any;

  @Input()
  loading: boolean;
  isShown: boolean = false;
  scrollToElement = scrollToElement;
  @Input()
  lcType: string;
  isReportClaimLinkBound: boolean = false;
  isOpenfile: boolean = false;
  documentUrl: string;
  isLearnMoreLinkBound: boolean = false;
  isLearnMore: boolean = false;
  mappings: any;
  isIntroduction: boolean = false;
  isWhatisDS: boolean = false;
  isBenefitsCalc: boolean = false;
  isSubmittingClaim: boolean = false;
  isWhatsNext: boolean = false;
  isAfterDecision: boolean = false;
  isPlanningReturn: boolean = false;

  @ViewChild('dynamicContentContainer') container: ElementRef;

  private unlistens: (() => void)[] = [];
  constructor(
    private session: Session,
    public learningCenterService: LearningCenterService,
    public dialog: MatDialog,
    public openFileService: OpenFileService,
    public router: Router,
    private bindEvents: DynamicEventBindService,
    public claimConfigService: ConfigurationService
  ) {}
  subs = new SubSink();
  ngAfterViewInit() {
    //angular's innerHTML attribute doesnt bind click events so have to do this
    this.unlistens = this.bindEvents.bindDynamicEvents(
      this.container,
      this.mappings
    );
  }

  async ngOnInit() {
    this.buildDocumentLink();
    this.checkHeaderValues();
    this.mappings = [
      {
        selector: '.report-new-claim',
        action: this.reportClaim.bind(this),
      },
      {
        selector: '.medical-provider',
        action: this.openFileLink.bind(this),
      },
      {
        selector: '.learn-more',
        action: this.openLearnMore.bind(this),
      },
      {
        selector: '.go-to-personal-notif',
        route: '/PreferencesPage',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-comm-center',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'comm-center',
        },
      },
      {
        selector: '.go-to-pharmacy-card',
        route: '/pharmacy-card',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-documents',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'DOCUMENTS',
        },
      },
      {
        selector: '.go-to-documents-upload',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: true,
        },
      },
      {
        selector: '.go-to-documents-show',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: false,
        },
      },
    ];
    this.checkViaClaim();
  }

  buildDocumentLink() {
    if (!this.isMgr)
      this.documentUrl = `/claims?source=${this.learningCenter.claimInfo.source}&claimId=${this.learningCenter.claimInfo.claimId}&focus=DOCUMENTS`;
  }

  openFileLink(event: MouseEvent) {
    event.stopPropagation();
    this.openFileService.openfile();
  }

  reportClaim(event: MouseEvent) {
    event.preventDefault();
    reportNewClaim(this.dialog);
  }

  openLearnMore(event: MouseEvent) {
    event.preventDefault();
    const element = event.target as HTMLElement;
    let url = element.getAttribute('href');
    const dialogRef = this.dialog.open(ExternalLinkDialogComponent);
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(url, '_blank');
      }
    });
  }
  checkViaClaim() {
    if (this.learningCenter.claimInfo.claimId != null) {
      this.isShown = !this.isShown;
    }
  }

  checkHeaderValues() {
    if (this.checkArrayValue(this.learningCenter.introductionResults, 0)) {
      this.isIntroduction = true;
    }
    if (this.checkArrayValue(this.learningCenter.introductionResults, 2)) {
      this.isWhatisDS = true;
    }
    if (this.checkArrayValue(this.learningCenter.benefits, 0)) {
      this.isBenefitsCalc = true;
    }
    if (this.checkArrayValue(this.learningCenter.whatsnexts, 0)) {
      this.isWhatsNext = true;
    }
    if (this.checkArrayValue(this.learningCenter.afterDecide, 0)) {
      this.isAfterDecision = true;
    }
    if (this.checkArrayValue(this.learningCenter.returntoworks, 1)) {
      this.isPlanningReturn = true;
    }
  }
  checkArrayValue(arr: any[], index: number) {
    const arrVal = arr.slice();
    if (index > 0) {
      arrVal.splice(index - 1, 1);
      return arrVal.some((obj) => obj.value !== '');
    } else {
      return arrVal.some((obj) => obj.value !== '');
    }
  }

  ngOnDestroy(): void {
    this.unlistens.forEach((unlisten) => unlisten());
    this.subs.unsubscribe();
  }
}
