import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { scrollToElement } from 'src/app/shared';
import { SubSink } from 'subsink';
import { DynamicEventBindService, Session } from '../..';
import { getUrlParams } from '../../../shared';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-learning-center-liability-base',
  template: `
    <div #dynamicContentContainer class="tw-w-full tw-flex">
      <!-- CONTENT -->
      <div class="tw-grow">
        <h2
          class="app-lc-header-2"
          id="lc-intro"
          *ngIf="isIntroduction"
          role="heading"
        >
          Introduction - {{ lobFullName }}
        </h2>
        <div class="app-lc-text">
          <p>
            <span
              class="tw-text-lg"
              [innerHTML]="learningCenter.introductionResults[1]?.value"
            ></span>
          </p>
          <div *ngIf="isMgr; then mgrIntro; else nonMgrIntro"></div>
          <ng-template #mgrIntro>
            <p>
              {{
                learningCenter.introductionResults[2]?.value +
                  learningCenter.introductionResults[3]?.value
              }}
            </p>
          </ng-template>
          <ng-template #nonMgrIntro>
            <p>
              {{
                learningCenter.introductionResults[2]?.value +
                  learningCenter.introductionResults[3]?.value
              }}
            </p>
          </ng-template>
        </div>

        <h2 class="app-lc-header-2" id="lc-sub" role="heading">
          Submitting Claim Information
        </h2>
        <div>
          <app-learning-center-section
            label="{{ learningCenter.submittingclaiminformations[0]?.value }}"
            icon="1"
            layout="bullet"
          >
            <div *ngIf="isMgr; then mgrSub1; else nonMgrSub1"></div>
            <ng-template #mgrSub1>
              <span
                [innerHTML]="
                  learningCenter.submittingclaiminformations[1]?.value
                "
              ></span
              ><span
                [innerHTML]="
                  learningCenter.submittingclaiminformations[2]?.value
                "
              ></span>
            </ng-template>
            <ng-template #nonMgrSub1>
              <span
                [innerHTML]="
                  learningCenter.submittingclaiminformations[1]?.value
                "
              ></span
              ><span
                [innerHTML]="
                  learningCenter.submittingclaiminformations[2]?.value
                "
              ></span>
              <p
                *ngIf="!claimClosed && isClaimSpecific"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[3]?.value
                "
              ></p>
            </ng-template>
          </app-learning-center-section>

          <div *ngIf="isMgr; then mgrSub2; else nonMgrSub2"></div>
          <ng-template #mgrSub2>
            <div
              *ngIf="isController; then mgrSubLcType; else nonMgrSubLcType"
            ></div>
            <ng-template #mgrSubLcType>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[3]?.value
                }}"
                icon="2"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[4]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[5]?.value
                }}"
                icon="3"
                layout="bullet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
                >
                  <app-learning-center-section
                    *ngIf="showUpload"
                    class="tw-basis-1/4 app-color-teal"
                    layout="icon-small"
                    icon="upload_file"
                  >
                    <a
                      tabindex="0"
                      label="Documents"
                      class="go-to-documents-upload"
                      (click)="redirectToDocUpload()"
                      >Upload Documents</a
                    >
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[6]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="mail"
                  >
                    Mail:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[6]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[7]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="fax"
                  >
                    Fax:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[7]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[8]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="laptop_mac"
                  >
                    Email:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[8]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                </div>
              </app-learning-center-section>
            </ng-template>
            <ng-template #nonMgrSubLcType>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[4]?.value
                }}"
                icon="2"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[5]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[7]?.value
                }}"
                icon="3"
                layout="bullet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
                >
                  <app-learning-center-section
                    *ngIf="showUpload"
                    class="tw-basis-1/4 app-color-teal"
                    layout="icon-small"
                    icon="upload_file"
                  >
                    <a
                      tabindex="0"
                      label="Documents"
                      class="go-to-documents-upload"
                      (click)="redirectToDocUpload()"
                      >Upload Documents</a
                    >
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[8]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="mail"
                  >
                    Mail:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[8]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[9]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="fax"
                  >
                    Fax:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[9]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[10]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="laptop_mac"
                  >
                    Email:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[10]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                </div>
              </app-learning-center-section>
            </ng-template>
          </ng-template>
          <ng-template #nonMgrSub2>
            <div
              *ngIf="isController; then mgrSubLcType; else nonMgrSubLcType"
            ></div>
            <ng-template #mgrSubLcType>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[3]?.value
                }}"
                icon="2"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[4]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[5]?.value
                }}"
                icon="3"
                layout="bullet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
                >
                  <app-learning-center-section
                    *ngIf="showUpload"
                    class="tw-basis-1/4 app-color-teal"
                    layout="icon-small"
                    icon="upload_file"
                  >
                    <a
                      tabindex="0"
                      label="Documents"
                      class="go-to-documents-upload"
                      (click)="redirectToDocUpload()"
                      >Upload Documents</a
                    >
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[6]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="mail"
                  >
                    Mail:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[6]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[7]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="fax"
                  >
                    Fax:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[7]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[8]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="laptop_mac"
                  >
                    Email:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[8]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                </div>
              </app-learning-center-section>
            </ng-template>
            <ng-template #nonMgrSubLcType>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[4]?.value
                }}"
                icon="2"
                layout="bullet"
              >
                <p
                  [innerHTML]="
                    learningCenter.submittingclaiminformations[5]?.value
                  "
                ></p>
              </app-learning-center-section>
              <app-learning-center-section
                label="{{
                  learningCenter.submittingclaiminformations[7]?.value
                }}"
                icon="3"
                layout="bullet"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
                >
                  <app-learning-center-section
                    *ngIf="showUpload"
                    class="tw-basis-1/4 app-color-teal"
                    layout="icon-small"
                    icon="upload_file"
                  >
                    <a
                      tabindex="0"
                      label="Documents"
                      class="go-to-documents-upload"
                      (click)="redirectToDocUpload()"
                      >Upload Documents</a
                    >
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[8]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="mail"
                  >
                    Mail:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[8]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="learningCenter.submittingclaiminformations[9]?.value"
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="fax"
                  >
                    Fax:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[9]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                  <app-learning-center-section
                    *ngIf="
                      learningCenter.submittingclaiminformations[10]?.value
                    "
                    class="tw-basis-1/4"
                    layout="icon-small"
                    icon="laptop_mac"
                  >
                    Email:<br />
                    <p
                      [innerHTML]="
                        learningCenter.submittingclaiminformations[10]?.value
                      "
                    ></p>
                  </app-learning-center-section>
                </div>
              </app-learning-center-section>
            </ng-template>
          </ng-template>
        </div>

        <h2
          class="app-lc-header-2"
          id="lc-next"
          *ngIf="isWhatsnext"
          role="heading"
        >
          {{ learningCenter.whatsnexts[0]?.value }}
        </h2>
        <div class="app-lc-text tw-flex">
          <div class="tw-grow">
            <div *ngIf="isMgr; then mgrNext; else nonMgrNext"></div>
            <ng-template #mgrNext>
              <div class="right-wrap app-pl2 tw-hidden md:tw-block">
                <img
                  src="./assets/images/img_LC_WhatsNext_computer.webp"
                  width="141"
                  height="100"
                  alt=""
                />
              </div>
              <p [innerHTML]="learningCenter.whatsnexts[1]?.value"></p>
              <p [innerHTML]="learningCenter.whatsnexts[2]?.value"></p>
              <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
              <p
                *ngIf="learningCenter.whatsnexts[7]?.value"
                [innerHTML]="learningCenter.whatsnexts[4]?.value"
              ></p>
              <div>
                <app-learning-center-section
                  *ngIf="learningCenter.whatsnexts[7]?.value"
                  label="{{ learningCenter.whatsnexts[5]?.value }}"
                  icon="contact_phone"
                  layout="icon"
                >
                  <span
                    [innerHTML]="learningCenter.whatsnexts[6]?.value + ' '"
                  ></span>
                  <span
                    [innerHTML]="
                      (learningCenter.whatsnexts[7]?.value | phone) + ' '
                    "
                  >
                  </span>
                  <span [innerHTML]="learningCenter.whatsnexts[8]?.value">
                  </span>
                </app-learning-center-section>
              </div>
            </ng-template>
            <ng-template #nonMgrNext>
              <div class="right-wrap app-pl2 tw-hidden md:tw-block">
                <img
                  src="./assets/images/img_LC_WhatsNext_computer.webp"
                  width="141"
                  height="100"
                  alt=""
                />
              </div>
              <p [innerHTML]="learningCenter.whatsnexts[1]?.value"></p>
              <p [innerHTML]="learningCenter.whatsnexts[2]?.value"></p>
              <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>

              <p
                *ngIf="
                  (isClaimSpecific &&
                    (learningCenter.whatsnexts[6]?.value ||
                      learningCenter.whatsnexts[11]?.value)) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[8]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[4]?.value"
              ></p>
              <div>
                <app-learning-center-section
                  *ngIf="
                    !claimClosed &&
                    isClaimSpecific &&
                    learningCenter.whatsnexts[6]?.value
                  "
                  label="{{ learningCenter.whatsnexts[5]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[6]?.value"></p>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="
                    this.claimConfigService.getConfigFeatureAccess(
                      'notificationpreferences'
                    ) && learningCenter.whatsnexts[8]?.value
                  "
                  label="{{ learningCenter.whatsnexts[7]?.value }}"
                  icon="chat"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[8]?.value"></p>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.whatsnexts[11]?.value"
                  label="{{ learningCenter.whatsnexts[9]?.value }}"
                  icon="contact_phone"
                  layout="icon"
                >
                  <span>{{ learningCenter.whatsnexts[10]?.value }}</span>
                  <span> {{ learningCenter.whatsnexts[11]?.value }}</span>
                  <span> {{ learningCenter.whatsnexts[12]?.value }}</span>
                </app-learning-center-section>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- RHS MENU -->
      <div
        class="tw-hidden lg:tw-block tw-flex-none tw-max-w-[220px] app-lc-menu"
      >
        <app-sticky-menu>
          <div class="app-pt2">
            <div class="app-lc-sticky-link">
              <b>{{ lobFullName }}</b>
            </div>
            <div class="app-lc-sticky-link" *ngIf="isIntroduction">
              <a (click)="scrollToElement('lc-intro', false, 65)"
                >Introduction</a
              >
            </div>

            <div class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-sub', false, 65)"
                >Submitting Claim Information</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isWhatsnext">
              <a (click)="scrollToElement('lc-next')">What's Next</a>
            </div>
          </div>
        </app-sticky-menu>
      </div>
    </div>
  `,
  styles: [
    `
      .app-lc-header-2 {
        color: var(--color-teal);
        font-size: 28px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .left-wrap {
        float: left;
      }
      .right-wrap {
        float: right;
      }
      .center {
        text-align: center;
      }
      .mat-icon {
        margin: auto;
      }
      p + p {
        margin-top: 10px;
      }
    `,
  ],
})
export class LearningCenterLiabilityBaseComponent
  implements OnInit, AfterViewInit
{
  @Input()
  isMgr: boolean;

  @Input()
  learningCenter: any;

  @Input()
  loading: boolean;

  scrollToElement = scrollToElement;
  mappings: any;

  @Input()
  lobFullName: string = 'Liability';

  @Input()
  claimClosed: boolean;
  @Input()
  lcType: string;

  isClaimSpecific: boolean = false;
  showUpload: boolean = false;
  isIntroduction: boolean = false;
  isWhatsnext: boolean = false;
  isController: boolean = false;
  @ViewChild('dynamicContentContainer') container: ElementRef;
  private unlistens: (() => void)[] = [];
  constructor(
    private session: Session,
    private router: Router,
    private bindEvents: DynamicEventBindService,
    public claimConfigService: ConfigurationService
  ) {}

  subs = new SubSink();

  async ngOnInit() {
    this.checkHeaderValues();
    // console.log(this.learningCenter);
    this.mappings = [
      {
        selector: '.go-to-personal-notif',
        route: '/PreferencesPage',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-comm-center',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'comm-center',
        },
      },
      {
        selector: '.go-to-pharmacy-card',
        route: '/pharmacy-card',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-documents',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'DOCUMENTS',
        },
      },
      {
        selector: '.go-to-documents-upload',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: true,
        },
      },
      {
        selector: '.go-to-documents-show',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: false,
        },
      },
    ];
    this.checkViaClaim();
  }

  async checkViaClaim() {
    const params = getUrlParams();
    if (params.claimId != null) {
      this.isClaimSpecific = true;
    }
    const hasLC = await this.claimConfigService.getFeatureAccess(
      'uploaddocuments'
    );
    if (this.isClaimSpecific && hasLC) {
      this.showUpload = true;
    }
    if (this.lcType == 'CONTROLLER') {
      this.isController = true;
    }
  }

  ngAfterViewInit(): void {
    //angular's innerHTML attribute doesnt bind click events so have to do this
    this.unlistens = this.bindEvents.bindDynamicEvents(
      this.container,
      this.mappings
    );
  }

  ngOnDestroy(): void {
    this.unlistens.forEach((unlisten) => unlisten());
  }

  checkHeaderValues() {
    if (this.checkArrayValue(this.learningCenter.introductionResults, 0)) {
      this.isIntroduction = true;
    }

    if (this.checkArrayValue(this.learningCenter.whatsnexts, 0)) {
      this.isWhatsnext = true;
    }
  }
  checkArrayValue(arr: any[], index: number) {
    const arrVal = arr.slice();
    if (index > 0) {
      arrVal.splice(index - 1, 1);
      return arrVal.some((obj) => obj.value !== '');
    } else {
      return arrVal.some((obj) => obj.value !== '');
    }
  }

  redirectToDocUpload() {
    this.router.navigate(['../upload-documents-in-documentstab'], {
      queryParams: {
        source: this.learningCenter.claimInfo.source,
        claimId: this.learningCenter.claimInfo.claimId,
        focus: 'documents',
        showDialog: true,
      },
    });
  }
}
