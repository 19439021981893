import { Component, Input, OnInit } from '@angular/core';
import { RouterService, SearchResult } from 'src/app/shared';
import { Policies } from 'src/app/shared/models/Policies';
import { ClaimService } from '../../services/claim.service';
@Component({
  selector: 'app-leave',
  template: `
    <app-loading-panel [state]="isLoaded ? 'Loaded' : 'Loading'">
      <ng-template #content>
        <app-list
          #table
          [dataSource]="policiesInfo"
          [template]="row"
          class="app-pt2"
          [filterEnabled]="false"
          [paginatorEnabled]="false"
          loadingMessage=""
          noDataIcon="list_alt"
          noDataMessage="<br><strong>You do not have any leave balance information.</strong>"
          [tableAriaLabel]="'Leaves'"
        >
          <ng-container header>
            <tr class="tw-flex tw-flex-wrap tw-w-full">
              <app-list-header
                name="policyName"
                label="Policy"
                class="tw-w-full md:tw-w-[40%]"
                [names]="['policyName']"
              ></app-list-header>
              <app-list-header
                name="timeUsed"
                label="Time Used"
                class="tw-w-full md:tw-w-[30%]"
                [names]="['timeUsed']"
                [labelTemplate]="labelTemplate"
              >
                <ng-template #labelTemplate>
                  <app-help-button
                    alt="Column information"
                    (click)="$event.stopPropagation()"
                    [size]="20"
                    message="Time Used is based on how much time has been used for this claim only, as of today."
                    [id]="'leaveToolTip'"
                  >
                  </app-help-button>
                </ng-template>
              </app-list-header>

              <app-list-header
                name="exhaustionDate"
                label="Policy Exhausts"
                class="tw-w-full md:tw-w-[30%]"
                [names]="['exhaustionDate']"
              ></app-list-header>
            </tr>
          </ng-container>
        </app-list>

        <ng-template #row let-element>
          <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <td class="tw-w-full md:tw-w-[40%]">
              <app-value
                label="Policy"
                [value]="element.policyName"
                [tabIndex]="0"
                layout="row-xs"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[30%]">
              <app-value
                label="Time Used"
                [tabIndex]="0"
                [value]="element.timeUsed"
                layout="row-xs"
              >
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[30%]">
              <app-value
                label="Policy Exhausts"
                [tabIndex]="0"
                [value]="element.exhaustionDate | format : 'date'"
                layout="row-xs"
              >
              </app-value>
            </td>
          </tr>
        </ng-template>
        <div class="summary" *ngIf="showLabels">
          <img
            [src]="'/assets/images/welcome.png'"
            alt=""
            height="100"
            width="100"
          />
          <ul>
            <li>
              You can also
              <a
                href="http://view.ceros.com/sedgwick/leave-law-map/p/1"
                target="_blank"
              >
                learn more
              </a>
              about leave rights in your state
            </li>
            <li>
              View your
              <a
                href="javascript:void(0)"
                [queryParams]="{
                  fromClaim: 'yes',
                  claimId: this.claim.claimId,
                  source: this.claim.source
                }"
                routerLink="/leaveBalanceSummary"
              >
                leave balance summary
              </a>
              for all of your related claims
            </li>
          </ul>
        </div>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .summary {
        display: flex;
        gap: 40px;
        align-items: center;
        padding: 7px;
      }
      li {
        list-style-type: disc;
      }
    `,
  ],
})
export class LeaveComponent implements OnInit {
  @Input()
  claim: SearchResult;
  source: any;
  isLoaded: boolean = false;
  policiesInfo: Policies[];
  showLabels: boolean = false;
  constructor(
    public routerService: RouterService,
    public claimService: ClaimService
  ) {}
  async ngOnInit() {
    if (this?.claim?.claimNumber) {
      const result = await this.claimService.getPolicies(
        this.claim.claimNumber
      );
      if (result) {
        this.policiesInfo = result;
        this.showLabels = true;
      }
      this.isLoaded = true;
    }
  }
}
