import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-date-control',
  template: `
    <div class="app-pr1 app-pb1 datepick">
      <mat-form-field [hintLabel]="hintLabel" [formGroup]="formGroup">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          #input
          [placeholder]="placeHolder"
          [formControlName]="formControlName"
          [matDatepicker]="picker"
          autocomplete="off"
          [min]="minValue"
          [max]="maxValue"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startAt"></mat-datepicker>

        <mat-error
          *ngIf="!formControl.valid"
          class="errormsg"
          [ngClass]="errormsgClass"
          [attr.aria-label]="label + ' is ' + errorMessage"
          >{{ errorMessage }}</mat-error
        >
        <mat-error *ngIf="formControl.hasError('matDatepickerMin')"
          >Date should be inferior</mat-error
        >
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .datepick mat-label {
        color: #67707a;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep .mat-form-field-outline {
        color: #67707a;
      }

      .datepick:focus mat-label {
        color: #171c20;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep .mat-form-field-outline-thick .mat-focused {
        color: #171c20 !important ;
        border-color: #007dbc !important ;
      }
      .errormsg {
        color: #c24533;
      }
      .errormsgabsence {
        color: var(--mdc-theme-error, #f44336) !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep
        .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
        .mat-form-field-outline-thick {
        color: #c24533 !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .datepick::ng-deep
        .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
        .mat-form-field-outline-thick:hover {
        color: #3f464d !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-focused::ng-deep .mat-form-field-outline {
        color: #007dbc !important;
      }
    `,
  ],
})
export class DateControlComponent
  extends BaseControlComponent
  implements OnInit
{
  @HostBinding('attr.ngDefaultControl')
  ngDefaultControl = '';

  @Input()
  errormsgClass: string = '';

  @Input()
  minValue: Date;

  @Input()
  maxValue: Date;

  @Input()
  startAt: Date;

  ngOnInit(): void {}
}
