import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';
import { Session } from './my-sedgwick';
import { TimeoutDialogComponent } from './my-sedgwick/components/common/timeout-dialog.component';

declare const webchat: any;

@Component({
  selector: 'app-root',
  template: ` <div *ngIf="!loaded" class="app-startup-container">
      <div class="app-startup-dot-loader"></div>
    </div>
    <router-outlet *ngIf="loaded"></router-outlet>`,
  styles: [``],
})
export class AppComponent implements OnInit {
  title = 'my-sedgwick-5';
  warnThreshold: number;
  logoutThreshold: number;
  abandonThreshold: number;
  alertTime: number;
  lastActive = new Date();
  firstActive = new Date();
  alertMessage: string =
    'For your safety and protection your mySedgwick session will end in TIMER due to inactivity';
  continueMessage: string =
    'If you are still working in your mySedgwick session, simply click OK to continue.';
  showPopup: boolean = false;
  timeoutTitle: string = 'Alert';
  confirmLabel: string = 'OK';
  loaded = false;
  @ViewChild('timeoutTemplate') timeoutTemplate: TimeoutDialogComponent;

  constructor(
    public session: Session,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    await this.session.loadUser();
    this.loaded = true;
    this.setThresholds(this.session);

    this.session.updateActivity.subscribe((result: boolean) => {
      if (result == true) {
        this.doUpdateActivity(true);
      }
    });

    //Check thresholds every minute
    this.bnIdle.startWatching(60).subscribe((isCheckTimeout: boolean) => {
      //This is just the base line for this code, the logic is similar to what is done in old code.
      // Reference bouncer.js and the timeout component in our old code for the rest of the logic.
      if (isCheckTimeout && !webchat.isConversationOn()) {
        const dt = new Date();
        const lastDt = this.lastActive;
        this.alertTime = this.logoutThreshold - this.warnThreshold;
        const ms = dt.getTime() - lastDt.getTime();
        const d = ms / 1000;

        if (
          this.warnThreshold > 0 &&
          d >= this.warnThreshold &&
          d <= this.logoutThreshold
        ) {
          if (!this.showPopup) {
            this.dialog.open(
              TimeoutDialogComponent,
              this.buildMatDialog(this.alertTime)
            );
          }
          this.showPopup = true;
        }

        if (d > this.logoutThreshold) {
          this.doUpdateActivity(false);
          this.doLogout();
        }

        const msx = dt.getTime() - this.firstActive.getTime();
        const dx = msx / 1000;

        if (dx > this.abandonThreshold) {
          this.doLogout();
        }
      } else {
        this.doUpdateActivity(true);
      }
    });
  }

  private setThresholds(session: Session) {
    const user = session.user;
    this.warnThreshold = parseInt(user.timeoutThresholds.warnThreshold);
    this.logoutThreshold = parseInt(user.timeoutThresholds.logoutThreshold);
    this.abandonThreshold = parseInt(user.timeoutThresholds.abandonThreshold);
  }

  public doUpdateActivity(hidePopup: boolean) {
    this.showPopup = !hidePopup;
    this.lastActive = new Date();
  }

  private async doLogout() {
    await this.session.logoff();
  }

  private buildMatDialog(alertTime: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      timer: alertTime,
    };
    dialogConfig.ariaLabelledBy = 'timeoutWarningDialog';

    return dialogConfig;
  }
}
