import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { CommCenterService } from '../..';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-emulate-user-header',
  template: `
    <div>
      <mat-toolbar
        color="primary"
        class="header fixtoolbar last-head-bar"
        [class.mat-elevation-z4]="scrolled"
        style="z-index:999"
      >
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          *ngIf="(isSmall$ | async) && !this.isAuditor"
          (click)="toggleMenu()"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <img
          *ngIf="logoImage"
          src="data:image/JPEG;base64,{{ logoImage }}"
          alt="mySedgwick Logo"
          class="tw-hidden md:tw-block"
        />
        <div class="" *ngIf="isAuditor">Client : {{ clientHeader }}</div>
        <span class="example-spacer"></span>

        <mat-icon color="primary" class="persondisplay">person</mat-icon>
        <div class="app-pr3 app-pl1 welcome">
          {{ session.user.firstName }}
        </div>

        <button
          mat-icon-button
          style="color: var(--color-input-grey)"
          alt="New Messages"
          class="nopadding"
          routerLink="/communication-center"
          role="button"
          aria-label="View new Message/s"
          tabindex="0"
        >
          <mat-icon
            [matBadge]="commCenterService.unreadMessageCount()"
            matBadgeColor="accent"
            >mail_outline</mat-icon
          >
        </button>
        <div *ngIf="showHeaderIcons" class="md:tw-block">
          <ng-container *ngIf="showSearch">
            <app-overlay
              [origin]="searchOverlayOrigin"
              #searchOverlay
              [overlayClassName]=""
            >
              <app-quick-search
                *ngIf="searchOverlay.opened"
                [showTitle]="false"
                class="overlay"
              ></app-quick-search>
            </app-overlay>
            <button
              aria-label="Search Popup"
              mat-icon-button
              cdkOverlayOrigin
              #searchOverlayOrigin="cdkOverlayOrigin"
              (click)="searchOverlay.open()"
            >
              <mat-icon>search</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="showWatchlist">
            <app-overlay [origin]="watchListOverlayOrigin" #watchListOverlay>
              <app-watch-list
                *ngIf="watchListOverlay.opened"
                [overlay]="true"
                class="overlay"
              ></app-watch-list>
            </app-overlay>
            <button
              mat-icon-button
              aria-label="Watch List Popup"
              cdkOverlayOrigin
              #watchListOverlayOrigin="cdkOverlayOrigin"
              (click)="watchListOverlay.open()"
            >
              <mat-icon>star</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="showHistory">
          <app-overlay
          [overlayClassName]="'history-overlay'"
          [origin]="historyOverlayOrigin"
          #historyOverlay
        >
          <app-claim-history
            *ngIf="historyOverlay.opened"
            [overlay]="true"
          ></app-claim-history>
        </app-overlay>
        <button
          aria-label="History Popup"
          mat-icon-button
          cdkOverlayOrigin
          #historyOverlayOrigin="cdkOverlayOrigin"
          (click)="historyOverlay.open()"
        >
          <mat-icon class="toolbar-icon-slate">history</mat-icon>
        </button>
          </ng-container>
        </div>
        <div
          *ngIf="hasEulaAgreement === false && showAccountSetting"
          class="md:tw-block"
        >
          <button
            *ngIf="!isPreference && showAccountSetting"
            aria-label="Preference page"
            mat-icon-button
            role="link"
            routerLink="PreferencesPage"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <div class="md:tw-block" *ngIf="!hasEulaAgreement">
          <button
            mat-button
            class="logout"
            aria-label="Logout and open the login page"
            (click)="logOutEmulatedUser()"
          >
            <b>Log out</b>
          </button>
        </div>
      </mat-toolbar>
    </div>
  `,
  styles: [
    `
      .overlay h2 {
        padding-top: 20px;
      }

      .outlet {
        --max-width: 1280px;
      }
      .mat-mdc-toolbar.mat-mdc-primary {
        position: sticky;
        top: 0;
        z-index: 1;
        min-height: 64px;
      }

      .logo-image {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 20px;
      }

      @media (max-width: 760px) {
      }
      .example-spacer {
        flex: 1 1 auto;
      }

      .mat-mdc-toolbar {
        position: relative;
      }
      .overlay {
        width: 600px;
      }
      .header {
        background-color: #ffffff;
        color: #67707a;
        border-bottom: 1px solid #e0e0e0;
      }
      .header-xs {
        background-color: #ffffff;
      }

      .last-head-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .mat-toolbar-row,
      .mat-toolbar-single-row {
        min-height: 64px;
        height: auto !important;
      }

      @media only screen and (max-device-width: 480px) {
        .fixtoolbar {
          position: sticky;
          top: 0;
          z-index: 999999999;
          min-height: 64px;
        }
      }
      .preview-mode {
        z-index: 9999;
      }
    `,
  ],
})
export class EmulateUserHeaderComponent implements OnInit {
  @Input() logoImage: string;
  @Input() isSmall$: Observable<boolean>;
  @Input() isAuditor: boolean;
  @Input() showHeaderIcons: boolean;
  @Input() showSearch: boolean;
  @Input() showWatchlist: boolean;
  @Input() showHistory: boolean;
  @Input() hasEulaAgreement: any;
  @Input() showAccountSetting: boolean;
  @Input() isPreference: boolean;
  @Input() scrolled: boolean;

  @Output() logOutEmitter = new EventEmitter();
  @Input() drawer: MatDrawer;

  clientHeader: string;

  constructor(
    public session: Session,
    public commCenterService: CommCenterService
  ) {}

  async ngOnInit() {
    if (this.session.user) {
      this.clientHeader =
        this.session.user.clientName + ' (' + this.session.user.contNum + ')';
    }
  }

  toggleMenu() {
    if (this.drawer) {
      this.drawer.toggle();
    }
  }

  logOutEmulatedUser() {
    this.logOutEmitter.emit();
  }
}
