import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

import {
  AbstractControl,
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ControlContainer,
} from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Session } from '../../services/session.service';
import { ToastType, srSpeak } from 'src/app/shared';
import { AccountSettingsService } from '../../services/accountsettings.service';
import { emulator } from '../../services/emulator.service';

@Component({
  selector: 'app-security-questions-page',

  template: `
    <app-section title="Security Questions"></app-section>
    <div id="regSecurityQuesionsFormdiv" id="secQuesDiv">
      <form
        role="none"
        class="tw-flex tw-flex-wrap tw-flex-col"
        class=""
        [formGroup]="regSecurityQuesionsForm"
        autocomplete="off"
      >
        <div class="md:tw-block tw-hidden">
          <div class="flex-container">
            <div class="flex-left-col">
              <strong
                >For security purposes we are asking you to select a series of
                challenge questions.</strong
              ><br aria-hidden="true" />
              <label
                >They will be used to provide you with access if you forget your
                password.</label
              >
            </div>

            <div class="flex-mid-col"></div>
            <div class="flex-right-col" style="text-align: right;">
              <strong>*Required</strong>
            </div>
          </div>
        </div>
        <div class="md:tw-hidden tw-block">
          <div class="tw-w-full">
            <div class="tw-w-full" style="text-align: right;">
              <strong>*Required</strong>
            </div>
            <div class="tw-w-full">
              <strong
                >For security purposes we are asking you to select a series of
                challenge questions.</strong
              ><br aria-hidden="true" />
              <label
                >They will be used to provide you with access if you forget your
                password.</label
              >
            </div>
          </div>
        </div>

        <div class="md:tw-flex tw-w-full pdt20">
          <div class="flex-left">
            <ul class="flex-outer" role="none">
              <li>
                <mat-form-field class="tw-w-full" style="display: none;">
                  <mat-label>Security Question</mat-label>
                  <mat-select
                    class="ddlquestions"
                    tabIndex="0"
                    formControlName="securityQuestion1"
                    id="securityQuestion1"
                    aria-label="Security question 1 of 5"
                    aria-controls="optionsQuestion1"
                    required
                    (selectionChange)="updateAvailableQuestions($event)"
                  >
                  <div id="optionsQuestion1">
                    <mat-option
                      *ngFor="let question of securityQuestions1"
                      [value]="question.Text"
                    >
                      {{ question.Text }}
                    </mat-option>
                  </div>
                  </mat-select>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      showErrorMsg ||
                      (regSecurityQuesionsForm.get('securityQuestion1')
                        .errors &&
                        (regSecurityQuesionsForm.get('securityQuestion1')
                          .dirty ||
                          regSecurityQuesionsForm.get('securityQuestion1')
                            .touched) &&
                        regSecurityQuesionsForm
                          .get('securityQuestion1')
                          .hasError('required'))
                    "
                  >
                    <span class="error-message">
                      Error : Security question 1 of 5 is required.</span
                    >
                  </mat-error>
                </mat-form-field>
              </li>
              <li role="none">
                <mat-form-field class="tw-w-full">
                  <mat-label>Enter your Answer</mat-label>
                  <input
                    matInput
                    formControlName="securityAnswer1"
                    name="securityAnswer1"
                    required
                    id="securityAnswer1"
                    tabindex="0"
                    aria-label="Security answer 1 of 5"
                    autocomplete="new-password"
                    [type]="hide ? 'password' : 'text'"
                  />
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer1')
                        .hasError('required')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer1')
                        .hasError('minlength')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    ></mat-error
                  >
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer1')
                        .hasError('pattern')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter a valid Answer.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer1')
                        .hasError('duplicateAnswer')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter unique answers.</span
                    >
                  </mat-error>
                </mat-form-field>
              </li>
              <mat-divider class="mat-divider"></mat-divider>

              <li>
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Security Question</mat-label>
                  <mat-select
                    tabindex="0"
                    formControlName="securityQuestion2"
                    id="securityQuestion2"
                    aria-label="Security question 2 of 5"
                    aria-controls="optionsQuestion2"
                    required
                    (selectionChange)="updateAvailableQuestions($event)"
                  >
                  <div id="optionsQuestion2">
                    <mat-option
                      *ngFor="let question of securityQuestions2"
                      [value]="question.Text"
                    >
                      {{ question.Text }}
                    </mat-option>
                  </div>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      showErrorMsg ||
                      (regSecurityQuesionsForm.get('securityQuestion2')
                        .errors &&
                        (regSecurityQuesionsForm.get('securityQuestion2')
                          .dirty ||
                          regSecurityQuesionsForm.get('securityQuestion2')
                            .touched) &&
                        regSecurityQuesionsForm
                          .get('securityQuestion2')
                          .hasError('required'))
                    "
                  >
                    <span class="error-message">
                      Error : Security question 2 of 5 is required.</span
                    >
                    <!-- </mat-error>-->
                  </mat-error>
                </mat-form-field>
              </li>

              <li role="none">
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Enter your Answer</mat-label>
                  <input
                    matInput
                    class="input"
                    formControlName="securityAnswer2"
                    name="securityAnswer2"
                    required
                    id="securityAnswer2"
                    aria-label="Security answer 2 of 5"
                    autocomplete="new-password"
                    [type]="hide ? 'password' : 'text'"
                  />
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer2')
                        .hasError('required')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer2')
                        .hasError('minlength')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    ></mat-error
                  >
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer2')
                        .hasError('pattern')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter a valid Answer.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer2')
                        .hasError('duplicateAnswer')
                    "
                  >
                    <span class="error-message">
                      Error :Please enter unique answers.</span
                    >
                  </mat-error>
                  <!-- </mat-error> -->
                </mat-form-field>
              </li>
              <mat-divider class="mat-divider"></mat-divider>
              <li>
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Security Question</mat-label>
                  <mat-select
                    formControlName="securityQuestion3"
                    id="securityQuestion3"
                    tabindex="0"
                    aria-label="Security question 3 of 5"
                    aria-controls="optionsQuiestion3"
                    required
                    (selectionChange)="updateAvailableQuestions($event)"
                  >
                  <div id="optionsQuiestion3">
                    <mat-option
                      *ngFor="let question of securityQuestions3"
                      [value]="question.Text"
                    >
                      {{ question.Text }}
                    </mat-option>
                  </div>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      showErrorMsg ||
                      (regSecurityQuesionsForm.get('securityQuestion3')
                        .errors &&
                        (regSecurityQuesionsForm.get('securityQuestion3')
                          .dirty ||
                          regSecurityQuesionsForm.get('securityQuestion3')
                            .touched) &&
                        regSecurityQuesionsForm
                          .get('securityQuestion3')
                          .hasError('required'))
                    "
                  >
                    <span class="error-message">
                      Error : Security question 3 of 5 is required.</span
                    >
                    <!-- </mat-error>-->
                  </mat-error>
                </mat-form-field>
              </li>
              <li role="none">
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Enter your Answer</mat-label>
                  <input
                    matInput
                    class="input"
                    formControlName="securityAnswer3"
                    name="securityAnswer3"
                    required
                    id="securityAnswer3"
                    autocomplete="new-password"
                    [type]="hide ? 'password' : 'text'"
                    aria-label="Security answer 3 of 5"
                  />
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer3')
                        .hasError('required')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer3')
                        .hasError('minlength')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    ></mat-error
                  >
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer3')
                        .hasError('pattern')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter a valid Answer.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer3')
                        .hasError('duplicateAnswer')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter unique answers.</span
                    >
                  </mat-error>
                </mat-form-field>
              </li>
            </ul>
          </div>
          <div class="flex-mid"></div>
          <div class="flex-right">
            <ul class="flex-outer" role="none">
              <li>
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Security Question</mat-label>
                  <mat-select
                    formControlName="securityQuestion4"
                    id="securityQuestion4"
                    tabindex="0"
                    aria-label="Security question 4 of 5"
                    aria-controls="optionsQuestion4"
                    required
                    (selectionChange)="updateAvailableQuestions($event)"
                  >
                  <div id="optionsQuestion4">
                    <mat-option
                      *ngFor="let question of securityQuestions4"
                      [value]="question.Text"
                    >
                      {{ question.Text }}
                    </mat-option>
                  </div>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      showErrorMsg ||
                      (regSecurityQuesionsForm.get('securityQuestion4')
                        .errors &&
                        (regSecurityQuesionsForm.get('securityQuestion4')
                          .dirty ||
                          regSecurityQuesionsForm.get('securityQuestion4')
                            .touched) &&
                        regSecurityQuesionsForm
                          .get('securityQuestion4')
                          .hasError('required'))
                    "
                  >
                    <span class="error-message">
                      Error : Security question 4 of 5 is required.</span
                    >
                    <!-- </mat-error>-->
                  </mat-error>
                </mat-form-field>
              </li>
              <li role="none">
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Enter your Answer</mat-label>
                  <input
                    matInput
                    class="input"
                    formControlName="securityAnswer4"
                    name="securityAnswer4"
                    required
                    id="securityAnswer4"
                    aria-label="Security answer 4 of 5"
                    autocomplete="new-password"
                    [type]="hide ? 'password' : 'text'"
                  />
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer4')
                        .hasError('required')
                    "
                    ><span class="error-message">
                      Error : Four or more characters are required.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer4')
                        .hasError('minlength')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    ></mat-error
                  >
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer4')
                        .hasError('pattern')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter a valid Answer.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer4')
                        .hasError('duplicateAnswer')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter unique answers.</span
                    >
                  </mat-error>
                </mat-form-field>
              </li>

              <mat-divider class="mat-divider"></mat-divider>
              <li>
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Security Question</mat-label>
                  <mat-select
                    formControlName="securityQuestion5"
                    id="securityQuestion5"
                    tabindex="0"
                    aria-label="Security question 5 of 5"
                    aria-controls="optionsQuestion5"
                    required
                    (selectionChange)="updateAvailableQuestions($event)"
                  >
                  <div id="optionsQuestion5">
                    <mat-option
                      *ngFor="let question of securityQuestions5"
                      [value]="question.Text"
                    >
                      {{ question.Text }}
                    </mat-option>
                  </div>
                  </mat-select>

                  <mat-error
                    *ngIf="
                      showErrorMsg ||
                      (regSecurityQuesionsForm.get('securityQuestion5')
                        .errors &&
                        (regSecurityQuesionsForm.get('securityQuestion5')
                          .dirty ||
                          regSecurityQuesionsForm.get('securityQuestion5')
                            .touched) &&
                        regSecurityQuesionsForm
                          .get('securityQuestion5')
                          .hasError('required'))
                    "
                  >
                    <span class="error-message">
                      Error : Security question 5 of 5 is required.</span
                    >
                  </mat-error>
                </mat-form-field>
              </li>
              <li role="none">
                <mat-form-field
                  [@.disabled]="animationsDisabled"
                  class="tw-w-full"
                >
                  <mat-label>Enter your Answer</mat-label>
                  <input
                    matInput
                    class="input5"
                    formControlName="securityAnswer5"
                    name="securityAnswer5"
                    required
                    id="securityAnswer5"
                    aria-label="Security answer 5 of 5"
                    autocomplete="new-password"
                    [type]="hide ? 'password' : 'text'"
                  />
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer5')
                        .hasError('required')
                    "
                    ><span class="error-message">
                      Error : Four or more characters are required.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer5')
                        .hasError('minlength')
                    "
                  >
                    <span class="error-message">
                      Error : Four or more characters are required.</span
                    ></mat-error
                  >
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer5')
                        .hasError('pattern')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter a valid Answer.</span
                    >
                  </mat-error>
                  <mat-error
                    class="error-message-style"
                    *ngIf="
                      regSecurityQuesionsForm
                        .get('securityAnswer5')
                        .hasError('duplicateAnswer')
                    "
                  >
                    <span class="error-message">
                      Error : Please enter unique answers.</span
                    >
                  </mat-error>
                </mat-form-field>
              </li>
            </ul>

            <div dir="ltr" style="text-align: right;">
              <mat-checkbox
                labelPosition="after"
                aria-label="Show answers"
                (change)="showHideAnswers()"
              ></mat-checkbox>
              <span> Show answers </span>
            </div>
          </div>
        </div>

        <div *ngIf="this.toastMessage != ''">
          <br />
          <app-toast id="successMsg" [type]="toastType" #toastMsg>
            {{ this.toastMessage }}
          </app-toast>
        </div>

        <div fxLayoutAlign="end start" class="actionbtnbox">
          <app-button
            type="submit"
            autofocus
            (click)="confirm()"
            emphasis="High"
            mat-raised-button
            aria-label="Save security questions."
          >
            Save
          </app-button>
          <span class="app-button-margin"></span>
          <app-button
            mat-raised-button
            class="btn-cancel"
            emphasis="Low"
            (click)="onCancel()"
            aria-label="Cancel security questions."
          >
            Cancel
          </app-button>
        </div>
      </form>
    </div>
  `,
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #secQuesDiv
        ::ng-deep
        .mat-form-field-type-mat-input
        .mat-form-field-outline {
        color: #67707a;
        border-color: #67707a;
      }

      /* #secQuesDiv
             ::ng-deep
             .mat-form-field-type-mat-input
             .mat-form-field-outline:focus {
             color: #67707a;
             border-color: #67707a;
           } */

      /* #secQuesDiv ::ng-deep .mat-form-field-type-mat-input .mat-form-field-outline:hover {
       color: #67707A;
       border-color: #3F464D;
     } */

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #secQuesDiv
        ::ng-deep
        .mat-form-field-type-mat-select
        .mat-form-field-outline {
        background-color: #ffffff;
        color: #171c20;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #secQuesDiv
        ::ng-deep
        .mat-form-field-type-mat-select
        .mat-form-field-outline:focus {
        background-color: #d2d8e1;
        color: #171c20;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      #secQuesDiv
        ::ng-deep
        .mat-form-field-type-mat-select
        .mat-form-field-outline:hover {
        background-color: #f1f4f7;
        color: #171c20;
      }

      .flex-container {
        display: flex;
        flex-direction: row;
      }

      .flex-left {
        width: 35%;
      }

      .flex-mid {
        width: 5%;
      }
      .flex-right {
        width: 35%;
      }

      .flex-left-col {
        width: 70%;
      }

      .flex-mid-col {
        width: 5%;
      }
      .flex-right-col {
        width: 25%;
      }

      .pdt20 {
        padding-top: 20px;
      }
      @media (max-width: 760px) {
        .flex-left {
          width: 100%;
        }

        .flex-mid {
          width: 100%;
        }
        .flex-right {
          width: 100%;
        }
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;
      }
      .box > * {
        flex: 1 1 160px;
        margin: 10px;
      }

      .flex-outer,
      .flex-inner {
        list-style-type: none;
        padding: 0;
      }

      .flex-outer li,
      .flex-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .flex-inner {
        padding: 0 8px;
        justify-content: space-between;
      }

      .flex-outer > li:not(:last-child) {
        margin-bottom: 20px;
      }

      .flex-outer li label,
      .flex-outer li p {
        padding: 8px;
        letter-spacing: 0.09em;
      }

      .flex-outer div {
        padding: 8px;
        font-weight: 500;
        letter-spacing: 0.09em;
      }

      .flex-outer > li > label,
      .flex-outer li p {
        flex: 1 0 120px;
        max-width: 220px;
      }

      .flex-outer > li > label + *,
      .flex-inner {
        flex: 1 0 220px;
      }

      .flex-outer li p {
        margin: 0;
      }

      .ddlquestions.mat-mdc-select {
        /* outline: 0;
             border-width: 0 0 1px; */
        /* border-color: var(--dark-blue); */
        color: #67707a !important;
        border-color: #67707a !important;
      }
      .ddlquestions.mat-mdc-select:focus {
        color: #171c20 !important;
        /* border-color: var(--light-blue); */
        border-color: #007dbc !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-outline-thick:hover {
        color: #67707a !important;
        /* border-color: var(--light-blue); */
        border-color: #3f464d !important;
      }

      .error-message-style {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .flex-outer li button {
        margin-left: auto;
      }

      .flex-inner li {
        width: 100px;
      }

      .mb-20 {
        margin-bottom: 20px;
      }
      .mt-20 {
        margin-top: 20px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-underline {
        border-bottom: 1px solid var(--dark-blue);
      }
      .mat-divider {
        border-top-width: 2px;
        margin-bottom: 30px;
        margin-top: 30px;
      }
      .button {
        background-color: var(--dark-blue);
        margin: 5px;
        color: white;
      }
      .whiteButton {
        background-color: var(--white);
        margin: 5px;
        color: black;
      }
      .reg-icon {
        margin-right: 5%;
        font-size: 21px;
      }
      .mat-mdc-radio-button ~ .mat-radio-button {
        margin-left: 16px;
      }

      .secAnswers-checkbox {
        text-align: right;
        margin-top: 5%;
      }
      .actionbtnbox {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
      }
      /*.mat-mdc-form-field-error {
            display: none !important;
        }*/
    `,
  ],
  animations: [], //if needed trigger conditions here
})
export class SecurityQuestionsPageComponent implements OnInit {
  animationsDisabled = true;
  hide: boolean = true;
  displayLabel: string = 'Show Password';
  displaySuccessMsg: boolean = false;

  toastMessage: string = '';
  toastType: ToastType;
  isSuccess: boolean = false;
  showErrorMsg: boolean = false;
  regSecurityQuesionsForm: FormGroup = this.fb.group({});
  @Input() hideAnswerLabel: string;
  @Output() private onChangeShowHide = new EventEmitter<any>();

  securityQuestions: { Value: number; Text: string }[] = [
    {
      Value: 0,
      Text: 'What is your favorite dessert?',
    },
    {
      Value: 1,
      Text: 'What is your favorite sports team?',
    },
    {
      Value: 2,
      Text: 'What is your favorite food to eat?',
    },
    {
      Value: 3,
      Text: 'What is your dream job?',
    },
    {
      Value: 4,
      Text: 'In what year was your mother born?',
    },
    {
      Value: 5,
      Text: 'What is the first and last name of your first boyfriend or girlfriend?',
    },
    {
      Value: 6,
      Text: 'Which phone number do you remember most from your childhood?',
    },
    {
      Value: 7,
      Text: 'What was your favorite place to visit as a child?',
    },
    {
      Value: 8,
      Text: 'Who is your favorite actor?',
    },
    {
      Value: 9,
      Text: 'What is the name of your favorite pet?',
    },
    {
      Value: 10,
      Text: 'In what city were you born?',
    },
    {
      Value: 11,
      Text: 'What high school did you attend?',
    },
    {
      Value: 12,
      Text: 'What elementary school did you attend?',
    },
    {
      Value: 13,
      Text: 'What is your favorite movie?',
    },
    {
      Value: 14,
      Text: "What is your mother's maiden name?",
    },
    {
      Value: 15,
      Text: 'What street did you grow up on?',
    },
    {
      Value: 16,
      Text: 'What was the make and model of your first car?',
    },
    {
      Value: 17,
      Text: 'When is your anniversary?',
    },
    {
      Value: 18,
      Text: 'What is your favorite color?',
    },
    {
      Value: 19,
      Text: "What is your father's middle name?",
    },
    {
      Value: 20,
      Text: 'What is the name of your first grade teacher?',
    },
    {
      Value: 21,
      Text: 'What was your high school mascot?',
    },
    {
      Value: 22,
      Text: 'Who was your favorite teacher?',
    },
    {
      Value: 23,
      Text: 'In what city did you meet your spouse/significant other?',
    },
    {
      Value: 24,
      Text: 'What is the name of your best friend from childhood?',
    },
    {
      Value: 25,
      Text: 'What street did you live on in third grade?',
    },
    {
      Value: 26,
      Text: "What is your youngest sibling's birthday month and year?",
    },
    {
      Value: 27,
      Text: 'What is the middle name of your youngest child?',
    },
    {
      Value: 28,
      Text: "What is your youngest sibling's middle name?",
    },
    {
      Value: 29,
      Text: 'What school did you attend for sixth grade?',
    },
    {
      Value: 30,
      Text: "What is your oldest cousin's name?",
    },
    {
      Value: 31,
      Text: 'What was the name of your first stuffed animal?',
    },
    {
      Value: 32,
      Text: 'In what city or town did your mother and father meet?',
    },
    {
      Value: 33,
      Text: 'Who is your favorite musician?',
    },
    {
      Value: 34,
      Text: 'What was the last name of your first grade teacher?',
    },
    {
      Value: 35,
      Text: 'In what city does your nearest sibling live?',
    },
    {
      Value: 36,
      Text: "What is your oldest sibling's birthday month and year?",
    },
    {
      Value: 37,
      Text: "What is your maternal grandmother's maiden name?",
    },
    {
      Value: 38,
      Text: 'In what city or town was your first job?',
    },
    {
      Value: 39,
      Text: 'Where was your wedding reception held?',
    },
    {
      Value: 40,
      Text: "What is the name of a college you applied to but didn't attend?",
    },
    {
      Value: 41,
      Text: 'Where were you when you first heard about 9/11?',
    },
    {
      Value: 42,
      Text: 'Who is your favorite super hero?',
    },
    {
      Value: 43,
      Text: 'What was the first concert you attended?',
    },
    {
      Value: 44,
      Text: 'What is the name of your favorite book?',
    },
  ];

  securityQuestions1 = cloneDeep(this.securityQuestions);
  securityQuestions2 = cloneDeep(this.securityQuestions);
  securityQuestions3 = cloneDeep(this.securityQuestions);
  securityQuestions4 = cloneDeep(this.securityQuestions);
  securityQuestions5 = cloneDeep(this.securityQuestions);
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public session: Session,
    private el: ElementRef,
    private accountService: AccountSettingsService,
    private enumalatorService: emulator
  ) {}

  ngOnInit() {
    this.createSecurityQuesForm();
  }

  createSecurityQuesForm() {
    //This component should be its own form group, it should not be addeding all these individual controls to the
    //parent form group.
    this.regSecurityQuesionsForm.addControl(
      'securityQuestion1',
      new FormControl('', [Validators.required])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityQuestion2',
      new FormControl('', [Validators.required])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityQuestion3',
      new FormControl('', [Validators.required])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityQuestion4',
      new FormControl('', [Validators.required])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityQuestion5',
      new FormControl('', [Validators.required])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityAnswer1',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_ $@$#!%^+=&]*$'),
        Validators.minLength(4),
        this.checkDuplicateAnswer1.bind(this),
      ])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityAnswer2',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_ $@$#!%^+=&]*$'),
        Validators.minLength(4),
        this.checkDuplicateAnswer2.bind(this),
      ])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityAnswer3',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_ $@$#!%^+=&]*$'),
        Validators.minLength(4),
        this.checkDuplicateAnswer3.bind(this),
      ])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityAnswer4',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_ $@$#!%^+=&]*$'),
        Validators.minLength(4),
        this.checkDuplicateAnswer4.bind(this),
      ])
    );
    this.regSecurityQuesionsForm.addControl(
      'securityAnswer5',
      new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_ $@$#!%^+=&]*$'),
        Validators.minLength(4),
        this.checkDuplicateAnswer5.bind(this),
      ])
    );

    //this.regSecurityQuesionsForm.addControl('showAnswerCheck', new FormControl('',));
  }

  onOptionsSelected(e: any) {}

  updateAvailableQuestions(e: any) {
    this.securityQuestions1 = this.securityQuestions.filter(
      (q) =>
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion2')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion3')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion4')?.value &&
        q.Text !== this.regSecurityQuesionsForm.get('securityQuestion5')?.value
    );
    this.securityQuestions2 = this.securityQuestions.filter(
      (q) =>
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion1')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion3')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion4')?.value &&
        q.Text !== this.regSecurityQuesionsForm.get('securityQuestion5')?.value
    );
    this.securityQuestions3 = this.securityQuestions.filter(
      (q) =>
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion1')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion2')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion4')?.value &&
        q.Text !== this.regSecurityQuesionsForm.get('securityQuestion5')?.value
    );
    this.securityQuestions4 = this.securityQuestions.filter(
      (q) =>
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion1')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion3')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion2')?.value &&
        q.Text !== this.regSecurityQuesionsForm.get('securityQuestion5')?.value
    );
    this.securityQuestions5 = this.securityQuestions.filter(
      (q) =>
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion1')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion3')?.value &&
        q.Text !==
          this.regSecurityQuesionsForm.get('securityQuestion4')?.value &&
        q.Text !== this.regSecurityQuesionsForm.get('securityQuestion2')?.value
    );

    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      let getIdForFocus = e.source.id;
      if (getIdForFocus == 'securityQuestion1') {
        this.el.nativeElement.querySelector('#securityAnswer1').focus();
      }
      if (getIdForFocus == 'securityQuestion2') {
        this.el.nativeElement.querySelector('#securityAnswer2').focus();
      }
      if (getIdForFocus == 'securityQuestion3') {
        this.el.nativeElement.querySelector('#securityAnswer3').focus();
      }
      if (getIdForFocus == 'securityQuestion4') {
        this.el.nativeElement.querySelector('#securityAnswer4').focus();
      }
      if (getIdForFocus == 'securityQuestion5') {
        this.el.nativeElement.querySelector('#securityAnswer5').focus();
      }
    }, 500);
  }

  checkDuplicateAnswer1(fc: AbstractControl): Validators {
    if (
      fc.value &&
      (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer3').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer4').value ||
        fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)
    ) {
      return { duplicateAnswer: true };
    } else {
      this.checkAllOtherAnswers(1);
      return null;
    }
  }

  checkDuplicateAnswer2(fc: AbstractControl): Validators {
    if (
      fc.value &&
      (fc.value === this.regSecurityQuesionsForm.get('securityAnswer1').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer3').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer4').value ||
        fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)
    ) {
      return { duplicateAnswer: true };
    } else {
      this.checkAllOtherAnswers(2);
      return null;
    }
  }

  checkDuplicateAnswer3(fc: AbstractControl): Validators {
    if (
      fc.value &&
      (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer1').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer4').value ||
        fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)
    ) {
      return { duplicateAnswer: true };
    } else {
      this.checkAllOtherAnswers(3);
      return null;
    }
  }

  checkDuplicateAnswer4(fc: AbstractControl): Validators {
    if (
      fc.value &&
      (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer3').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer1').value ||
        fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)
    ) {
      return { duplicateAnswer: true };
    } else {
      this.checkAllOtherAnswers(4);
      return null;
    }
  }

  checkDuplicateAnswer5 = (fc: AbstractControl): Validators => {
    //let resp: Validators[] = [];
    if (
      fc.value &&
      (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer3').value ||
        fc.value ===
          this.regSecurityQuesionsForm.get('securityAnswer4').value ||
        fc.value === this.regSecurityQuesionsForm.get('securityAnswer1').value)
    ) {
      //resp.push({duplicateAnswer: true, nonNullable: true});
      return { duplicateAnswer: true };
    } else {
      this.checkAllOtherAnswers(5);
      return null;
    }
  };

  showHideAnswers(): void {
    this.hide = this.hide ? false : true;
    this.onChangeShowHide.emit(this.hide);
  }

  getSecurityAnswer(answerNumber: number) {
    return this.regSecurityQuesionsForm.get(`securityAnswer${answerNumber}`);
  }

  allAnswersSupplied(): boolean {
    return !(
      this.isNullOrEmpty(this.getSecurityAnswer(1)?.value) ||
      this.isNullOrEmpty(this.getSecurityAnswer(2)?.value) ||
      this.isNullOrEmpty(this.getSecurityAnswer(3)?.value) ||
      this.isNullOrEmpty(this.getSecurityAnswer(4)?.value) ||
      this.isNullOrEmpty(this.getSecurityAnswer(5)?.value)
    );
  }

  checkAllOtherAnswers(currentQuestion: number): void {
    if (this.allAnswersSupplied()) {
      for (let i = 1; i < 5; i++) {
        if (i === currentQuestion) continue;

        let secAnswer = this.getSecurityAnswer(i);
        if (secAnswer.hasError('duplicateAnswer'))
          secAnswer?.updateValueAndValidity();
      }
    }
  }

  async confirm() {
    for (const key of Object.keys(this.regSecurityQuesionsForm.controls)) {
      if (this.regSecurityQuesionsForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector(
          '[formcontrolname="' + key + '"]'
        );
        invalidControl.focus();
        this.showErrorMsg = true;
        break;
      }
    }
    if (this.regSecurityQuesionsForm.valid) {
      this.enumalatorService.showAuditorInfo();
      var request: any = {
        NewPassword: '',
        Salt: '',
        SecurityQuestion: this.regSecurityQuesionsForm.get('securityQuestion1')
          .value
          ? this.regSecurityQuesionsForm.get('securityQuestion1').value
          : '',
        SecurityAnswer: this.regSecurityQuesionsForm.get('securityAnswer1')
          .value
          ? this.regSecurityQuesionsForm.get('securityAnswer1').value
          : '',
        SecurityQuestion2: this.regSecurityQuesionsForm.get('securityQuestion2')
          .value
          ? this.regSecurityQuesionsForm.get('securityQuestion2').value
          : '',
        SecurityAnswer2: this.regSecurityQuesionsForm.get('securityAnswer2')
          .value
          ? this.regSecurityQuesionsForm.get('securityAnswer2').value
          : '',
        SecurityQuestion3: this.regSecurityQuesionsForm.get('securityQuestion3')
          .value
          ? this.regSecurityQuesionsForm.get('securityQuestion3').value
          : '',
        SecurityAnswer3: this.regSecurityQuesionsForm.get('securityAnswer3')
          .value
          ? this.regSecurityQuesionsForm.get('securityAnswer3').value
          : '',
        SecurityQuestion4: this.regSecurityQuesionsForm.get('securityQuestion4')
          .value
          ? this.regSecurityQuesionsForm.get('securityQuestion4').value
          : '',
        SecurityAnswer4: this.regSecurityQuesionsForm.get('securityAnswer4')
          .value
          ? this.regSecurityQuesionsForm.get('securityAnswer4').value
          : '',
        SecurityQuestion5: this.regSecurityQuesionsForm.get('securityQuestion5')
          .value
          ? this.regSecurityQuesionsForm.get('securityQuestion5').value
          : '',
        SecurityAnswer5: this.regSecurityQuesionsForm.get('securityAnswer5')
          .value
          ? this.regSecurityQuesionsForm.get('securityAnswer5').value
          : '',
      };

      await this.accountService.setSecurityQuestions(request);
      this.toastMessage = 'Security questions have been successfully changed.';
      this.toastType = 'Success';
      this.isSuccess = true;
      //this.router.navigate(['/']);
    }
  }

  onCancel(): void {
    this.displaySuccessMsg = false;
    this.toastMessage = '';
    this.regSecurityQuesionsForm.reset();
    this.createSecurityQuesForm();
    this.showErrorMsg = false;
    srSpeak('Answer\'s were cleared','polite');
  }

  isNull(value: any): boolean {
    return value === undefined || value === null;
  }

  isNullOrEmpty(value: any): boolean {
    return this.isNull(value) ? true : value.toString().length == 0;
  }
}
