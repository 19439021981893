import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CalendarComponent, CalendarEvent } from 'src/app/shared';
import { StatusEvent } from 'src/app/shared/models/StatusEvent';
import { ClaimKey } from 'src/app/shared/models/claim-key';
import { ClaimService } from '../../services/claim.service';

@Component({
  selector: 'app-off-work-calendar',
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <app-section class="tw-w-full" [IsSmallBox]="false">
      <ng-template #dialogOffWorkExport>
        <app-dialog
          title="Export"
          ariaLabel="Export dialog"
          [width]="700"
          [showActions]="false"
          [showHeader]="false"
          ariaLabelMessage="Close export popup"
        >
          <app-off-work-export
            [offworkdata]="offWorkCalendarEntries"
            [offworkEventData]="calendarevents"
          ></app-off-work-export>
        </app-dialog>
      </ng-template>
      <app-panel
        title="Claims calendar"
        [headerLevel]="2"
        class="tw-w-full"
        [expanded]="EventCount > 0 || true"
        titleCollapse="Collapse Claims Calendar"
        titleExpand="Expand Claims Calendar"
      >
        <app-loading-panel
          [state]="loading ? 'Loading' : 'Loaded'"
          [loadingHeight]="500"
          loadingStyle="Box"
          [error]="error"
        >
          <ng-template #content>
            <div [hidden]="loading" class="tw-w-full">
              <!-- <div *ngIf="displayMode.value === 'calendar'"> -->
              <div class="app-pt2"></div>
              <app-calendar
                [events]="events"
                [IsAbsence]="false"
                [ShowClaimNoLink]="showClaimNoLink"
                (calendarDateUpdated)="dateUpdated($event)"
                toolbarRight="dayGridMonth,dayGridWeek"
              >
              </app-calendar>
              <div
                class=" md:tw-flex tw-items-center tw-justify-center app-pt2"
              >
                <div class="tw-w-full md:tw-w-[20%] tw-flex tw-justify-center">
                  <div class="dot app-bg-color-green"></div>
                  <div class="app-pr2 app-pl1 tw-font-bold">Approved</div>
                </div>
                <div class="tw-w-full md:tw-w-[20%] tw-flex tw-justify-center">
                  <div
                    class="dot app-bg-color-sedgwick-blue"
                    *ngIf="!IsAccomodation"
                  ></div>
                  <div
                    class="app-pr2 app-pl1 tw-font-bold"
                    *ngIf="!IsAccomodation"
                  >
                    Pending
                  </div>
                </div>
                <div class="tw-w-full md:tw-w-[25%] tw-flex tw-justify-center">
                  <div
                    class="dot app-bg-color-input-grey"
                    *ngIf="!IsAccomodation"
                  ></div>
                  <div
                    class="app-pr2 app-pl1 tw-font-bold"
                    *ngIf="!IsAccomodation"
                  >
                    Waiting Period
                  </div>
                </div>
                <div class="tw-w-full md:tw-w-[20%] tw-flex tw-justify-center">
                  <div
                    class="dot app-bg-color-red"
                    *ngIf="!IsAccomodation"
                  ></div>
                  <div
                    class="app-pr2 app-pl1 tw-font-bold"
                    *ngIf="!IsAccomodation"
                  >
                    Denied
                  </div>
                </div>

                <div class="tw-w-[60%]" *ngIf="IsAccomodation"></div>
                <div class="tw-w-full md:tw-w-[15%] tw-flex tw-justify-center">
                  <button
                    mat-icon-button
                    class="mat-datepicker-toggle tw-self-center"
                    aria-label="Export Calendar entries"
                    (click)="export()"
                  >
                    <mat-icon class="export_clr">file_download</mat-icon>
                  </button>
                </div>
              </div>
              <!-- </div> -->
              <ng-template #row let-element>
                <div class="tw-flex tw-flex-wrap tw-w-full">
                  <app-value
                    label="Name"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>{{ element.data.entry.name }}</span>
                  </app-value>
                  <app-value
                    label="Claim Number"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>{{ element.data.entry.claimNumber }}</span>
                  </app-value>
                  <app-value
                    label="Type"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>{{ element.data.entry.leaveType }}</span>
                  </app-value>

                  <app-value
                    label="First day of absence"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>{{ element.start | date : 'MM/dd/yyyy' }}</span>
                  </app-value>
                  <app-value
                    label="Last day of absence"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span
                      value
                      *ngIf="
                        !(
                          element.data.entry.isEndDateEmpty !== undefined &&
                          element.data.entry.isEndDateEmpty === true
                        )
                      "
                      >{{ element.end | date : 'MM/dd/yyyy' }}</span
                    >
                  </app-value>
                  <app-value
                    label="Status"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>
                      {{ element.data.entry.status }}
                    </span>
                  </app-value>
                  <app-value
                    label="Sub status"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>
                      {{ element.data.entry.subStatus }}
                    </span>
                  </app-value>
                  <app-value
                    label="Hours Absence"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] flex-right txt-wrp"
                  >
                    <span value>
                      {{ element.data.entry.hoursMissed }}
                    </span>
                  </app-value>
                  <app-value
                    label="Minutes missed"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] flex-right txt-wrp"
                  >
                    <span value>{{ element.data.entry.minutesMissed }}</span>
                  </app-value>
                  <app-value
                    *ngIf="element.data.claimDetails.lob !== 'DS'"
                    label="Reason of absence"
                    layout="row-xs"
                    class="tw-w-full md:tw-w-[10%] txt-wrp"
                  >
                    <span value>{{ element.data.entry.absenceReason }}</span>
                  </app-value>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      .dot {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: inline-block;
      }

      @media (max-width: 480px) {
        .dot {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          display: inline-block;
        }
      }
      .app-bg-color-green {
        background-color: var(--color-sedgwick-green);
      }
      .app-bg-color-sedgwick-blue {
        background-color: var(--color-sedgwick-blue);
      }

      .app-bg-color-input-grey {
        background-color: var(--color-input-grey);
      }

      .app-bg-color-red {
        background-color: var(--color-red);
      }
      .txt-small {
        font-size: 12px !important;
      }

      .txt-wrp {
        overflow-wrap: anywhere;
      }
      .export_clr {
        color: #67707a !important;
      }
    `,
  ],
})
export class OffWorkCalendarComponent implements OnInit {
  events: any[] = [];
  @ViewChild('dialogOffWorkExport') dialogOffWorkExport: TemplateRef<any>;
  @ViewChild(CalendarComponent) appCalendar: CalendarComponent;

  loading = true;
  offWorkCalendarEntries: StatusEvent[] = [];
  EventCount: number = 1;

  @Input()
  claimId: string;

  @Input()
  IsAccomodation: boolean = false;

  @Input()
  source: string;
  @Input()
  claims: ClaimKey[] = [];
  calendarevents: CalendarEvent[] = [];

  @Input()
  filterEnabled: boolean = false;

  @Input()
  showClaimNoLink: boolean = false;

  noDataIcon: string = 'search';
  noDataMsg = 'There are no off work.';
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  currentDate = new Date();
  error: any = null;

  constructor(
    public claimService: ClaimService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  async dateUpdated(d: any) {
    //console.log('CALENDAR DATE SET!!!!!!!!!!!!!', d);
    // check if user has changed calenard to more than six months from last data date
    if (
      d.end &&
      !this.loading &&
      new Date(d.end).getFullYear() !== this.currentYear
    ) {
      this.cdr.detach();
      this.loading = true;
      this.currentYear = new Date(d.end).getFullYear();
      await this.getCalendarData();
      this.loading = false;
      this.cdr.reattach();
      this.cdr.detectChanges();
    }
  }

  async getCalendarData() {
    //this.loading = true;
    const owEntries = await this.claimService.getOffWorkCalendarEntries(
      this.claims,
      this.currentYear.toString(),
      this.currentMonth.toString(),
      this.claims[0].hrMasterUid,
      this.claims[0].empUnum
    );

    if (owEntries.length > 0) {
      this.calendarevents = this.getEntries(owEntries);

      this.EventCount = this.calendarevents.length;
      this.events = [];
      this.events = JSON.parse(JSON.stringify(this.calendarevents));
      this.calendarevents.sort(function (a, b) {
        return a.start > b.start ? 1 : -1;
      });

      this.calendarevents.forEach((val, ind) => {
        if (val.end != undefined && val.end != null) {
          val.end = this.removeExtraDay(val.end);
        }
      });
      this.offWorkCalendarEntries = owEntries;
    } else {
      this.events = [];
      this.calendarevents = [];
      this.offWorkCalendarEntries = [];

      setTimeout(() => {
        this.EventCount = 0;
      }, 1000);
    }
    if (this.appCalendar) {
      //debugger;
      this.appCalendar?.render();
    }
    //this.loading = false;
    //this.cdr.reattach();

    //this.cdr.detectChanges();
  }

  async ngOnInit() {
    // moved below code into separate function so we can call as needed when user changes dates in calendar component
    /*
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;

    this.claimService
      .getOffWorkCalendarEntries(
        this.claims,
        currentYear.toString(),
        currentMonth.toString()
      )
      .then((owEntries: StatusEvent[]) => {
        if (owEntries.length > 0) {
          this.calendarevents = this.getEntries(owEntries);
          this.EventCount = this.calendarevents.length;
          this.events = [];
          this.events = JSON.parse(JSON.stringify(this.calendarevents));
          this.calendarevents.sort(function (a, b) {
            return a.start > b.start ? 1 : -1;
          });

          this.calendarevents.forEach((val, ind) => {
            if (val.end != undefined && val.end != null) {
              val.end = this.removeExtraDay(val.end);
            }
          });
          this.offWorkCalendarEntries = owEntries;
        } else {
          this.events = [];
          this.calendarevents = [];
          this.offWorkCalendarEntries = [];

          setTimeout(() => {
            this.EventCount = 0;
          }, 1000);
        }

        this.loading = false;
      });
      */

    // this.loading = true;
    try {
      await this.getCalendarData();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }

  private getEntries(owEntries: StatusEvent[]): CalendarEvent[] {
    // reduce indiv daily records into a single records
    // grouped by status if possible
    const ce: CalendarEvent[] = [];
    const ne: CalendarEvent = null;
    const neStat: string = null;

    const OpratedIndex: number[] = [];

    owEntries
      // sort records by status and date (prep for aggregation step next)
      .sort((a, b) =>
        a.status.localeCompare(b.status) || a.beginDate > b.beginDate ? 1 : -1
      )
      .forEach((e, idx) => {
        ce.push(this.createCalendarEvent(e));
        /*if(OpratedIndex.indexOf(idx) == -1)
        {

            let currentEvent = this.createCalendarEvent(e);
            OpratedIndex.push(idx);
            owEntries.forEach((val,ind)=>{
              if(OpratedIndex.indexOf(ind) == -1)
              {
                  let nextCurrentEvent =  this.createCalendarEvent(val);

                  if(currentEvent.data.claimDetails.claimNumber == nextCurrentEvent.data.claimDetails.claimNumber && currentEvent.data.entry.status == nextCurrentEvent.data.entry.status)
                  {
                        let currentEndDate = this.addDay(currentEvent.end);
                        let nextEndDate = nextCurrentEvent.end;

                        if(currentEndDate.getDate() == nextEndDate.getDate() && currentEndDate.getMonth() == nextEndDate.getMonth() && currentEndDate.getFullYear() == nextEndDate.getFullYear())
                        {
                          currentEvent.end = nextCurrentEvent.end;
                          OpratedIndex.push(ind);
                        }
                  }
              }
            });


            ce.push(JSON.parse(JSON.stringify(currentEvent)));

        }*/
      });

    return ce;
  }

  // need to increment the last day to get continuity
  // right on the calendar
  private addDay(date: Date): Date {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d;
  }

  private removeExtraDay(date: Date): Date {
    const d = new Date(date);
    d.setDate(d.getDate() - 1);
    return d;
  }

  private createCalendarEvent(entry: StatusEvent) {
    if (new Date(entry.beginDate) > new Date('1/1/2025')) {
      //debugger;
    }
    let claimDetails: ClaimKey;

    this.claims.forEach((val, ind) => {
      if (val.claimNumber === entry.claimNumber) {
        claimDetails = val;
      }
    });

    return <CalendarEvent>{
      start: entry.beginDate,
      end: this.addDay(entry.endDate),
      title: `${entry.claimDescription}` + this.subStatusForTitle(entry),

      allDay: true,
      backgroundColor:
        entry.statusCode == undefined || entry.statusCode == ''
          ? this.setBackgroundColor(
            entry.status + '-' + entry.subStatus + '-' + entry.absenceStatus
          )
          : this.setBackgroundColorByStatusCode(entry.statusCode),
      data: { claimDetails, status: entry.status, entry },
    };
  }

  private subStatusForTitle(entry: StatusEvent) {
    let status = '';
    if (entry.statusCode != undefined && entry.statusCode != null && entry.statusCode.length > 1) {
      if (entry.statusCode.toLowerCase() == 'op') {
        status = 'Pending';
      } else if (entry.statusCode.toLowerCase() == 'oa') {
        status = 'Approved';
      } else if (
        entry.statusCode.toLowerCase() == 'wp' ||
        entry.statusCode.toLowerCase() == 'wa'
      ) {
        status = 'Waiting';
      } else if (
        entry.statusCode.toLowerCase() == 'dp' ||
        entry.statusCode.toLowerCase() == 'da' ||
        entry.statusCode.toLowerCase() == 'od'
      ) {
        status = 'Denied';
      }

    } else {

      let eleStatus: string = 'Approved';
      if( entry.absenceStatus != undefined &&  entry.absenceStatus != null &&  entry.absenceStatus.length > 1)
      {
        status = entry.absenceStatus +'-'+ entry.status + '-' + entry.subStatus;
      }
      else
      {
        status = entry.status + '-' + entry.subStatus;
      }

      if (status.toLocaleLowerCase().indexOf('denied') > -1) {
        eleStatus = 'Denied';
      } else if (status.toLocaleLowerCase().indexOf('waiting') > -1) {
        eleStatus = 'Waiting';
      } else if (status.toLocaleLowerCase().indexOf('pending') > -1) {
        eleStatus = 'Pending';
      } else if (status.toLocaleLowerCase().indexOf('approved') > -1) {
        eleStatus = 'Approved';
      }
      status = eleStatus;
    }
    return " - " + status;
  }
  private setBackgroundColorByStatusCode(statusCode: string): string {
    let status = '';
    if (statusCode.toLowerCase() == 'op') {
      status = 'Open - Pending';
    } else if (statusCode.toLowerCase() == 'oa') {
      status = 'Open - Approved';
    } else if (
      statusCode.toLowerCase() == 'wp' ||
      statusCode.toLowerCase() == 'wa'
    ) {
      status = 'Waiting';
    } else if (
      statusCode.toLowerCase() == 'dp' ||
      statusCode.toLowerCase() == 'da' ||
      statusCode.toLowerCase() == 'od'
    ) {
      status = 'Denied';
    }

    return this.setBackgroundColor(status);
  }

  private setBackgroundColor(status: string) {
    let eleStatus: string = 'Approved';
    if (status.toLocaleLowerCase().indexOf('denied') > -1) {
      eleStatus = 'Denied';
    } else if (status.toLocaleLowerCase().indexOf('waiting') > -1) {
      eleStatus = 'Waiting';
    } else if (status.toLocaleLowerCase().indexOf('pending') > -1) {
      eleStatus = 'Pending';
    } else if (status.toLocaleLowerCase().indexOf('approved') > -1) {
      eleStatus = 'Approved';
    }

    return {
      Approved: '#3f7c4f',
      Denied: '#c24533',
      Waiting: '#67707a',
      Pending: '#007dbc',
    }[eleStatus];
  }

  onValChange(value: any) {
    if (value == 'calendar') {
    } else {
    }
  }

  export() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Export claims calendar',
    };
    const temp = this.dialog.open(this.dialogOffWorkExport, dialogConfig);

    /*let offworkExportdialogRef = this.dialog.open(OffWorkExportComponent, {
      data: {
        title: 'Export off work calendar',
        message: '',
      },
    });

    let instance = offworkExportdialogRef.componentInstance;
    instance.offworkdata = this.offWorkCalendarEntries;*/
  }

  getTitle(entry: StatusEvent) { }
}
