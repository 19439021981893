import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ConfigurationService,
  LogService,
  OpenFileService,
  Session,
  UserRoleService,
} from 'src/app/my-sedgwick';
import { UploadComponent } from 'src/app/my-sedgwick/components/upload/upload.component';
import {
  BaseComponent,
  MyClaim,
  SearchResult,
  UserActionService,
} from 'src/app/shared';
import { UserActions } from 'src/app/shared/models/user';
import { ClaimDataService } from 'src/app/shared/services/claim-data.service';
import { DisplayService } from '../..';
import { ClaimService } from '../../services/claim.service';
import { UserDetails } from '../../services/userdetails.service';
import { SubmitReturnToWorkDialogComponent } from './submit-return-to-work-dialog.component';
import { EncryptionService } from '../../services/encryption.service';
import {
  AccommodationJobFunction,
  PendingAccommodationPanels,
} from '../../models/accommodation';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-my-claims',
  template: `
    <app-loading-panel [state]="!myClaimsLoaded ? 'Loading' : 'Loaded'">
      <ng-template #content>
        <app-list
          [dataSource]="myClaims"
          [template]="row"
          class="app-pt2"
          [filterEnabled]="false"
          [noDataIcon]="noDataIcon"
          [noDataMessage]="noDataMessage"
          [toastInfoMessage]="toastInfoMessage"
          [displayInfoToastMsg]="displayInfoToastMsg"
          [toastErrorMessage]="toastErrorMsg"
          [displayErrorToastMsg]="displayErrorToastMsg"
          [tableAriaLabel]="'My Claims'"
        >
          <ng-container header>
            <tr class="tw-flex tw-flex-wrap tw-w-full">
              <app-list-header
                name="claimLabel"
                label="Claim"
                class="tw-w-full md:tw-w-[40%] "
                [names]="['claim']"
              ></app-list-header>
              <app-list-header
                name="status"
                label="Status"
                class="tw-w-full md:tw-w-[15%]"
              ></app-list-header>
              <app-list-header
                name="dateFirstAbsent"
                [names]="['dateFirstAbsent', 'lossDate']"
                label="Detail"
                sortLabel="Date"
                filterType="Date"
                class="tw-w-full md:tw-w-[35%]"
              >
              </app-list-header>
              <app-list-header
                name=""
                label="Actions"
                readOnly
                class="tw-w-full md:tw-w-[10%] pdl10"
              ></app-list-header>
            </tr>
          </ng-container>
        </app-list>

        <ng-template #row let-element>
          <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
            <td class="tw-w-full md:tw-w-[40%]">
              <app-value
                label="Claim"
                layout="row-xs"
                labelStyle="pmt-header-init"
              >
                <app-claim-label value [claim]="element" layout="row">
                </app-claim-label>
              </app-value>
            </td>
            <td class="tw-w-full md:tw-w-[15%] ">
              <app-value
                label="Status"
                [tabindex]="element.status ? '0' : '1'"
                [value]="
                  element.subStatus
                    ? element.status +
                      ' - ' +
                      element.subStatus +
                      (element.absenceSubStatus &&
                      element.absenceSubStatus.toUpperCase() !== 'NONE'
                        ? ' - ' + element.absenceSubStatus
                        : '')
                    : element.status
                "
                layout="row-xs"
              ></app-value>
            </td>

            <td class="tw-w-full md:tw-w-[35%] ">
              <app-value
                [tabindex]="0"
                *ngIf="
                  element?.lossDate &&
                  element.dataSet !== 'LV' &&
                  element.dataSet !== 'DS'
                "
                layout="row"
                class="tw-w-full  "
                label="Date of Loss"
                [value]="element.lossDate | format : 'date'"
              ></app-value>
              <app-value
                [tabindex]="0"
                *ngIf="
                  (element?.dateFirstAbsent || element?.lossDate) &&
                  (element.dataSet === 'LV' ||
                    element.dataSet === 'DS' ||
                    element.dataSet === 'AD')
                "
                layout="row"
                label="Begin Date"
                class="tw-w-full  "
                [value]="
                  element?.dateFirstAbsent
                    ? (element?.dateFirstAbsent | format : 'date')
                    : (element?.lossDate | format : 'date')
                "
              ></app-value>
              <app-value
                [tabindex]="0"
                class="tw-w-full"
                *ngIf="
                  element?.coverageDescription &&
                  element.dataSet !== 'LV' &&
                  element.dataSet !== 'DS' &&
                  element.dataSet !== 'WC'
                "
                layout="row"
                label="Type"
                [value]="element?.coverageDescription"
              ></app-value>
              <app-value
                [tabindex]="0"
                class="tw-w-full  "
                *ngIf="
                  element?.dateFirstAbsent &&
                  element?.claimType &&
                  element.dataSet === 'LV'
                "
                layout="row"
                label="Leave Type"
                [value]="
                  element?.claimType !== element.subType
                    ? element?.claimType + ' - ' + element.subType
                    : element?.claimType
                "
              ></app-value>
              <app-value
                [tabindex]="0"
                class="tw-w-full"
                *ngIf="element?.requestType && element.dataSet === 'AD'"
                layout="row"
                label="Request Type"
                [value]="element.requestType"
              ></app-value>
            </td>

            <!-- mobile menu -->
            <td class="tw-w-full md:tw-hidden tw-block" label="Actions">
              <div
                *ngIf="element.status !== 'Closed'"
                value
                color="accent"
                label="Actions"
              >
                <span [hidden]="element.status === 'Closed'">
                  <a
                    class="tw-block md:tw-hidden app-pl1"
                    tabindex="0"
                    role="button"
                    [matMenuTriggerFor]="actionMenu"
                    [attr.aria-expanded]="isActionsMenuExpanded"
                    [attr.aria-label]="
                      'Actions menu for claim -' +
                      element.claimLabel +
                      '-mobile'
                    "
                    (click)="toggleMenu()"
                    (keydown.enter)="toggleMenu()"
                  >
                    <b>Actions</b>
                  </a>
                </span>
              </div>
              <mat-menu
                #actionMenu="matMenu"
                role="menu"
                (closed)="toggleMenu()"
              >
                <h3 class="tw-w-full app-pl2">Actions</h3>
                <div
                  *ngIf="
                    this.claimConfigService.getConfigFeatureAccess(
                      'ReturnToWork'
                    ) &&
                    !element.isIntermittent &&
                    !element.isLiabilityClaim &&
                    element.lineOfBusiness !== 'AD'
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Returned to Work"
                    [queryParams]="{
                      rtw: 'submit',
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/return-to-work"
                  >
                    Report return to work
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('uploaddocuments') > -1
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Upload documents"
                    [queryParams]="{
                      focus: 'documents',
                      source: element.source,
                      claimId: element.claimId
                    }"
                    [queryParams]="{
                      focus: 'documents',
                      source: element.source,
                      claimId: element.claimId,
                      showDialog: true
                    }"
                    routerLink="/upload-documents-in-documentstab"
                  >
                    Upload documents
                  </a>
                </div>

                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf(
                      'submitintermittentabsences'
                    ) > -1 && display.reportIntermittentAbsenceAdditionalCheck
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Report an absence"
                    (click)="setClaimant(element)"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/reportanabsence"
                  >
                    Report an absence
                  </a>
                </div>

                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('directdeposit') > -1 &&
                    isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Manage direct deposit"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/managedirectdeposit"
                  >
                    Manage direct deposit
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('mileagereimbursement') >
                      -1 && isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Mileage reimbursement"
                    routerLink="/mileage-reimbursement"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                  >
                    Mileage reimbursement
                  </a>
                </div>
                <div
                  class="tw-w-full app-pl2"
                  *ngIf="
                    element.claimLevelFeatures.indexOf('medicalcard') > -1 &&
                    isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    label="View pharmacy card"
                    (click)="onPharmacyCardClick(element)"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/pharmacy-card"
                  >
                    View pharmacy card
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('findadoctor') > -1 &&
                    isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Find a medical provider"
                    (click)="openFileLink(element)"
                  >
                    Find a medical provider
                  </a>
                </div>
              </mat-menu>
            </td>
            <!-- desktop menu -->
            <td
              class="tw-w-full md:tw-w-[10%] md:tw-block tw-hidden"
              *ngIf="element.status !== 'Closed'"
            >
              <app-icon-button
                [matMenuTriggerFor]="menu"
                *ngIf="
                  this.claimConfigService.getConfigFeatureAccess(
                    'ReturnToWork'
                  ) ||
                  element.claimLevelFeatures.indexOf('uploaddocuments') > -1 ||
                  element.claimLevelFeatures.indexOf('directdeposit') > -1 ||
                  element.claimLevelFeatures.indexOf('mileagereimbursement') >
                    -1 ||
                  element.claimLevelFeatures.indexOf('medicalcard') > -1 ||
                  element.canSubmitClaimForms
                "
                icon="more_vert"
                label="Actions"
                tabindex="0"
                role="button"
                (click)="toggleMenu()"
                (keydown.enter)="toggleMenu()"
                [attr.aria-label]="
                  'Actions menu for claim - ' + element.claimLabel
                "
                [attr.aria-expanded]="isActionsMenuExpanded"
              >
              </app-icon-button>
              <mat-menu #menu="matMenu" role="menu" (closed)="toggleMenu()">
                <h3 class="tw-w-full app-pl2">Actions</h3>
                <div
                  *ngIf="
                    this.claimConfigService.getConfigFeatureAccess(
                      'returntowork'
                    ) &&
                    !element.isIntermittent &&
                    !element.isLiabilityClaim &&
                    element.lineOfBusiness !== 'AD'
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="rtwnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Return to Work"
                    (click)="setClaimant(element)"
                    [queryParams]="{
                      rtw: 'submit',
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/return-to-work"
                  >
                    Report return to work
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('uploaddocuments') > -1
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="uploadDocnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Upload documents"
                    (click)="setClaimant(element)"
                    [queryParams]="{
                      focus: 'documents',
                      source: element.source,
                      claimId: element.claimId
                    }"
                    [queryParams]="{
                      focus: 'documents',
                      source: element.source,
                      claimId: element.claimId,
                      showDialog: true
                    }"
                    routerLink="/upload-documents-in-documentstab"
                  >
                    Upload documents
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('directdeposit') > -1 &&
                    isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="directdepositnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Manage direct deposit"
                    (click)="redirectToDD(element)"
                  >
                    Manage direct deposit
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf(
                      'submitintermittentabsences'
                    ) > -1 && display.reportIntermittentAbsenceAdditionalCheck
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="reportanabsencenav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Report an absence"
                    (click)="setClaimant(element)"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/reportanabsence"
                  >
                    Report an absence
                  </a>
                </div>

                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('mileagereimbursement') >
                      -1 && isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="mileagereimbursementnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    (click)="setClaimant(element)"
                    label="Mileage reimbursement"
                    routerLink="/mileage-reimbursement"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                  >
                    Mileage reimbursement
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('medicalcard') > -1 &&
                    isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="pharmacycardnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    (click)="setClaimant(element)"
                    label="View pharmacy card"
                    (click)="onPharmacyCardClick(element)"
                    [queryParams]="{
                      source: element.source,
                      claimId: element.claimId
                    }"
                    routerLink="/pharmacy-card"
                  >
                    View pharmacy card
                  </a>
                </div>
                <div
                  *ngIf="
                    element.claimLevelFeatures.indexOf('findadoctor') > -1 &&
                    isViewingOwnEmpDb
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                >
                  <a
                    class="tw-w-full app-pl2"
                    label="Find a medical provider"
                    (click)="openFileLink(element)"
                    (keydown.enter)="openFileLink(element)"
                  >
                    Find a medical provider
                  </a>
                </div>
                <div
                  *ngIf="
                    element.lineOfBusiness === 'AD' &&
                    mgrAction === 2 &&
                    (userRole.manager ||
                      (element.subStatus === 'Pending business response' &&
                        userRole.employee))
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="approvedjobfunnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    (click)="setClaimant(element)"
                    (click)="approveJobFunctions(element)"
                    label="View job functions"
                  >
                    View job functions
                  </a>
                </div>

                <div
                  *ngIf="
                    element.lineOfBusiness === 'AD' &&
                    mgrAction === 2 &&
                    element.subStatus === 'Pending business response' &&
                    canApproveJobFunctions &&
                    hasPendingJobFunctions(element)
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="unapprovedjobfunnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    (click)="setClaimant(element)"
                    (click)="unapproveJobFunctions(element)"
                    label="Approve job functions"
                  >
                    Approve job functions
                  </a>
                </div>

                <div
                  *ngIf="
                    element.lineOfBusiness === 'AD' &&
                    mgrAction === 2 &&
                    element.subStatus === 'Pending business response' &&
                    canApproveAccommodationRequests &&
                    hasPendingAccommodations(element)
                  "
                  class="app-lc-sticky-link"
                  mat-menu-item
                  role="menuitem"
                  (keypress)="unapprovedaccommodationnav($event, element)"
                >
                  <a
                    class="tw-w-full app-pl2"
                    (click)="setClaimant(element)"
                    (click)="unapproveAccommodations(element)"
                    label="Approve accommodations"
                  >
                    Approve accommodations
                  </a>
                </div>
              </mat-menu>
            </td>
          </tr>
        </ng-template>
      </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
        font-weight: bold;
      }
      h3 {
        font-weight: bold;
      }
      ::ng-deep .mat-mdc-menu-panel.mat-mdc-menu-panel {
        padding-right: 11px !important;
      }
    `,
  ],
})
export class MyClaimsComponent extends BaseComponent implements OnInit {
  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  @Input()
  myClaims: MyClaim[];

  @Input()
  myClaimsLoaded: boolean = false;

  @Input()
  accommodationsJobFunctions: AccommodationJobFunction[] = [];

  development = false;
  toastInfoMessage: string = 'Test';
  displayInfoToastMsg: boolean = false;
  displayErrorToastMsg: boolean = false;
  toastErrorMsg: string = 'Toast Error Message test';
  noDataIcon: string = 'list_alt';
  noDataMessage: string = '<strong>No claims</strong><br>';
  yourClaim?: boolean = false;
  isIncidentalClient: boolean = false;
  isLoaded: boolean = false;
  mgrAction: UserActions | null;
  isViewingOwnEmpDb: boolean = true;
  userAction = UserActions.ViewOwnEmpDb;
  canApproveJobFunctions: boolean = false;
  canApproveAccommodationRequests: boolean = false;
  actionsMenuId = '';
  isActionsMenuExpanded: boolean = false;

  constructor(
    private router: Router,
    public session: Session,
    public userdetails: UserDetails,
    public dialog: MatDialog,
    public logService: LogService,
    public claimService: ClaimService,
    public claimConfigService: ConfigurationService,
    private claimDataService: ClaimDataService,
    private openFileService: OpenFileService,
    private userActions: UserActionService,
    public userRole: UserRoleService,
    public display: DisplayService,
    private encrypt: EncryptionService
  ) {
    super();
    this.canApproveJobFunctions = this.claimConfigService.hasFeature(
      'accommodationjobfunctionsrequests'
    );
    this.canApproveAccommodationRequests = this.claimConfigService.hasFeature(
      'accommodationsrequest'
    );
  }

  async ngOnInit() {
    !this.session.user ? await this.session.loadUser() : null;
    if (this.userRole.manager) {
      this.mgrAction = this.userActions.getAction();
      if (this.mgrAction != UserActions.ViewOwnEmpDb) {
        this.isViewingOwnEmpDb = false;
        this.userAction = UserActions.ViewOwnEmpDb;
      }
    }

    //console.log(this.accommodationsJobFunctions);
    this.subs.sink = this.claimDataService.currentMyClaim$.subscribe(
      (claim) => {
        if (claim) {
          this.myClaimsLoaded = true;
        }
      }
    );
  }

  openRtwComponent(claim: any): void {
    this.setClaimant(claim);
    if (this.userRole.manager) {
      this.userActions.setClaimant(claim);
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      claim: claim,
      yourClaim: this.yourClaim,
      uploadFile: claim.claimLevelFeatures.indexOf('uploaddocuments') > -1,
    };

    this.dialog.open(SubmitReturnToWorkDialogComponent, dialogConfig);
  }

  openUploadDocument(claim: any): void {
    this.setClaimant(claim);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      claim: claim,
      claimId: claim.claimId,
      source: claim.source,
      claimDescription: claim.claimDescription,
      yourClaim: this.yourClaim,
      uploadFile: claim.claimLevelFeatures.indexOf('uploaddocuments') > -1,
    };
    this.dialog.open(UploadComponent, dialogConfig);
  }

  //pharmacy card click
  onPharmacyCardClick(claim: SearchResult): void {
    this.setClaimant(claim);
    this.claimDataService.setClaim(claim);
  }

  async pharmacycardnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      this.onPharmacyCardClick(element);
      this.router.navigate(['pharmacy-card'], {
        queryParams: {
          source: element.source,
          claimId: element.claimId,
        },
      });
    }
  }
  openFileLink(claim: any) {
    this.setClaimant(claim);
    this.openFileService.openfile();
    this.logService.LogFeatureWithClaim(
      claim.claimId,
      claim.source,
      claim.lineOfBusiness,
      'Find a Doctor',
      'Employee Dashboard - My Claims'
    );
  }

  async redirectToDD(claim: any) {
    await this.setClaimant(claim);
    this.router.navigate(['managedirectdeposit'], {
      queryParams: {
        source: claim.source,
        claimId: claim.claimId,
      },
    });
  }
  async directdepositnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      this.redirectToDD(element);
    }
  }

  async reportanabsencenav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      await this.setClaimant(element);
      this.router.navigate(['reportanabsence'], {
        queryParams: {
          source: element.source,
          claimId: element.claimId,
        },
      });
    }
  }

  async mileagereimbursementnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      await this.setClaimant(element);
      this.router.navigate(['mileage-reimbursement'], {
        queryParams: {
          source: element.source,
          claimId: element.claimId,
        },
      });
    }
  }
  async rtwnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      await this.setClaimant(element);
      this.router.navigate(['return-to-work'], {
        queryParams: {
          rtw: 'submit',
          source: element.source,
          claimId: element.claimId,
        },
      });
    }
  }

  async uploadDocnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      await this.setClaimant(element);
      this.router.navigate(['upload-documents-in-documentstab'], {
        queryParams: {
          focus: 'documents',
          source: element.source,
          claimId: element.claimId,
          showDialog: true,
        },
      });
    }
  }

  async setClaimant(claim: any) {
    await this.userActions.setClaimant(claim);
  }

  approveJobFunctions(element: any) {
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.ApproveJobFunctions,
        userOwns: element.userOwns,
      },
    });
  }

  async approvedjobfunnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      this.setClaimant(element);
      this.approveJobFunctions(element);
    }
  }

  async unapprovedjobfunnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      this.setClaimant(element);
      this.unapproveJobFunctions(element);
    }
  }

  unapproveJobFunctions(element: any) {
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.UnapproveJobFunctions,
        userOwns: element.userOwns,
      },
    });
  }

  async unapprovedaccommodationnav(event: any, element: any) {
    if (event.charCode == 13 || event.charCode == 32) {
      this.setClaimant(element);
      this.unapproveAccommodations(element);
    }
  }

  unapproveAccommodations(element: any) {
    this.router.navigate(['../accommodations-pending'], {
      queryParams: {
        claimId: element.claimId,
        source: element.source,
        empUnum: this.encrypt.encrypt(element.empUnum),
        panel: PendingAccommodationPanels.UnapprovedAccommodations,
        userOwns: element.userOwns,
      },
    });
  }

  hasPendingJobFunctions(claim: MyClaim): boolean {
    return this.accommodationsJobFunctions.some(
      (ajf) =>
        claim.source === 'R' &&
        ajf.recordId === claim.claimId &&
        ajf.type === 'JobFunction' &&
        ajf.jobFunctionStatusId === '1' &&
        !claim.userOwns
    );
  }

  hasPendingAccommodations(claim: MyClaim): boolean {
    return this.accommodationsJobFunctions.some(
      (ajf) =>
        claim.source === 'R' &&
        ajf.recordId === claim.claimId &&
        ajf.type === 'Accommodation' &&
        ajf.decisionId === '1' &&
        !claim.userOwns
    );
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
