import { Component, Input, OnInit } from '@angular/core';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-search-count-row',
  template: `
    <div class="tw-w-full tw-pt-1">
      <app-action-row>
        <div left1>
          <div class="tw-font-bold tw-text-[16px]" [ngPlural]="count">
            <ng-template ngPluralCase="=1"
              >1 {{ resultSingle | titlecase }} found</ng-template
            >
            <ng-template ngPluralCase="other"
              >{{ count }} {{ resultPlural | titlecase }} found</ng-template
            >
          </div>
        </div>
        <app-toast
          [disableMarginBottom]="true"
          icon="info"
          left2
          *ngIf="count >= maxSearchResults"
          >{{
            maxResultsMsg
              ? maxResultsMsg
              : 'Maximum ' +
                resultPlural +
                ' are displayed, consider adding additional search parameters.'
          }}
        </app-toast>
      </app-action-row>
    </div>
  `,
  styles: ``,
})
export class SearchCountRowComponent implements OnInit {
  @Input()
  count: number = 0;

  @Input()
  maxSearchResults: number = 200;

  @Input()
  resultSingle = 'claim';

  @Input()
  resultPlural = 'claims';

  @Input()
  maxResultsMsg: string = '';

  resultsMessage: string = '';

  async ngOnInit() {
    if (this.count > 0) {
      await srSpeak(this.count + ' search results found', 'polite');
      if (this.count === this.maxSearchResults) {
        srSpeak(
          'Maximum search results are displayed, consider adding additional search parameters',
          'polite'
        );
      }
    }
  }
}
