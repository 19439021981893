import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/shared';

@Component({
    selector: 'app-base-control',
    template: ` <p>base-control works!</p>`,
    styles: [],
})
export class BaseControlComponent extends BaseComponent {
    @Input()
    formControlName: string;

    @Input()
    formGroup: UntypedFormGroup;

    @Input()
    label: string;

    @Input()
    hintLabel: string;

    @Input()
    placeHolder: string;

    @Input()
    validationControl: string;

    @Input()
    isSingular: boolean = true;

    @Input()
    minLengthValue: number;

    @Input()
    errorValidationMessage: string;

    private nativeElement: any;

    get formControl(): AbstractControl {
        return this.formGroup.controls[this.formControlName];
    }

    get isRequired(): boolean {
        return this.formGroup.controls[this.formControlName].hasValidator(
            Validators.required
        );
    }

    get errorMessage(): string {
        let errorMessage = '';
        if (this.formControl.hasError('required')) {
            errorMessage = this.isSingular
                ? 'Error: ' + this.validationControl + ' is required'
                : 'Error: ' + this.validationControl + ' are required';
            const validationControlField = this.validationControl
                ? this.validationControl
                : 'This field';
            errorMessage = this.isSingular
                ? 'Error: ' + validationControlField + ' is required'
                : 'Error: ' + validationControlField + ' are required';
        } else if (this.formControl.errors) {
            const error = Object.keys(this.formControl.errors)[0];
            const errorValue = Object.values(this.formControl.errors)[0];

            switch (error) {
                case 'email':
                    errorMessage = 'Error : Invalid email address';
                    break;
                case 'matDatepickerParse':
                    errorMessage = 'Error : Invalid date';
                    break;
                case 'mask':
                    errorMessage = 'Error : Invalid format';
                    break;
                case 'pattern':
                    errorMessage = error;
                    break;
                case 'max':
                    errorMessage = `Error : Maximum ${errorValue.max}`;
                    break;
                case 'minlength':
                    errorMessage =
                        ' Error: ' +
                        this.validationControl +
                        ' ' +
                        `${errorValue.requiredLength}   character/s minimum`;
                    break;
                case 'noWhiteSpace':
                    errorMessage =
                        'Error : White Space is not allowed in ' +
                        this.validationControl +
                        '.';
                    break;
                case 'noWhiteSpaceAndSpecialChar':
                    errorMessage =
                        'Error : White Space and Special characters are not allowed in ' +
                        this.validationControl +
                        '.';
                    break;
                case 'noSpecialCharacters':
                    errorMessage =
                        'Error : Special characters are not allowed in ' +
                        this.validationControl +
                        '.';
                    break;
                case 'noValidZipCode':
                    errorMessage = 'Error : Enter a valid zip code.';
                    break;
                case 'greaterThan':
                    errorMessage =
                        this.formControl.errors['customMsg'] ||
                        'Error : End Date cannot be smaller than Start Date';
                    break;
                default:
                    errorMessage = this.formControl.errors['customMsg'] || error;
                    if (typeof errorValue === 'string') {
                        errorMessage = 'Error : ' + errorValue;
                    }

                    break;
            }
        }

        return errorMessage;
    }

    @ViewChild('input')
    set setInput(ref: ElementRef) {
        if (ref) {
            this.nativeElement = ref.nativeElement;
        }
    }

    constructor() {
        super();
    }

    focus() {
        setTimeout(() => {
            this.nativeElement?.focus();
        }, 0);
    }
}
