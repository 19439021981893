import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OutboundSsoService {
  /**
   * Opens url in _blank tab. Url is determined by ssoType parameter.
   * @param ssoType
   */
  openSsoUrl(ssoType: string, ssoEndPoint: string) {
    /*     if (ssoType === 'prudential') {
      window.open(`/api/auth/Saml/AbsenceOne`, '_blank');
    } else if (ssoType === 'hartford') {
      window.open(`/api/auth/Saml/Hartford`, '_blank');
    } */

    window.open(ssoEndPoint, '_blank');
  }
}
