import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserActionService } from '../../../shared';
import { UserActions, isManagement } from '../../../shared/models/user';
import { ConfigurationService } from '../../services/configuration.service';
import { HelpfulLinkService } from '../../services/helpful-link.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { HelpItem } from '../help/help-page.component';

@Component({
  selector: 'app-visit-learning-center',
  template: `
    <app-panel title="Helpful resources" [border]="'Empty'" [canExpand]="false">
      <div
        class=" tw-text-white container app-bg-color-sedgwick-blue tw-flex tw-flex-col tw-min-h-[316px]"
      >
        <h2 class="tw-text-[20px] tw-font-bold tw-grow-0" role="heading">
          Helpful resources
        </h2>
        <div class="tw-grow"></div>
        <app-loading-panel
          [state]="loaded ? 'Loaded' : 'Loading'"
          loadingStyle="DotWhite"
          [error]="error"
        >
          <ng-template #content>
            <div
              class="tw-flex tw-flex-wrap tw-w-full tw-content-center tw-justify-around"
            >
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: {
                    data: {
                      label: 'Learning Center',
                      source: './assets/images/learning-center.png',
                      routerLink: '/help',
                      queryParams: { focus: 'learningcenter' },
                      visible: showLC,
                    }
                  }
                "
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: {
                    data: {
                      label: 'Links',
                      source: './assets/images/link-icon.png',
                      routerLink: '/help',
                      queryParams: { focus: 'links' },
                      visible: links?.count
                    }
                  }
                "
              ></ng-container>

              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: {
                    data: {
                      label: 'Videos',
                      source: './assets/images/play-icon.png',
                      routerLink: '/help',
                      queryParams: { focus: 'videos' },
                      visible: videos?.count
                    }
                  }
                "
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  link;
                  context: {
                    data: {
                      label: 'Documents',
                      source: './assets/images/document-icon.png',
                      routerLink: '/help',
                      queryParams: { focus: 'documents' },
                      visible: documents?.count
                    }
                  }
                "
              ></ng-container>
            </div>
          </ng-template>
        </app-loading-panel>

        <div class="tw-grow"></div>

        <ng-template #link let-data="data">
          <div
            *ngIf="data.visible"
            class="tw-flex tw-flex-col tw-items-center app-pt4 app-pb4 tw-px-2"
          >
            <img
              [src]="data.source"
              height="50"
              width="50"
              [alt]="data.label"
              [attr.aria-label]="data.label + ' image'"
              role="img"
            />
            <div
              class="tw-text-[14px] app-pt1 tw-text-center tw-leading-none tw-font-light"
            >
              {{ data.label }}
            </div>
            <div class="app-pt2">
              <app-button
                tabindex="-1"
                emphasis="Low"
                [routerLink]="data.routerLink"
                [queryParams]="data.queryParams"
                [arialabel]="'View ' + data.label"
                [role]="'link'"
              >
                View
              </app-button>
            </div>
          </div>
        </ng-template>
      </div>
    </app-panel>
  `,
  styles: [
    `
      .container {
        padding: 10px 16px 16px 16px;
      }
    `,
  ],
})
export class VisitLearningCenterComponent implements OnInit {
  helpfulReferences: HelpItem[] = [];
  links: HelpItem;
  videos: HelpItem;
  documents: HelpItem;
  faqs: HelpItem;
  document: HelpItem;
  showLC: boolean = false;
  claimId: string = '';
  source: string = '';
  lcType: string;
  lob: string;
  isMgr: boolean;
  items: any[] = [];
  claims: any[] = [];
  loaded = false;
  error: any;

  constructor(
    public session: Session,
    public config: ConfigurationService,
    public helpfulLinkService: HelpfulLinkService,
    private route: ActivatedRoute,
    private userRole: UserRoleService,
    private userAction: UserActionService
  ) {}

  async ngOnInit() {
    !this.session.user ? await this.session.loadUser() : null;
    this.isMgr = isManagement(this.session.user.role.toUpperCase());
    // const lobHierarchy = ['DS', 'WC', 'LV', 'AD', 'GL', 'PR', 'AU', 'PL'];
    this.claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.source = this.route.snapshot.queryParamMap.get('source');
    this.lob = this.route.snapshot.queryParamMap.get('lob');
    await this.loadHelpfulLinks();

    if (this.helpfulReferences) {
      this.links = this.helpfulReferences.find((item) => item.name === 'links');
      this.videos = this.helpfulReferences.find(
        (item) => item.name === 'videos'
      );
      this.documents = this.helpfulReferences.find(
        (item) => item.name === 'documents' || item.name === 'faqs'
      );
      this.faqs = this.helpfulReferences.find((item) => item.name === 'faqs');
      this.document = this.helpfulReferences.find(
        (item) => item.name === 'documents'
      );
      if (this.documents) {
        let count1 = 0;
        let count2 = 0;
        if (this.faqs) {
          count1 = this.faqs?.count;
        }
        if (this.document) {
          count2 = this.document?.count;
        }
        this.documents.count = count1 + count2;
      }
    }
    this.showLC = await this.config.getConfigFeatureAccess('learningcenter');
    this.loaded = true;
  }

  private async loadHelpfulLinks() {
    //this.loading = true;
    this.lcType = this.getLcType(this.lob);

    //this.isMgr = isManagement(this.session.user.role);
    this.helpfulReferences = [];

    try {
      this.helpfulReferences = (await this.helpfulLinkService.getHelpfulLinks(
        this.lcType,
        true
      )) as HelpItem[];
    } catch (error) {
      this.error = error;
    }

    // this.learningCenter.claimInfo = claimObject;
    // this.loading = false;
  }
  getLcType(lob: string) {
    const lobTypes = ['WC', 'AU', 'GL', 'PR', 'PL']; // these have CLAIMANT and CONTROLLER type in setup app
    const mgrAction = this.userAction.getAction();
    if (lobTypes.includes(lob)) {
      if (
        (this.isMgr && mgrAction == UserActions.ViewOwnMgrDb) ||
        this.userRole.controller
      ) {
        return 'CONTROLLER';
      } else {
        return 'HRM';
      }
    } else {
      if (
        (this.isMgr && mgrAction == UserActions.ViewOwnMgrDb) ||
        mgrAction == UserActions.ManagerSummaryView ||
        this.userRole.controller
      ) {
        return this.session.user.role.toUpperCase();
      } else {
        return 'HRM';
      }
    }
  }
}
