import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-timeout-dialog',
  template: `
    <div id="timeoutDialog" class="app-pb2 app-pt3">
      <app-dialog
        title="Alert"
        id="timeoutWarningDialog"
        ariaLabel="Session expiration warning"
        confirmLabel="Ok"
        (confirmEvent)="doUpdateActivity()"
        [showConfirm]="true"
        [showCancel]="false"
      >
        <div>
          <div id="timerDiv">
            <strong
              ><span
                >For your safety and protection your mySedgwick session will end
                in <span id="time"></span> due to inactivity.</span
              ></strong
            >
          </div>
          <br />
          {{ continueMessage }}
        </div>
      </app-dialog>
    </div>
  `,
  styles: [],
})
export class TimeoutDialogComponent implements AfterViewInit {
  confirmAltText: string = 'OK';
  confirmTitleText: string = '';

  alertMessage: string =
    'For your safety and protection your mySedgwick session will end in TIMER due to inactivity';

  continueMessage: string =
    'If you are still working in your mySedgwick session, simply click OK to continue.';
  confirmText: string = 'Click OK To continue on mySedgwick Application';
  timerStart: any;
  minutes: number;
  seconds: number;
  display: Element;

  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    private sessionService: Session,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.timerStart = data != null ? data.timer : null;
  }
  ngAfterViewInit(): void {
    this.display = document.querySelector('#time');
    this.startTimer(this.timerStart, this.display);
  }

  public doUpdateActivity() {
    this.sessionService.updateActivity.emit(true);
    this.dialogRef.close();
  }

  startTimer(duration: number, display: Element) {
    let timer = duration;
    let minutes: any;
    let seconds: any;
    setInterval(function () {
      minutes = Math.floor(timer / 60);
      seconds = Math.floor(timer % 60);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      display.textContent = minutes + ':' + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }
}
