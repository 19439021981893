import { OverlayRef } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseComponent,
  ClaimDataService,
  MyClaim,
  SearchResult,
  SnackbarService,
  SnackbarType,
  UserActionService,
  getUrlParams,
} from 'src/app/shared';
import {
  ChatService,
  ClaimService,
  ConfigurationService,
  DirectDepositService,
  DisplayService,
  InpageAliasService,
  LogService,
  OpenFileService,
  RecentClaimService,
  Session,
  UserRoleService,
} from '../..';
import { Location } from '@angular/common';

@Component({
  selector: 'app-action-tiles',
  template: `
    <div>
      <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'">
        <ng-template #content>
          <div class="app-header-4 tw-pb-2 tw-pt-2">Actions</div>
          <div class="tw-w-full tw-flex tw-flex-wrap tw-gap-3 tw-pb-2">
            <div
              class="container tw-flex tw-flex-col tw-items-center  tw-border  tw-justify-center"
              tabindex="0"
              [attr.aria-label]="item.label"
              role="link"
              [attr.alt]="item.label"
              [style.cursor]="item.loading ? 'default' : 'pointer'"
              [ngClass]="{
                'tw-drop-shadow-md': !item.loading,
                'hover:tw-drop-shadow-lg': !item.loading,
                'hover:tw-border-sky-700': !item.loading,
              }"
              *ngFor="let item of items"
              (click)="handleAction(item)"
              (keydown.enter)="handleAction(item)"
            >
              <div
                class="app-spinner-grey-24 tw-py-2 tw-mb-2"
                *ngIf="item.loading"
              ></div>
              <ng-container *ngIf="!item.loading">
                <ng-container *ngIf="item.isSvgIcon === true">
                  <mat-icon
                    class="icon"
                    alt=""
                    [svgIcon]="item.icon"
                  ></mat-icon>
                </ng-container>
                <ng-container *ngIf="item.isSvgIcon === false">
                  <img
                    class="icon"
                    alt=""
                    src="assets/images/actiontilesimg/{{ item.imgName }}"
                  />
                </ng-container>
              </ng-container>
              <div
                class="label"
                [ngClass]="{
                'app-color-teal': !item.loading,
                'app-color-input-grey': item.loading,
              }"
              >
                {{ item.label }}
              </div>
              <div class="help" *ngIf="item.help">
                <app-help-button
                  [message]="item.help"
                  (click)="$event.stopPropagation()"
                  [id]="'actionTiles'"
                ></app-help-button>
              </div>
            </div>
          </div>
        </ng-template>
      </app-loading-panel>
    </div>
    <ng-template #chatWarning>
      <app-dialog-container
        label="Chat in progress"
        [headerLevel]="2"
        [headerId]="'charProgress'"
        aria-labelledby="'chatProgress'"
      >
        <div class="tw-pt-2 tw-pb-4 tw-w-80">
          Press new chat to close the active chat session and start a new chat.
        </div>
        <app-action-row class="tw-pt-4">
          <app-button
            right2
            emphasis="High"
            matDialogClose
            (click)="openCareyChat()"
            >New chat</app-button
          >
          <app-button right1 emphasis="Low" matDialogClose>Cancel</app-button>
        </app-action-row>
      </app-dialog-container>
    </ng-template>
    <ng-template #auditorUnavailable>
      <app-dialog-container
        label="Feature Unavailable"
        [headerLevel]="2"
        [headerId]="'featureUnavailable'"
        aria-labelledby="'featureUnavailable'"
      >
        <div class="tw-pt-2 tw-pb-4 tw-w-80">
          This feature is unavailable for auditors.
        </div>
        <app-action-row class="tw-pt-4">
          <app-button right1 emphasis="High" matDialogClose>Close</app-button>
        </app-action-row>
      </app-dialog-container>
    </ng-template>
  `,
  styles: [
    `
      .help {
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px 4px 0 0;
      }
      .icon {
        font-size: 36px;
        width: 36px;
        height: 36px;
        color: var(--color-teal);
      }
      .label {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
      }
      .container {
        height: 95px;
        width: 115px;
        padding: 6px;
        border-radius: 6px;
        background-color: white;
      }
      .suggestion {
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 8px;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        color: #1f7850;
        background-color: #e2f6e8;
      }

      .required {
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 8px;
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        color: #c6180b;
        background-color: #ffd7cb;
      }

      .large-link {
        font-size: 16px;
      }
    `,
  ],
})
export class ActionTilesComponent extends BaseComponent implements OnInit {
  @ViewChild('chatWarning')
  chatWarning: TemplateRef<any>;

  @ViewChild('auditorUnavailable')
  auditorUnavailable: TemplateRef<any>;

  @Input()
  source: string;

  @Input()
  claimId: string;

  @Input()
  showUploadDocuments = true;

  @Input()
  showDirectDeposit = false;

  @Input()
  showElectronicUpdates = true;

  @Input()
  showMilageReimbursement = false;

  @Input()
  showPharmacyCard = false;

  @Input()
  showLearningCenter = true;

  @Input()
  showContactExaminer = true;

  @Input()
  showLiveChat = true;

  @Input()
  showReturnToWork = false;

  @Input()
  showReimbursement = false;

  @Input()
  showIntermittent = false;

  @Input()
  showRequestExtension = true;

  @Input()
  claim: SearchResult;

  displayErrorToastMsg: string = '';
  items: TileItem[] = [];
  myClaim: MyClaim;
  myClaims: MyClaim[];
  examinerLabel: string;
  isManageDDVisiable: boolean = true;
  isPharmCardVisible: boolean = false;
  loading: boolean = true;
  isController: boolean = this.userRole.controller;
  overlayRef: OverlayRef;

  chatTile: TileItem;

  isManageDDAccess = async () => {
    if (this.claim != null && this.claim != undefined) {
      if (this.claimConfigService.getFeatureAccess('DirectDeposit')) {
        const isAccess = await this.ddService.hasAccessDirectDeposit({
          ClaimUID: this.claim.claimId,
          LineOfBusiness: this.claim.lob,
        });

        return isAccess;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  constructor(
    private router: Router,
    public claimConfigService: ConfigurationService,
    public inpageAliasService: InpageAliasService,
    public session: Session,
    private route: ActivatedRoute,
    public claimService: ClaimService,
    public ddService: DirectDepositService,
    public openFileService: OpenFileService,
    public recentClaimService: RecentClaimService,
    private claimDataService: ClaimDataService,
    private matIconRegistory: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private display: DisplayService,
    private userRole: UserRoleService,
    public dialog: MatDialog,
    private careyChat: ChatService,
    private snackbarService: SnackbarService,
    private userActions: UserActionService,
    private location: Location,
    private logService: LogService
  ) {
    super();
  }

  private async loadClaim() {}

  async ngOnInit() {
    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.claim = await this.claimService.getSummary(this.source, this.claimId);

    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingular',
      this.claimConfigService.GetConfig.configFeatures.aliases
    );
    await this.setupVisibility();
    await this.loadTileItems();
    this.loading = false;

    this.subs.sink = this.claimDataService.currentMyClaim$.subscribe(
      (claim) => {
        if (claim) {
          this.myClaim = claim;
        }
      }
    );
  }

  handleAction(item: TileItem) {
    if (item.loading) {
      return;
    }

    const action = item.action;

    const queryParams: any = {};

    if (action === 'rtw') {
      queryParams.rtw = 'submit';
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['/return-to-work'], {
        queryParams,
      });
    } else if (action === 'docs') {
      queryParams.focus = 'documents';
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      queryParams.showDialog = true;
      const targetUrl = this.router
        .createUrlTree(['/upload-documents-in-documentstab'], { queryParams })
        .toString();
      if (this.location.path() === targetUrl) {
        window.location.reload(); //when coming back from upload to summary tab if the url is the same it doesnt redirect so need to reload
      } else {
        this.router.navigate(['/upload-documents-in-documentstab'], {
          queryParams,
        });
      }
    }
    // Report an Absence
    else if (action === 'raa') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['/reportanabsence'], {
        queryParams,
      });
    } else if (action === 'eeu') {
      this.router.navigate([], {
        queryParams,
      });
    } else if (action === 'mdd') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['/managedirectdeposit'], {
        queryParams,
      });
    } else if (action === 'mr') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['/mileage-reimbursement'], {
        queryParams,
      });
    } else if (action === 'vpc') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['/pharmacy-card'], {
        queryParams,
      });
    } else if (action === 'vhr') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      queryParams.claim =
        this.claim.claimDescription + ' - ' + this.claim.claimNumber;
      queryParams.lob = this.claim.lob;
      this.router.navigate(['/help'], {
        queryParams,
      });
    } else if (action === 'ce') {
      this.gotoContactEmaminer();
    } else if (action === 'ccp') {
      queryParams.ExpandCCP = 'true';
      queryParams.focus = 'changepreferences';
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['../PreferencesPage'], {
        queryParams,
      });
    } else if (action === 'fmp') {
      this.openFileLink();
    } else if (action === 'awl') {
      this.addToWatchList();
    } else if (action === 'chat') {
      this.tryOpenCareyChat();
    } else if (action === 'mws') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['../my-work-schedule'], {
        queryParams,
      });
    } else if (action === 'pend-acc') {
      queryParams.claimId = this.claimId;
      queryParams.source = this.source;
      this.router.navigate(['../accommodations-pending'], {
        queryParams,
      });
    }
  }

  openFileLink() {
    this.openFileService.openfile();
    this.logService.LogFeatureWithClaim(
      this.myClaim.claimId,
      this.myClaim.source,
      this.myClaim.lineOfBusiness,
      'Find a Doctor',
      'Summary - Action Tiles'
    );
  }

  async addToWatchList() {
    const str = await this.recentClaimService.addToWatchList(this.claim);
    if (str != 'success') {
      this.displayErrorToastMsg =
        'The Watch List is full. Additional claims can be added when one or more claims have been removed.';
    }
  }

  gotoContactEmaminer() {
    const comCenter = document.getElementById('commCenterPnl');
    if (comCenter != null) {
      comCenter.scrollIntoView();
      if (
        document.getElementById('btnSendMessage') != null ||
        document.getElementById('btnSendMessage') != undefined
      ) {
        document.getElementById('btnSendMessage').click();
      }
    }
  }

  async loadTileItems() {
    this.matIconRegistory.addSvgIcon(
      'rtw',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/v1_return_to_office.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'uploaddoc',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/v1_Upload documents.svg'
      )
    );
    // this.matIconRegistory.addSvgIcon(
    //   'directdeposit',
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(
    //     '../../../../assets/images/svgicons/Direct_deposit.svg'
    //   )
    // );
    this.matIconRegistory.addSvgIcon(
      'milage',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/v1_Request mileage reimbursement.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'helpful',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/Helpful_resources.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'commpref',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/v1_Change communication preferences.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'mail_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/Comcenter.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'local_pharmacy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/v1_View pharmacy card.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'medical_services',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/Medical_Provider.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'intermittent_absence',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/Intermittent_absence.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'my_work_schedule',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/Intermittent_absence.svg'
      )
    );
    this.matIconRegistory.addSvgIcon(
      'live_chat',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/images/svgicons/Live_chat.svg'
      )
    );
  }

  async setupVisibility() {
    this.isManageDDVisiable =
      (await this.isManageDDAccess()) && this.claim.userOwns;
    if (
      this.claimConfigService.getConfigFeatureAccess('ReturnToWork') &&
      !this.claim.isIntermittent &&
      !this.claim.isLiabilityClaim &&
      this.claim.status &&
      !this.claim.status?.toLowerCase().includes('closed') &&
      this.claim.lob !== 'AD'
    ) {
      this.items.push(
        new TileItem(
          'rtw',
          'Report return to work',
          'rtw',
          '',
          false,
          'v1_return_to_office.png'
        )
      );
    }
    if (this.claimConfigService.getFeatureAccess('UploadDocuments')) {
      this.items.push(
        new TileItem(
          'docs',
          'Upload documents',
          'uploaddoc',
          '',
          false,
          'v1_Upload documents.png'
        )
      );
    }
    if (this.display.reportIntermittentAbsenceByLob) {
      this.items.push(
        new TileItem(
          'raa',
          'Report an absence',
          'intermittent_absence',
          '',
          false,
          'Intermittent_absence.png'
        )
      );
    }
    if (
      this.isManageDDVisiable &&
      this.claimConfigService.getFeatureAccess('DirectDeposit')
    ) {
      this.items.push(
        new TileItem(
          'mdd',
          'Manage direct deposit',
          '../../../../assets/images/Direct_deposit.png',
          '',
          false,
          'Direct_deposit.png'
        )
      );
    }
    const listofRemovedFeatures = this.claim.featuresNotAllowedForCurrentClaim;
    if (
      this.claimConfigService.getFeatureAccess('medicalcard') &&
      (!listofRemovedFeatures ||
        (listofRemovedFeatures &&
          listofRemovedFeatures.indexOf('medicalcard') < 0))
    ) {
      this.isPharmCardVisible = true;
    }

    if (this.isPharmCardVisible && this.claim.userOwns) {
      this.items.push(
        new TileItem(
          'vpc',
          'View pharmacy card',
          'local_pharmacy',
          '',
          false,
          'v1_View pharmacy card.png'
        )
      );
    }

    if (
      this.claimConfigService.getFeatureAccess('mileagereimbursement') &&
      this.claim.userOwns
    ) {
      this.items.push(
        new TileItem(
          'mr',
          'Mileage reimbursement',
          'milage',
          '',
          false,
          'v1_Request mileage reimbursement.png'
        )
      );
    }

    this.items.push(
      new TileItem(
        'vhr',
        'Visit helpful resources',
        'helpful',
        '',
        false,
        'Helpful_resources.png'
      )
    );

    if (
      this.claimConfigService.getConfigFeatureAccess(
        'notificationpreferences'
      ) &&
      this.claim.userOwns
    ) {
      this.items.push(
        new TileItem(
          'ccp',
          'Communication preferences',
          'commpref',
          '',
          false,
          'v1_Change communication preferences.png'
        )
      );
    }
    if (
      this.claimConfigService.getFeatureAccess('FindaDoctor') &&
      this.claim.userOwns
    ) {
      this.items.push(
        new TileItem(
          'fmp',
          'Find a medical provider',
          'medical_services',
          '',
          true
        )
      );
    }
    if (this.claimConfigService.getFeatureAccess('careychat')) {
      this.chatTile = new TileItem(
        'chat',
        'Chat with us',
        'live_chat',
        '',
        false,
        'Live_chat.png'
      );
      this.items.push(this.chatTile);
    }

    if (
      this.display.myWorkSchedule &&
      (this.claim.lob == 'DS' || this.claim.lob == 'LV')
    ) {
      this.items.push(
        new TileItem(
          'mws',
          'Work Schedule',
          'my_work_schedule',
          '',
          false,
          'Intermittent_absence.png'
        )
      );
    }
  }

  liveChatVisible() {
    const element = document.getElementById('lhnHelpOutCenter');
    if (element) {
      return element.classList.contains('lhnActive');
    }
    return false;
  }

  careyChatVisible() {
    const element = document.getElementById('chatcontainer');
    if (element) {
      const style = window.getComputedStyle(element);
      return style.display !== 'none';
    }
    return false;
  }

  async tryOpenCareyChat() {
    if (
      this.session.user.emulatorContext?.isEmulating &&
      this.session.user.emulatorContext?.role === 'AUDITOR'
    ) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        arialabelledby: 'featureUnavailable',
      };
      this.dialog.open(this.auditorUnavailable);
    } else if (
      this.careyChat.isActive() ||
      this.liveChatVisible() ||
      this.careyChatVisible()
    ) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        arialabelledby: 'chatProgress',
      };
      this.dialog.open(this.chatWarning);
    } else {
      this.openCareyChat();
    }
  }

  setChatTileLoading(loading: boolean) {
    if (loading) {
      this.chatTile.loading = true;
    } else {
      if (this.careyChatVisible()) {
        this.chatTile.loading = false;
      } else {
        // prevent double clicks between load and render
        setTimeout(() => {
          this.setChatTileLoading(false);
        }, 1000);
      }
    }
  }

  checkCareyChatStatus() {
    if (!this.careyChatVisible() && this.chatTile.loading) {
      this.chatTile.loading = false;
      this.snackbarService.show('Chat server error', SnackbarType.Error);
    }
  }

  async openCareyChat() {
    this.setChatTileLoading(true);

    if (this.careyChat.isActive()) {
      await this.careyChat.close();
    }

    const liveChatClose = document.getElementById('lhnWindowCloser');
    if (liveChatClose) {
      liveChatClose.focus();
      liveChatClose.click();
    }

    try {
      setTimeout(() => {
        this.checkCareyChatStatus();
      }, 20000);
      await this.careyChat.initialize(
        this.claim.source,
        this.claim.claimId,
        this.claim.lob
      );
    } catch (e: any) {
      this.snackbarService.show(
        e?.message ? e.message : 'Chat error',
        SnackbarType.Error
      );
    } finally {
      this.setChatTileLoading(false);
    }
  }
}

class TileItem {
  constructor(
    public action: string,
    public label: string,
    public icon: string,
    public help: string = '',
    public isSvgIcon: boolean = false,
    public imgName: string = '',
    public loading: boolean = false
  ) {}
}
