import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MyClaim, NotificationItem } from 'src/app/shared';
import {
  CommCenterService,
  ConfigurationService,
  DisplayService,
  LogService,
  Session,
  UserRoleService,
} from '../..';
import { Reminder } from '../../../shared/models/reminder';
import { OutboundSSO, UserActions } from '../../../shared/models/user';
import { UserActionService } from '../../../shared/services/user-action.service';
import { OutboundSsoService } from '../../services/outbound-sso.service';
import { IClaimant } from '../labels/claimant-label.component';
import { ReportNewClaimComponent } from '../report-new-claim/report-new-claim.component';

@Component({
  selector: 'app-reminders',
  template: `
    <div>
      <app-loading-panel [state]="!myclaimsLoaded ? 'Loading' : 'Loaded'">
        <ng-template #content>
          <!-- mobile view -->
          <div
            class="tw-items-start tw-w-full tw-flex tw-flex-wrap lg:tw-hidden"
          >
            <div class="tw-w-full">
              <ng-container *ngTemplateOutlet="reminders"></ng-container>
            </div>
            <div class="tw-w-full">
              <ng-container *ngTemplateOutlet="links"></ng-container>
            </div>
          </div>
          <!-- mobile view end -->

          <div class="tw-items-start tw-w-full tw-hidden lg:tw-flex app-pt2">
            <div
              class="app-pr2 tw-flex-none"
              *ngIf="isManager && !isMgrOwnClaims"
            >
              <img
                src="./assets/images/053726-mySedgwick_Helpful_Link_Characters_4.png"
                width="194"
                height="193"
                alt=""
              />
            </div>
            <div
              class="app-pr2 tw-flex-none"
              *ngIf="isMgrOwnClaims || !isManager"
            >
              <img
                src="./assets/images/mySedgwick_Helpful_Link_Characters_3.webp"
                width="194"
                height="193"
                alt=""
              />
            </div>
            <div class="app-pr2 app-pl3 tw-grow">
              <ng-container *ngTemplateOutlet="reminders"></ng-container>
            </div>
            <div class="tw-flex-none side-border app-pr3 app-pb2">
              <ng-container *ngTemplateOutlet="links"></ng-container>
            </div>
          </div>
          <ng-template #reminders>
            <app-loading-panel
              loadingStyle="Image"
              loadingImageSrc="/assets/images/loader/my-claims-ghost.svg"
              [state]="isLoaded ? 'Loaded' : 'Loading'"
            >
              <ng-template #content>
                <div class="tw-w-full">
                  <span style="font-weight: bold;font-size: 18px"
                    >Hello {{ currentUserName | titlecase }},
                  </span>
                  <span
                    style="font-weight: normal;font-size: 18px"
                    *ngIf="!hasClaims && hasReportClaim && lobExist"
                  >
                    I see that you don't have any claims.
                  </span>
                  <span
                    style="font-weight: normal;font-size: 18px"
                    *ngIf="lobExist && !hasReportClaim"
                  >
                    Do you want to
                    <a
                      tabindex="0"
                      role="link"
                      *ngIf="(isClaimant || isEmployee) && !hasReportClaim"
                      (click)="redirectToLearningCenter()"
                      (keydown.enter)="redirectToLearningCenter()"
                      >learn about claims</a
                    >
                    ?
                  </span>
                  <span
                    style="font-weight: normal;font-size: 18px"
                    *ngIf="
                      (!hasClaims && hasReportClaim) ||
                      (hasClaims && lobExist && hasReportClaim)
                    "
                  >
                    Do you want to
                    <a
                      tabindex="0"
                      role="button"
                      *ngIf="
                        (isClaimant ||
                          isEmployee ||
                          (isManager && isMgrOwnClaims)) &&
                        !terminated
                      "
                      (click)="reportClaim()"
                      (keydown.enter)="reportClaim()"
                      >report a new claim</a
                    >
                    or
                    <a
                      tabindex="0"
                      role="link"
                      (click)="redirectToLearningCenter()"
                      (keydown.enter)="redirectToLearningCenter()"
                      >learn about claims</a
                    >?
                  </span>
                  <span
                    style="font-weight: normal;font-size: 18px"
                    *ngIf="hasReminders"
                  >
                    here are some important reminders.
                  </span>
                  <span *ngIf="isManager">
                    <span
                      style="font-weight: normal;font-size: 18px"
                      *ngIf="
                        hasClaims &&
                        !hasReminders &&
                        !lobExist &&
                        isMgrOwnClaims
                      "
                    >
                      there are currently no important reminders for your
                      claim(s).
                    </span>
                  </span>
                  <span
                    style="font-weight: normal;font-size: 18px"
                    *ngIf="
                      hasClaims && !hasReminders && !lobExist && isEmployee
                    "
                  >
                    there are currently no important reminders for your
                    claim(s).
                  </span>
                  <span
                    style="font-weight: normal;font-size: 18px"
                    *ngIf="
                      hasClaims &&
                      !hasReminders &&
                      !lobExist &&
                      !isMgrOwnClaims &&
                      !isEmployee
                    "
                  >
                    there are currently no important reminders for your
                    claim(s).
                  </span>
                </div>
                <ul
                  role="list"
                  class="tw-list-disc app-pl3 app-pt2 reminder-list"
                  *ngFor="let rmdr of activitiesMyClaims.reminder"
                >
                  <li
                    role="listitem"
                    class="list-item"
                    *ngIf="
                      rmdr.alertType !== 'PaymentAlert' &&
                      rmdr.alertType !== 'CLINICAL_REVIEW_SENT_A' &&
                      rmdr.firstText !== '' &&
                      rmdr.firstText !== undefined
                    "
                  >
                    {{ rmdr.firstText }}
                    <a
                      role="button"
                      tabindex="0"
                      (click)="
                        getClaimDetails(
                          rmdr.alertType,
                          rmdr.importantRemainders?.length
                        )
                      "
                      (keydown.enter)="
                        getClaimDetails(
                          rmdr.alertType,
                          rmdr.importantRemainders?.length
                        )
                      "
                      [attr.aria-label]="
                        'Review ' +
                        rmdr.linkText +
                        ' claim/s for which ' +
                        getReminderAriaLabel(rmdr.alertType)
                      "
                      >{{ rmdr.linkText }}</a
                    >
                    {{ rmdr.lastText }}
                  </li>
                  <li
                    role="listitem"
                    class="list-item"
                    *ngIf="rmdr.alertType === 'CLINICAL_REVIEW_SENT_A'"
                  >
                    <a
                      role="button"
                      tabindex="0"
                      (click)="
                        getClaimDetails(
                          rmdr.alertType,
                          rmdr.importantRemainders?.length
                        )
                      "
                      (keydown.enter)="
                        getClaimDetails(
                          rmdr.alertType,
                          rmdr.importantRemainders?.length
                        )
                      "
                      >{{ rmdr.linkText }}</a
                    >
                    {{ rmdr.lastText }}
                  </li>
                  <li
                    role="listitem"
                    class="list-item"
                    *ngIf="
                      rmdr.alertType === 'PaymentAlert' && showPaymentAlert
                    "
                  >
                    {{ rmdr.notificationMessage }}
                  </li>
                </ul>
              </ng-template>
            </app-loading-panel>
          </ng-template>

          <ng-template #links>
            <div
              class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
              *ngIf="outboundSSO?.ssoLinkEmployee"
            >
              <img
                src="./assets/images/SSO_link_icon.png"
                height="30"
                width="30"
                class="tw-hidden lg:tw-block"
                alt=""
              />

              <a
                tabindex="0"
                class="big-link app-pl1"
                (click)="
                  outboundSsoService.openSsoUrl(
                    outboundSSO.ssoType,
                    outboundSSO.ssoEndPoint
                  )
                "
                (keydown.enter)="
                  outboundSsoService.openSsoUrl(
                    outboundSSO.ssoType,
                    outboundSSO.ssoEndPoint
                  )
                "
                >{{ outboundSSO.ssoLinkEmployee }}</a
              >
            </div>
            <div
              class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
              *ngIf="hasReportClaim && !terminated"
            >
              <img
                src="./assets/images/Report_claim.png"
                height="30"
                width="30"
                class="tw-hidden lg:tw-block"
                alt=""
              />
              <a
                class="big-link app-pl1"
                role="button"
                (click)="reportClaim()"
                (keydown.enter)="reportClaim()"
                tabindex="0"
                >Report a new claim</a
              >
            </div>
            <div
              *ngIf="showReportAbsence()"
              class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
            >
              <img
                src="./assets/images/svgicons/Intermittent_absence.svg"
                height="30"
                width="30"
                class="tw-hidden lg:tw-block"
                alt=""
              />
              <a
                class="big-link app-pl1"
                (click)="selectClaim()"
                (keydown.enter)="selectClaim()"
                role="button"
                tabindex="0"
                >Report an absence</a
              >
            </div>
            <div
              class="tw-flex tw-items-center app-pt1 tw-cursor-pointer"
              *ngIf="hasClaims"
            >
              <img
                src="./assets/images/Comm_center_icon.png"
                height="30"
                width="30"
                class="tw-hidden lg:tw-block"
                alt=""
              />
              <a
                tabindex="0"
                routerLink="/communication-center"
                role="link"
                class="big-link app-pl1 mat-badge-accent"
                [matBadge]="commCenterService.unreadMessageCount()"
                matBadgeOverlap="false"
                >Communication center</a
              >
            </div>
            <div class="tw-flex tw-items-center app-pt1 ">
              <img
                src="./assets/images/Helpful_resources_icon.png"
                height="30"
                width="30"
                class="tw-hidden lg:tw-block"
                alt=""
              />
              <a
                tabindex="0"
                class="big-link app-pl1"
                (click)="redirectToLearningCenter()"
                (keydown.enter)="redirectToLearningCenter()"
                >Helpful resources</a
              >
            </div>
          </ng-template>
        </ng-template></app-loading-panel
      >
      <ng-template #dialogSelectClaim>
        <app-dialog
          [showActions]="false"
          [showHeader]="false"
          ariaLabel="Select claim dialog"
          ariaLabelMessage="Close announcement popup"
        >
          <app-claim-list-report-absence
            title="Confirmation"
            [claims]="myClaims"
            [redirectToSmartly]="redirectToSmartly"
          >
          </app-claim-list-report-absence>
        </app-dialog>
      </ng-template>
      <ng-template #dialogReportNewClaim>
        <app-dialog
          title="Confirmation"
          ariaLabel="Confirmation dialog"
          [showActions]="false"
          [showHeader]="false"
          ariaLabelMessage="Close announcement popup"
        >
          <app-claim-list-report-absence
            [claims]="this.myClaims"
            [showContinue]="true"
            [redirectToSmartly]="true"
          ></app-claim-list-report-absence>
        </app-dialog>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .big-link {
        font-size: 16px;
        font-weight: bold;
        color: #007dbc;
      }

      .small-link {
        font-size: 14px;
        margin: 6px;
      }

      .side-border {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        height: 100%;
        padding-left: 16px;
      }

      .list-item {
        padding-bottom: 0px;
      }

      .claimListDialog {
        width: 80%;
        height: auto;
        min-height: 'calc(100vh - 90px)';
      }
    `,
  ],
})
export class RemindersComponent implements OnInit {
  currentUserName: string;
  isClaimant: boolean = false;
  isEmployee: boolean = false;
  hasReportClaim: boolean = false;
  outboundSSO: OutboundSSO;
  hasClaims: boolean = false;
  isLoaded: boolean = false;
  hasReminders: boolean = false;
  isIncidentalClient: boolean = false;
  isWFA: boolean = false;
  maxBadgeCount = 99;
  mappings: any;
  filterData: NotificationItem[] = [];
  claimCount: number;
  trigger: string;
  userReminder: Reminder[];
  initialNotifications: NotificationItem[] = [];
  redirectToSmartly: boolean = true;
  @Input()
  showPaymentAlert: boolean;

  @Input()
  setToastButnShow: Observable<boolean>;

  hasOpenClaims: boolean = false;
  multipleOpenClaims: boolean = false;

  @ViewChild('dynamicContentContainer') container: ElementRef;
  @ViewChild('dialogSelectClaim') dialogSelectClaim: TemplateRef<any>;
  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;

  @Output()
  valueChanged = new EventEmitter<boolean>();

  @Output()
  focusTab = new EventEmitter<string>();

  @Output()
  filterApplied = new EventEmitter<boolean>();

  @Input()
  myClaims: MyClaim[];

  @Input()
  commCenterMsgCount: number = 0;

  @Input()
  activitiesMyClaims: any;

  @Input()
  mgrAction: UserActions | null;

  @Input()
  myclaimsLoaded: boolean = false;

  claimkey: any[];
  lobs: any = [];
  lobmyclaims = ['PR', 'AU', 'GL'];
  lobExist: boolean = false;
  isManager: boolean = false;
  terminated: boolean = false;
  isMgrOwnClaims: boolean = false;
  claimant: IClaimant | null;
  isController: boolean = false;
  constructor(
    public session: Session,
    public dialog: MatDialog,
    public userRole: UserRoleService,
    public display: DisplayService,
    public claimConfigService: ConfigurationService,
    private router: Router,
    public logService: LogService,
    private userAction: UserActionService,
    public outboundSsoService: OutboundSsoService,
    public commCenterService: CommCenterService
  ) {}

  async ngOnInit() {
    this.terminated = this.session.user.emulatorContext?.isTerminated;
    this.isManager = this.userRole.manager;
    this.isController = this.userRole.controller;
    this.isMgrOwnClaims = this.mgrAction == UserActions.ViewOwnEmpDb;
    this.myClaims.forEach((element) => this.lobs.push(element.lineOfBusiness));
    this.lobExist = this.lobs.every((value: string) => {
      return this.lobmyclaims.includes(value);
    });
    this.hasReportClaim = this.display.reportClaim;
    this.outboundSSO = this.display.outboundSSO;
    this.isClaimant = this.userRole.claimant;
    this.isEmployee = this.userRole.employee;
    this.hasClaims = this.myClaims && this.myClaims.length > 0 ? true : false;
    this.hasReminders =
      this.activitiesMyClaims?.reminder &&
      this.activitiesMyClaims.reminder.filter(
        (n: any) => n.reminderMessage != null && n.reminderMessage != undefined
      ).length > 0
        ? true
        : false;
    //reminderMessage

    this.checkOpenClaims();
    this.isWFA = this.isEmployee;
    this.isLoaded = true;

    if (this.userRole.manager) {
      this.mgrAction = this.userAction.getAction();
      if (this.mgrAction == UserActions.MgrViewEmpDb) {
        this.claimant = this.userAction.getClaimant();
        this.currentUserName = this.session.user?.firstName;
      }
      if (this.mgrAction == UserActions.ViewOwnEmpDb) {
        this.userAction.removeClaimant();
        this.currentUserName = this.session.user?.firstName;
      }
    } else if (this.userRole.controller) {
      this.userAction.removeClaimant();
      this.currentUserName = this.session.user?.firstName;
    } else {
      this.currentUserName = this.session.user?.firstName;
    }
    this.initialNotifications = this.activitiesMyClaims.notifications;
    this.isLoaded = true;
  }

  getClaimDetails(triggerName: string, count: number) {
    this.activitiesMyClaims.notifications = this.initialNotifications;
    for (let rem = 0; rem < this.activitiesMyClaims?.reminder.length; rem++) {
      const arrItem = this.activitiesMyClaims?.reminder[rem];
      if (arrItem.alertType != 'PaymentAlert') {
        if (arrItem && arrItem?.importantRemainders != undefined) {
          this.claimkey = arrItem?.importantRemainders.map((claimData: any) => {
            return {
              claimId: claimData.claimId,
              claimNumber: claimData.claimNumber,
              source: claimData.source,
            };
          });
        }

        if (triggerName == arrItem?.alertType) {
          if (count == 1) {
            const currentAlert = this.activitiesMyClaims.notifications.find(
              (n: any) => n.type === triggerName
            );

            const triggerKey = `${currentAlert.type}_${currentAlert.claimNumber}_${currentAlert.source}`;

            sessionStorage.removeItem('triggerKey');
            sessionStorage.removeItem('alertMasterId');
            sessionStorage.setItem('triggerKey', triggerKey);
            sessionStorage.setItem('alertMasterId', currentAlert.alertId);
            switch (triggerName) {
              case 'RTW_CONFIRM_NEEDED_A':
                this.router.navigate(['return-to-work'], {
                  queryParams: {
                    claimId: this.claimkey[0].claimId,
                    source: this.claimkey[0].source,
                    alertMasterUid: currentAlert.alertId,
                    lob: currentAlert.lob,
                    claimNumber: currentAlert.claimNumber,
                    rtw: 'confirm',
                    rtwType: arrItem.notificationMessage
                      .toLowerCase()
                      .includes('full')
                      ? 'Full'
                      : arrItem.notificationMessage
                          .toLowerCase()
                          .includes('restricted')
                      ? 'Restricted'
                      : null,
                    rtwDate: currentAlert.date.toString(),
                  },
                });

                break;
              case 'MEDDOC_NEEDED_A':
                this.router.navigate(['/upload-documents-in-documentstab'], {
                  queryParams: {
                    focus: 'documents',
                    source: this.claimkey[0].source,
                    claimId: this.claimkey[0].claimId,
                    showDialog: true,
                  },
                });
                break;
              case 'MEDDOC_RCVD_A':
                this.filterNotifications(this.claimkey, triggerName);
                break;
              case 'CLINICAL_REVIEW_SENT_A':
                this.filterNotifications(this.claimkey, triggerName);
                break;
              default:
            }
          }
          if (count > 1) {
            this.filterNotifications(this.claimkey, triggerName);
          }
        }
      }
    }
  }

  filterNotifications(claimkey: any[], triggerName: string) {
    this.isLoaded = false;
    let filteredNotifications: NotificationItem[] = [];
    filteredNotifications = this.activitiesMyClaims?.notifications.filter(
      (x: NotificationItem) => x.type === triggerName
    );
    if (filteredNotifications.length > 0) {
      this.activitiesMyClaims.notifications = [...filteredNotifications];
    }
    const notificationElement = document.getElementById('myclaimspnl');
    if (notificationElement != null) {
      notificationElement.scrollIntoView();
    }
    this.filterApplied.emit(true);
    this.focusTab.emit('Notifications');
    this.isLoaded = true;
  }

  showReportAbsence() {
    return (
      this.myClaims.findIndex(
        (claim) =>
          claim.status === 'Open' &&
          claim.lineOfBusiness === 'LV' &&
          // this.isIncidentalClient &&
          claim.isIntermittent
      ) > -1 && this.display.reportIntermittentAbsence
    );
  }

  async reportClaim() {
    if (
      ((this.userRole.manager &&
        this.display.mgrAction == UserActions.ViewOwnEmpDb) ||
        !this.userRole.manager) &&
      this.showReportAbsence()
    ) {
      this.dialog.open(this.dialogRefReportNewClaim, {
        panelClass: 'claimListDialog',
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Confirmation',
      };
      const dlg = this.dialog.open(ReportNewClaimComponent, dialogConfig);
      dlg.addPanelClass('app-dlg-padding');
      dlg.updateSize('700px', '450px');
    }
  }

  checkOpenClaims() {
    let count: number = 0;
    this.myClaims.forEach(function (claim) {
      if (
        claim.status === 'Open' &&
        claim.lineOfBusiness === 'LV' &&
        claim.claimDescription.toLowerCase().indexOf('intermittent') > -1
      ) {
        count++;
      }
    });
    if (count > 0) {
      this.hasOpenClaims = true;
    }
    if (count > 1) {
      this.multipleOpenClaims = true;
    }
  }
  selectClaim() {
    if (this.hasOpenClaims) {
      if (this.multipleOpenClaims) {
        this.redirectToSmartly = false;
        this.dialog.open(this.dialogSelectClaim, {
          panelClass: 'claimListDialog',
        });
      } else {
        const queryParams: any = {};
        const element = this.myClaims.find(
          (claim) =>
            claim.status === 'Open' &&
            claim.lineOfBusiness === 'LV' &&
            claim.claimDescription.toLowerCase().indexOf('intermittent') > -1
        );
        queryParams.claimId = element.claimId;
        queryParams.source = element.source;
        this.userAction.setClaimant(element);
        this.router.navigate(['/reportanabsence'], {
          queryParams,
        });
      }
    }
  }

  redirectToLearningCenter() {
    if (this.userAction.getAction() != 2) {
      this.userAction.setAction(UserActions.ViewOwnEmpDb);
    }
    this.router.navigate(['help'], {
      queryParams: {
        focus: 'learningcenter',
      },
    });
  }

  getReminderAriaLabel(alertType: string): string | null {
    switch (alertType) {
      case 'MEDDOC_RCVD_A':
        return 'Supporting documentation has been received';
      case 'MEDDOC_NEEDED_A':
        return 'Supporting documentation is required';
      case 'RTW_CONFIRM_NEEDED_A':
        return 'confirmation of return to work date is required.';
      case 'CLINICAL_REVIEW_SENT_A':
        return 'which have been referred to clinical review';
      case 'CLARIFICATION_DUE_A':
        return 'clarification is due';
      case 'CLARIFICATION_RCVD_A':
        return 'clarification has been received';
      case 'INITIAL_PACKET_SENT_A':
        return 'initial packet has been sent';
      default:
        return null;
    }
  }
}
