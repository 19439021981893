import { Component, OnInit } from '@angular/core';
import {
  DetailSearchConfiguration,
  InpageAliasService,
  OmniApi,
  SearchRequest,
} from 'src/app/my-sedgwick';
import { LoadingState } from 'src/app/shared';

@Component({
  selector: 'app-off-work-page',
  template: `
    <app-section>
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [title]="(employeeAlias | titlecase) + ' off work'"
        [largeHeader]="true"
        class="tw-w-full"
        [canExpand]="false"
      >
        <div class="tw-min-h-[510px] tw-w-full">
          <app-detail-search-form
            #searchForm
            [config]="config"
            (search)="search($event)"
            [loading]="loading"
          >
            <ng-template #results>
              <app-search-count-row
                [count]="offWorks.length"
              ></app-search-count-row>
              <app-off-work
                [offWork]="offWorks"
                class="tw-w-full"
                (export)="searchForm.appendExport($event)"
              />
            </ng-template>
          </app-detail-search-form>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: ``,
})
export class OffWorkPageComponent implements OnInit {
  config = new DetailSearchConfiguration();

  employeeAlias: string;

  loading: LoadingState;

  offWorks: any[];

  constructor(private omniApi: OmniApi, private aliases: InpageAliasService) {
    this.config.key = 'omni-dashboard:off-work';
    this.config.setVisibility(false);
    this.config.firstName.visible = true;
    this.config.lastName.visible = true;
    this.config.employeeId.visible = true;
    this.config.initialSearch = true;
    this.config.initialSearchResultsOnClear = true;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = true;
    this.config.expanded = false;
    this.config.claimNumber.visible = true;
    this.config.payType.visible = false;
  }

  async ngOnInit() {
    this.employeeAlias = await this.aliases.get('employeePlural');
  }

  async search(request: SearchRequest) {
    this.loading = 'Loading';
    this.offWorks = await this.omniApi.getOffWorks(request.getParameters());
    this.loading = this.offWorks.length > 0 ? 'Loaded' : 'Empty';
  }
}
