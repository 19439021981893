import { Component, Input } from '@angular/core';
import { TimelineItemComponent } from './timeline-item.component';

@Component({
  selector: 'app-timeline',
  template: `
    <!-- Mobile View -->
    <div class="tw-flex tw-flex-col tw-w-full">
      <div
        class="x-tw-grow md:tw-hidden "
        *ngFor="let item of items; index as i"
      >
        <div>
          <div class="icon">
            <div
              [ngClass]="{
                'inner-icon': true,
                'success-icon': item.status === 'Success',
                'error-icon': item.status === 'Error',
                'pending-icon': item.status === 'Pending',
                'unknown-icon': item.status === 'Unknown'
              }"
            >
              <mat-icon
                *ngIf="item.status === 'Success'; else label"
                class="done"
                >done</mat-icon
              >
              <ng-template #label>
                <mat-icon
                  *ngIf="item.status === 'Error'; else label1"
                  class="exclamate"
                  >error_outline</mat-icon
                >
                <ng-template #label1>{{
                  i + 1 | number : '2.0-0'
                }}</ng-template>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          class="label-container"
          [style.padding-top]="'10px'"
          [style.padding-right.px]="padding"
          [ngClass]="item.details ? 'popuptop' : ''"
        >
          <div class="description-container tw-w-full">
            <div class="tw-w-full">
              <span class="uppercase-label clear">
                {{ item.description1 }}
              </span>
              <span class="uppercase-label clear">{{ item.description2 }}</span>
            </div>
            <ng-container
              *ngIf="item.dateList !== undefined && item.dateList.length > 0"
            >
              <span
                class="uppercase-label clear"
                *ngFor="let dateitem of item.dateList; index as j"
                >{{ dateitem.trackerLabel }}:
                <span class="small-label">{{
                  dateitem.trackerDate | date : 'MM/dd/yyyy'
                }}</span></span
              >
            </ng-container>
            <ng-container
              *ngIf="
                (item.dateList === undefined || item.dateList.length === 0) &&
                item.date !== undefined &&
                item.date !== null
              "
            >
              <span class="small-label clear">{{
                item.date | date : 'MM/dd/yyyy'
              }}</span>
            </ng-container>
            <div *ngFor="let detail of item.details" class="clear">
              <span class="short-side-line"></span>
              <span
                [ngClass]="{
                  'detail-step-icon': true,
                  'detail-success-icon': detail.status === 'Success',
                  'detail-error-icon': detail.status === 'Error'
                }"
                class="short-side-check"
              >
                <mat-icon *ngIf="detail.status === 'Success'">done</mat-icon>
                <mat-icon *ngIf="detail.status === 'Error'">close</mat-icon>
              </span>
              <span class="app-pl2">{{ detail.description }}</span>
            </div>
          </div>
        </div>
        <div
          class="short-line"
          [style.border-left]="
            i === items.length - 1
              ? '0px solid transparent'
              : item.status === 'Success'
              ? '3px solid #507b20'
              : '2px solid #D2D8E1'
          "
          [style.height]="'40px'"
          [style.margin-left]="'15px'"
        ></div>
      </div>
    </div>

    <!-- Desktop View -->
    <div class="tw-flex tw-w-full tw-justify-center">
      <div
        class="x-tw-grow tw-hidden md:tw-block"
        tabindex="0"
        [style.padding-left.px]="i === 0 ? padding : ''"
        *ngFor="let item of items; index as i"
      >
        <div
          class="line"
          [style.border-bottom]="
            i === items.length - 1
              ? '0px solid transparent'
              : item.status === 'Success'
              ? '3px solid #507b20'
              : '2px solid #D2D8E1'
          "
        >
          <div class="icon">
            <div
              [ngClass]="{
                'inner-icon': true,
                'success-icon': item.status === 'Success',
                'error-icon': item.status === 'Error',
                'pending-icon': item.status === 'Pending',
                'unknown-icon': item.status === 'Unknown'
              }"
            >
              <mat-icon
                *ngIf="item.status === 'Success'"
                class="done"
                [attr.aria-label]="'Complete'"
                >done</mat-icon
              >
              <mat-icon
                *ngIf="item.status === 'Error'"
                class="exclamate"
                [attr.aria-label]="'Past due'"
                >error_outline</mat-icon
              >
              <span
                *ngIf="item.status === 'Pending' || item.status === 'Unknown'"
                tabindex="-1"
                aria-hidden="true"
                [attr.aria-label]="
                  item.status === 'Pending' ? 'Pending' : 'Step ' + (i + 1)
                "
              >
                {{ i + 1 | number : '2.0-0' }}</span
              >
            </div>
          </div>
        </div>

        <div
          class="label-container"
          [style.padding-right.px]="padding"
          [ngClass]="item.details ? 'popuptop' : ''"
        >
          <div class="description-container">
            <div class="uppercase-label clear">
              {{ item.description1 }}
              <span [hidden]="!item.details">
                <app-overlay [origin]="detailOrigin" #detailOverlay>
                  <app-panel [showTitle]="false" *ngIf="detailOverlay.opened">
                    <div class="app-pt1 ">
                      <div class="clear tw-w-full">
                        <mat-icon
                          class="detail-icon overlay-close"
                          tabindex="0"
                          (click)="detailOverlay.close()"
                          >highlight_off</mat-icon
                        >
                      </div>
                      <div *ngFor="let detail of item.details" class="clear">
                        <span
                          [ngClass]="{
                            'detail-step-icon': true,
                            'detail-success-icon': detail.status === 'Success',
                            'detail-error-icon': detail.status === 'Error'
                          }"
                        >
                          <mat-icon *ngIf="detail.status === 'Success'"
                            >done</mat-icon
                          >
                          <mat-icon *ngIf="detail.status === 'Error'"
                            >close</mat-icon
                          >
                        </span>
                        <span class="app-pl2">{{ detail.description }}</span>
                      </div>
                      <br />
                    </div>
                  </app-panel>
                </app-overlay>
                <mat-icon
                  [hidden]="!item.details"
                  class="detail-icon"
                  tabindex="0"
                  (click)="detailOverlay.open()"
                  cdkOverlayOrigin
                  #detailOrigin="cdkOverlayOrigin"
                  >add_circle_outline</mat-icon
                >
              </span>
            </div>
            <div class="uppercase-label clear">{{ item.description2 }}</div>
            <ng-container
              *ngIf="item.dateList !== undefined && item.dateList.length > 0"
            >
              <div
                class="uppercase-label clear"
                *ngFor="let dateitem of item.dateList; index as j"
              >
                {{ dateitem.trackerLabel }}:
                <span class="small-label">{{
                  dateitem.trackerDate | date : 'MM/dd/yyyy'
                }}</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                (item.dateList === undefined || item.dateList.length === 0) &&
                item.date !== undefined &&
                item.date !== null
              "
            >
              <div class="small-label clear">
                {{ item.date | date : 'MM/dd/yyyy' }}
              </div>
            </ng-container>
          </div>
          <!-- <div
            [ngClass]="{
              'uppercase-label': true,
              'pending-label':
                item.status !== 'Error' && item.status !== 'Unknown',
              'error-label': item.status === 'Error',
              'unknown-label': item.status === 'Unknown'
            }"
          >
            {{ item.label }}
          </div> -->
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .inner-icon {
        height: 32px;
        width: 32px;
        padding: 5px;
        margin-top: 2px;
        margin-left: 2px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
      }

      .success-icon {
        -background-color: #e6f9e1;
        background-color: #e6f9e1;
      }

      .error-icon {
        color: #c24533;
        background-color: #fff0f3;
        -background-color: #fff0f3;
      }

      .pending-icon {
        background-color: #67707a;
        color: var(--color-sedgwick-blue);
      }

      .unknown-icon {
        background-color: #f1f4f7;
        color: #000;
      }

      .icon {
        float: left;
        font-size: 0;
        height: 36px;
        width: 36px;
        padding-bottom: 10px;
        margin-top: 3px;
        background-color: white;
      }

      .icon-mobile {
        font-size: 36px;
        height: 36px;
        width: 36px;
        padding-bottom: 10px;
        margin-top: 3px;
        background-color: white;
      }

      .done {
        float: left;
        font-size: 22px;
        height: 22px;
        width: 22px;
        -color: #507b20;
        color: #507b20;
      }
      .exclamate {
        float: left;
        font-size: 22px;
        height: 22px;
        width: 22px;
        -color: #c24533;
        color: #c24533;
      }

      .line {
        padding-bottom: 20px;
        width: 120px;
      }

      .short-line {
        padding-bottom: 20px;
        width: 75px;
      }

      .short-side-line {
        border-left: 3px solid #507b20;
        display: inline-block;
        width: 1px;
        height: 20px;
        position: relative;
        left: -15px;
        margin-bottom: -6px;
      }

      .short-side-check {
        margin-left: 12px;
      }

      .line-mobile {
        height: 100%;
        margin-right: 17px;
      }

      .last-line {
        border-bottom: 3px solid transparent;
        padding-bottom: 20px;
      }

      .label-container {
        padding-left: 4px;
        padding-top: 20px;
        font-size: 13px;
        -padding-right: 40px;
      }

      .label-container-mobile {
        padding-left: 4px;
        font-size: 13px;
        padding-bottom: 20px;
        padding-top: 10px;
      }

      .description-container {
        align-items: center;
        font-size: 11px;
      }

      .description-flex {
        display: flex;
      }

      .uppercase-label {
        text-transform: uppercase;
        font-weight: bold;
      }
      .small-label {
        text-transform: uppercase;
        font-weight: normal;
      }

      .pending-label {
        color: var(--color-sedgwick-blue);
      }

      .error-label {
        color: #cd4f28;
      }

      .unknown-label {
        color: #c3c8ce;
      }

      .detail-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        color: var(--color-input-grey);
        margin-left: 2px;
        cursor: pointer;
      }

      .detail-overlay {
      }

      .detail-step-icon {
        font-size: 14px;
        height: 14px;
        width: 14px;
        color: #c3c8ce;
        float: left;
      }

      .detail-success-icon {
        color: #507b20;
      }
      .detail-error-icon {
        color: #c24533;
      }
      .overlay-close {
        float: right;
      }
      .popuptop {
        padding-top: 15px !important;
      }
    `,
  ],
})
export class TimelineComponent {
  @Input()
  padding = 0;

  items: TimelineItemComponent[] = [];

  addItem(item: TimelineItemComponent) {
    this.items.push(item);
  }

  removeItem(item: TimelineItemComponent) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);
  }
}
