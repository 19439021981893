import {
  Absence,
  Alert,
  ClaimLink,
  Disability,
  Payment,
  Policy,
  PolicyCalc,
  SearchResult,
} from 'src/app/shared';
import {
  ClientMessage,
  Companion,
  Vehicle,
} from 'src/app/shared/models/search-result';
import { CommCenterMessage } from './Communications';

export class ConversationSummary implements SearchResult {
  client: string = '';
  empId: string = '';
  employeeId: string = '';
  empUnum: string = '';
  openDate: string = '';
  status: string;
  subStatus: string = '';
  userClaim: boolean = false;
  payments: Payment[] = [];
  activity: Alert[] = [];
  notifications: Alert[] = [];
  policyCalcs: PolicyCalc[] = [];
  policies: Policy[] = [];
  results: SearchResult[] = [];
  absence: Absence;
  disability: Disability;
  lob: string;
  linkedClaims: ClaimLink[];
  isLiabilityClaim: boolean;
  lossDate: string;
  anticipatedReturnToWorkDuty: string;
  anticipatedReturnToWorkDate: string;
  workStatus: string;
  exPolicy: string;
  lobDescription: string;
  type: string;
  vehicleInfo: Vehicle;
  listClientMessage: ClientMessage[];
  examinerName: string;
  examinerEmail: string;
  claimant: string;
  vendorId: string;
  procUnit: string;
  stateofJurisdiction: string;
  carrierCode: string;
  paidTotal: string;
  source: string;
  claimDescription: string;
  claimNumber: string;
  claimId: string;
  contNum: string;
  isDisableAddToWatchList: boolean;
  name: string;
  date: Date;
  unread: boolean;
  messageId: string;
  examinerPhoneNumber: string;
  claim: SearchResult;
  details: CommCenterMessage[];
  userOwns: boolean;
  causedBy: string;
  companionClaims: Companion[];
  absenceSubstatus: string;
  sourceClaimKey: SearchResult;
  claimFullDescription: string;
  isIntermittent: boolean;
  claimLabel: string;
  featuresNotAllowedForCurrentClaim: string[];
  employeeType: string;
  dateReported: string;
}
